import { Button, Menu, MenuItem, Stack, Tooltip, Typography, styled } from '@mui/material';
import { toPng } from 'html-to-image';
import { FC, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

interface MarketAnalysisGridCardProps {
  id: string;
  title: string;
  children: JSX.Element;
  overflow?: boolean;
  width?: number;
  exportOptions?: Array<'csv' | 'image'>;
  enableLogarithmicScale?: boolean;
  onLogarithmicScaleClick?: (value: string) => void;
  onClickExpandIcon?: () => void;
  displayLog?: boolean;
}

const StyledSectionCard = styled(Stack)(({ theme }) => ({
  flex: 1,
  border: 'none',
  position: 'absolute',
  top: '10px',
  right: '10px',
  bottom: '10px',
  left: '10px',
  boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '13px',
}));

const GraphTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '0.8rem',
  color: '#000000',
  fontWeight: 700,
  height: '40px',
  lineHeight: '40px',
}));

const MarketAnalysisGridCardHeader: FC<MarketAnalysisGridCardProps> = ({
  id,
  title,
  children,
  exportOptions = [],
  overflow = false,
  enableLogarithmicScale = false,
  onLogarithmicScaleClick,
  onClickExpandIcon,
  displayLog,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportToCSV = async () => {
    //  TODO - Implement CSV export
  };

  const exportToPNG = async () => {
    const element = document.getElementById(id);

    if (element) {
      const link = document.createElement('a');
      link.download = title;
      link.href = await toPng(element, {
        style: {
          borderRadius: '0',
        },
      });
      link.click();
    }
  };

  return (
    <Stack>
      {enableLogarithmicScale && (
        <Button
          sx={{
            zIndex: 2,
            borderColor: 'transparent',
            p: 0,
            width: '30px',
            height: '20px',
            minWidth: 0,
            mr: 0,
            color: 'gray',
            position: 'absolute',
            right: 65,
            top: 20,
            cursor: 'pointer',
            borderRadius: '2px',
            backgroundColor: displayLog ? '#a1bde6' : '#dfe4eb',
          }}
          onClick={() => {
            if (onLogarithmicScaleClick) onLogarithmicScaleClick(id);
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '0.7rem',
              color: '#000000',
              fontWeight: 500,
              height: '40px',
              lineHeight: '40px',
            }}
          >
            Log
          </Typography>
        </Button>
      )}
      <Button
        sx={{
          zIndex: 2,
          borderColor: 'transparent',
          p: 0,
          width: '30px',
          height: '20px',
          minWidth: 0,
          mr: 0,
          color: 'gray',
          position: 'absolute',
          right: 33,
          top: 20,
          cursor: 'pointer',
          borderRadius: '2px',
        }}
        onClick={onClickExpandIcon}
      >
        <OpenInFullIcon color="inherit" fontSize="small" />
      </Button>

      {exportOptions.length && (
        <Tooltip title="More Options">
          <Button
            size="small"
            variant="text"
            aria-haspopup="true"
            sx={{
              zIndex: 2,
              borderColor: 'transparent',
              p: 0,
              width: '20px',
              minWidth: 0,
              mr: 0,
              color: 'gray',
              position: 'absolute',
              right: 15,
              top: 20,
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <MoreVertIcon color="inherit" fontSize="small" />
          </Button>
        </Tooltip>
      )}

      <StyledSectionCard
        id={id}
        sx={{
          background: 'white',
        }}
      >
        <Stack>
          <GraphTitle>{title}</GraphTitle>
          <Stack
            sx={{
              position: 'absolute',
              top: '35px',
              right: '15px',
              left: '15px',
              bottom: '15px',
              overflowY: overflow ? 'auto' : 'hidden',
            }}
          >
            {children}
          </Stack>
        </Stack>
      </StyledSectionCard>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 8,
              width: 8,
              height: 8,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {exportOptions.includes('csv') && (
          <MenuItem onClick={exportToCSV}>
            <FileDownloadOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2">Download Excel</Typography>
          </MenuItem>
        )}
        {exportOptions.includes('image') && (
          <MenuItem onClick={exportToPNG}>
            <FileDownloadOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2">Download PNG</Typography>
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
};

export default MarketAnalysisGridCardHeader;
