import { useQuery, TypedDocumentNode, gql } from '@apollo/client';
import { gqlInvestor } from './shared';

interface ActiveInvestorsData {
  activeInvestors: {
    investor: {
      name: string;
      city: string;
      countryCode: string;
      source: string;
      cbUrl: string;
      uuid: string;
      funds: {
        name: string;
        raisedAmountUsd: number;
      }[];
    };
    totalInvestments: number;
  }[];
}

interface ActiveInvestorsVars {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
}

const ACTIVE_INVESTORS: TypedDocumentNode<ActiveInvestorsData, ActiveInvestorsVars> = gql`
  query GetActiveInvestors(
    $countries: [String!]
    $investmentTypes: [String!]
    $categories: [String!]
    $deliveryTypes: [String!]
    $years: [Int!]
  ) {
    activeInvestors(
      countries: $countries
      investmentTypes: $investmentTypes
      categories: $categories
      deliveryTypes: $deliveryTypes
      years: $years
    ) {
      investor {
        ${gqlInvestor}
      }
      totalInvestments
    }
  }
`;

const useActiveInvestors = (vars: ActiveInvestorsVars) => {
  const {
    data: { activeInvestors } = { activeInvestors: [] },
    loading,
    refetch,
  } = useQuery(ACTIVE_INVESTORS, { variables: vars });

  return { activeInvestors, loading, refetch };
};

export type { ActiveInvestorsData, ActiveInvestorsVars };
export default useActiveInvestors;
