import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import IconButton from '@mui/material/IconButton';
import { Button, Divider, Paper, Stack, TextField, Typography } from '@mui/material';

// FIREBASE
import { getAuth, OAuthProvider, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';

// CUSTOM
import background_image from '../assets/bg.png';

// MICROSOFT
import { ReactComponent as MicrosoftSignInButton } from '../assets/microsoft/ms_light.svg';

import { AuthCtx } from '../AuthProvider';
import useWindowSize from '../hooks/useWindowSize';

interface WindowsLocationType {
  from: Location | undefined;
}
const SignInPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as WindowsLocationType | undefined;
  const from = state?.from?.pathname || '/';

  // CONTEXTS
  const { user } = React.useContext(AuthCtx);
  const windowSize = useWindowSize();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!username || !password) {
      setError('Please fill in all fields');
      return;
    }
    try {
      const auth = getAuth();
      const result = await signInWithEmailAndPassword(auth, username, password);
      console.log(result);
    } catch (e) {
      setError('Error signing in with password and email!');
    }
  };

  useEffect(() => {
    if (user) {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    }
  }, [from, navigate, user]);

  const handleSignInWithMicrosoft = async () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: 'fc4b9672-10b3-4192-bf77-973d3433b822',
    });
    try {
      const auth = getAuth();
      await signInWithPopup(auth, provider);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundImage: `url(${background_image})`,
        backgroundSize: 'cover',
        backgroundColor: 'black',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
      }}
    >
      <Paper sx={{ background: 'white', borderRadius: 4, p: 4 }}>
        {/* MUI SIGN IN FORM  with username and password */}
        <Stack
          flex={1}
          sx={{
            flex: 1,
            position: 'relative',
            mx: 4,
            my: 3,
            // alignItems: 'center',
            justifyContent: 'center',
          }}
          spacing={2}
        >
          <TextField
            required
            id="outlined-required"
            label="Username"
            onChange={e => {
              setUsername(e.target.value);
            }}
            sx={{
              width: windowSize.width && windowSize.width > 600 ? '400px' : '100%',
            }}
          />
          <TextField
            required
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              background: 'black',
              fontWeight: 600,
              fontFamily: 'OpenSans-Regular',
              textTransform: 'none',
              padding: '10px 20px',
            }}
          >
            Submit
          </Button>

          {/* Errors */}
          {error && (
            <Typography
              sx={{
                textAlign: 'center',
                color: 'crimson',
                fontSize: '0.9rem',
              }}
            >
              {error}
            </Typography>
          )}
          <Divider />
          <IconButton onClick={() => handleSignInWithMicrosoft()}>{<MicrosoftSignInButton />}</IconButton>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default SignInPage;
