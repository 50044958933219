import { useEffect, useState } from 'react';
import { getUsers } from '../../API';
import { SURUser } from './types';
import UsersList from './components/UsersList';

const AdminPage = () => {
  const [users, setUsers] = useState<SURUser[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const sur_users = await getUsers();
      setUsers(sur_users);
    };
    fetchUsers();
  }, []);

  return (
    <div>
      <UsersList users={users} />
    </div>
  );
};
export default AdminPage;
