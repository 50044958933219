import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { SURUser, SURUserRole } from '../types';
import { assignUserRoles, getUser } from '../../../API';

interface IUserRow {
  user: SURUser;
}

const UserRow: FC<IUserRow> = ({ user }) => {
  const [displayedUser, setDisplayedUser] = useState<SURUser>(user);

  const computeNewUserRoles = (role: SURUserRole): Array<SURUserRole> => {
    const newRoles = displayedUser.roles.includes(role)
      ? displayedUser.roles.filter(r => r !== role)
      : [...displayedUser.roles, role];
    return newRoles;
  };

  const refetchUser = async () => {
    const sur_user = await getUser(displayedUser.uid);
    setDisplayedUser(sur_user);
  };

  return (
    <Stack direction={'row'} sx={{ fontWeight: 400, fontSize: '0.8rem' }} spacing={2}>
      <Stack sx={{ flex: 1, justifyContent: 'center' }}>{displayedUser.uid}</Stack>
      <Stack sx={{ flex: 1, justifyContent: 'center' }}>{displayedUser.email}</Stack>
      <Stack direction={'row'} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        {/* Iterate over UserRoles */}
        {Object.keys(SURUserRole).map((role, index) => (
          <span
            key={'role_option_' + index}
            style={{
              color: displayedUser.roles.includes(role as SURUserRole) ? 'blue' : 'crimson',
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={async () => {
              await assignUserRoles(displayedUser.uid, computeNewUserRoles(role as SURUserRole));
              await refetchUser();
            }}
          >
            {role}
          </span>
        ))}
      </Stack>
    </Stack>
  );
};
export default UserRow;
