import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
// Material UI
import { styled, Divider, Grid, Typography, Button, ButtonProps, Stack } from '@mui/material';
import { TypographyProps } from '@mui/system';

// Custom imports
import { StartupRadarDataCtx } from '../StartupRadarDataProvider';
import { LabelledOrganization, Organization } from '../types';
import OrganizationLabels from './OrganizationLabels';
import OrganizationListPaginator from './OrganizationsListPaginator';
import { GET_LABELLED_ORGANIZATIONS } from '../Models';

const ScrollDiv = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#9BA7AF',
    borderRadius: 1,
  },
}));

const StyledList = styled('div')(({ theme }) => ({
  marginTop: '20px',
  flexDirection: 'row',
  marginRight: '5px',
  cursor: 'pointer',
}));

const CompanyTitle = styled(Typography)<TypographyProps>(() => ({
  // fontFamily: "'Inter', normal",
  fontWeight: 500,
  fontSize: '19px',
  color: '#667085',
  lineHeight: '28px',
}));

const CalandarWeekTitle = styled(Button)<ButtonProps>(() => ({
  // fontFamily: "'Inter', normal",
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
  height: '30px',
  width: '300px',
  '&:disabled': {
    backgroundColor: '#D9D9D9',
    color: '#000000',
  },
}));

const CompanyName = styled(Typography)<TypographyProps>(() => ({
  // fontFamily: "'Inter', normal",
  fontWeight: 500,
  fontSize: '22px',
  color: '#101828',
  lineHeight: '31px',
}));

interface IOrganizationsLabelledListProps {
  months: Array<string>;
  selectedMonth: string;
  selectedYear: string;
  selectedCW: number;
}
const OrganizationsLabelledList = ({
  months,
  selectedMonth,
  selectedYear,
  selectedCW,
}: IOrganizationsLabelledListProps) => {
  const itemsPerPage = 50;
  const [organizationsSearchFilters, setOrganizationsSearchFilters] = useState({
    offset: 0,
    limit: 50,
  });
  const [fromDate, setFromDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const selectedStartDate = moment(`${selectedYear}-01-01`)
      .add(selectedCW - 1, 'weeks')
      .add(1, 'days')
      .format('YYYY-MM-DD');

    const selectedEndDate = moment(`${moment(`${selectedYear}-01-01`).year()}`)
      .add(selectedCW, 'weeks')
      .format('YYYY-MM-DD');

    setFromDate(selectedStartDate);
    setEndDate(selectedEndDate);
  }, [selectedCW, selectedYear]);

  const { data: { labelledOrganizations } = { totalCount: 0, items: [] }, refetch: refetchLabelledOrganizations } =
    useQuery(GET_LABELLED_ORGANIZATIONS, {
      variables: { fromDate: fromDate, toDate: endDate, offset: 0, limit: 1 },
    });

  useEffect(() => {
    refetchLabelledOrganizations({
      fromDate,
      toDate: endDate,
      offset: organizationsSearchFilters.offset * organizationsSearchFilters.limit,
      limit: organizationsSearchFilters.limit,
    });
  }, [
    endDate,
    fromDate,
    organizationsSearchFilters.limit,
    organizationsSearchFilters.offset,
    refetchLabelledOrganizations,
    selectedCW,
  ]);

  // List item hooks
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [currPage, setCurrPage] = useState(1);
  const navigate = useNavigate();

  const { setSelectedOrganization, positioningLogicMap, deliverablesMap } = React.useContext(StartupRadarDataCtx);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const getOrganizationImage = React.useCallback((organization: Omit<Organization, 'labelStatusRel'>) => {
    if (
      !organization?.logoUrl ||
      organization.logoUrl.toLowerCase() === 'nan' ||
      organization?.logoUrl.endsWith('.ai')
    ) {
      return `https://via.placeholder.com/70x40/000000/fff?Text=${organization?.name}`;
    }
    return organization?.logoUrl;
  }, []);

  const handleOrganizationClick = (labelledOrganization: LabelledOrganization) => {
    const organization: Organization = {
      ...labelledOrganization.organization,
      labelStatusRel: { ...labelledOrganization },
    };
    setSelectedOrganization(organization);
    navigate(`/classification/${organization.uuid}`);
  };

  return (
    <Stack
      sx={{
        height: '80vh',
      }}
    >
      <Grid container sx={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '15px' }}>
        <Grid item md={5} sm={12} xs={12} sx={{ mr: '20px' }} zeroMinWidth>
          <CompanyTitle>Companies</CompanyTitle>
        </Grid>
        <Grid item md={5} sm={12} xs={12} sx={{ mr: '20px' }} zeroMinWidth>
          <CalandarWeekTitle variant="outlined" disabled>
            {selectedMonth}
          </CalandarWeekTitle>
        </Grid>
      </Grid>
      <ScrollDiv onScroll={onScroll} ref={listInnerRef}>
        {labelledOrganizations?.items.map((labelledOrganization: LabelledOrganization, index: number) => {
          return (
            <Stack>
              <Grid container flex={1} sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <Grid item md={4} sm={12} xs={12} sx={{ mr: '20px' }} zeroMinWidth>
                  <StyledList key={index} onClick={() => handleOrganizationClick(labelledOrganization)}>
                    <img
                      width={70}
                      style={{ marginRight: '10px' }}
                      src={getOrganizationImage(labelledOrganization.organization)}
                      alt={`${labelledOrganization.organization.name}`}
                    />
                    <CompanyName noWrap> {labelledOrganization.organization.name}</CompanyName>
                    {/* <CompanyUrl noWrap> {labelledOrganization.organization.cbUrl}</CompanyUrl> */}
                  </StyledList>
                </Grid>
                <OrganizationLabels
                  isDeleteDisabled
                  positioningLogicValues={labelledOrganization.organization.taxonomy
                    .filter(taxonomy => taxonomy.type === 'CATEGORY')
                    .map(taxonomy => taxonomy.uuid)}
                  deliverablesValues={labelledOrganization?.organization.taxonomy
                    .filter(taxonomy => taxonomy.type === 'DELIVERY_TYPE')
                    .map(taxonomy => taxonomy.uuid)}
                  positioningLogicMap={positioningLogicMap}
                  deliverablesMap={deliverablesMap}
                />
              </Grid>
              <Divider />
            </Stack>
          );
        })}
      </ScrollDiv>

      <OrganizationListPaginator
        onPageNumberSelection={(offsetValue: number) => {
          setOrganizationsSearchFilters({ ...organizationsSearchFilters, offset: offsetValue - 1 });
        }}
        currentPage={organizationsSearchFilters.offset + 1}
        totalNumberOfPages={Math.ceil((labelledOrganizations?.totalCount || 0) / itemsPerPage)}
      />
    </Stack>
  );
};

export default OrganizationsLabelledList;
