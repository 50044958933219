import { FC, useState } from 'react';
// Material UI
import { Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// CUSTOM COMPONENTS
import useActiveInvestors, { ActiveInvestorsVars } from '../../graphql/useActiveInvestors';
import { formatCash } from '../../Utils';
import MarketAnalysisGridCard from '../../MarketAnalysis/MarketAnalysisGridCardHeader';
import SURGraphModal from '../SURGraphModal';

interface RenderActiveInvestorsProps {
  loading: boolean;
  activeInvestors: investor[];
}

interface investor {
  investor: {
    name: string;
    city: string;
    countryCode: string;
    source: string;
    cbUrl: string;
    uuid: string;
    funds: {
      name: string;
      raisedAmountUsd: number;
    }[];
  };
  totalInvestments: number;
}

const RenderActiveInvestorsGraph: FC<RenderActiveInvestorsProps> = ({ loading, activeInvestors }) => {
  if (loading) {
    return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}> Loading...</Stack>;
  }

  return (
    <Stack sx={{ flex: 1, position: 'relative' }}>
      <Stack
        sx={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          right: '10px',
          bottom: '10px',
        }}
      >
        <TableContainer
          component={Stack}
          sx={{
            '&::-webkit-scrollbar': {
              width: '3px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#9BA7AF',
              borderRadius: 1,
            },
            overflow: 'auto',
            borderRadius: '5px',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
          }}
        >
          <Table aria-label="Active Investors">
            <TableHead sx={{ backgroundColor: '#e6eef8' }}>
              <TableRow sx={{ height: '5px' }}>
                <TableCell sx={{ padding: '5px' }}>Investor Name</TableCell>
                <TableCell align="right" sx={{ padding: '5px' }}>
                  AUM
                </TableCell>
                <TableCell align="right" sx={{ padding: '5px' }}>
                  Total Investments
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeInvestors.map((row: investor, index: number) => (
                <TableRow key={`active-investor-row-${index}`}>
                  <TableCell component="th" scope="row" sx={{ padding: '5px' }}>
                    <Link color="inherit" target="_blank" href={row.investor.cbUrl}>
                      {row.investor.name}
                    </Link>
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '5px' }}>
                    $
                    {formatCash(
                      row.investor.funds.reduce(
                        (acc: number, fund: { raisedAmountUsd: number }) => acc + fund.raisedAmountUsd,
                        0,
                      ),
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '5px' }}>
                    {row.totalInvestments}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

const ActiveInvestors: FC<ActiveInvestorsVars> = ({ investmentTypes, countries, categories, deliveryTypes, years }) => {
  // QUERIES
  const { activeInvestors, loading } = useActiveInvestors({
    investmentTypes,
    countries,
    categories,
    deliveryTypes,
    years,
  });

  // use state variable to track expanded state
  const [expanded, setExpanded] = useState<boolean>(false);

  const graphData = {
    loading,
    activeInvestors: activeInvestors,
  };

  const handleExpandClose = () => {
    setExpanded(false);
  };

  return (
    <Stack>
      <MarketAnalysisGridCard
        id={'active-investors'}
        title={'Active Investors'}
        exportOptions={['csv']}
        onClickExpandIcon={() => {
          setExpanded(true);
        }}
      >
        <RenderActiveInvestorsGraph {...graphData} />
      </MarketAnalysisGridCard>
      {expanded && (
        <SURGraphModal open={expanded} onClose={handleExpandClose} loading={loading} title="Active Investors">
          <Stack sx={{ minHeight: '300px', height: '80vh', maxHeight: '600px' }}>
            <RenderActiveInvestorsGraph {...graphData} />
          </Stack>
        </SURGraphModal>
      )}
    </Stack>
  );
};

export default ActiveInvestors;
