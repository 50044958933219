import { useQuery, gql, TypedDocumentNode } from '@apollo/client';

type InvestmentTypeAllocationRawData = readonly {
  raisedAmountUsd: number;
  investmentType: string;
}[];

type InvestmentTypeAllocationVars = {
  countries?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  investmentTypes?: readonly string[];
  years?: readonly number[];
};

export const INVESTMENT_TYPES_ALLOCATION: TypedDocumentNode<
  InvestmentTypeAllocationRawData,
  InvestmentTypeAllocationVars
> = gql`
  query InvestmentTypesAllocation(
    $countries: [String!]
    $categories: [String!]
    $deliveryTypes: [String!]
    $investmentTypes: [String!]
    $years: [Int!]
  ) {
    investmentTypesAllocation(
      countries: $countries
      categories: $categories
      deliveryTypes: $deliveryTypes
      investmentTypes: $investmentTypes
      years: $years
    ) {
      raisedAmountUsd
      investmentType
    }
  }
`;

const useInvestmentTypesAllocation = (filters: InvestmentTypeAllocationVars) => {
  const {
    data: { investmentTypesAllocation } = { investmentTypesAllocation: [] },
    loading,
    refetch,
  } = useQuery<{
    investmentTypesAllocation: InvestmentTypeAllocationRawData;
  }>(INVESTMENT_TYPES_ALLOCATION, { variables: filters, notifyOnNetworkStatusChange: true });
  return { investmentTypesAllocation, loading, refetch };
};

export type { InvestmentTypeAllocationRawData, InvestmentTypeAllocationVars };
export default useInvestmentTypesAllocation;
