import { useQuery } from '@apollo/client';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { AuthCtx } from '../../AuthProvider';
import { firestore } from '../../Firebase';
import { Props } from '../../ifaces';
import { GET_DELIVERABLES, GET_POSITIONING_LOGIC } from './Models';
import { Organization } from './types';
import useFilterOptions, { FilterOptionsCountry, FilterOptionsInvestmentType } from './graphql/useFilterOptions';
import { DataLayer, TaxonomyMap } from '../../components/TaxonomyPicker/components/types';
import { SURUserRole } from '../AdminPage/types';

export type Filters = {
  investmentTypes: readonly string[];
  categories: readonly string[];
  countries: readonly string[];
  years: readonly number[];
};

//  UTIL Functions
const getTaxonomyChildNodes = ({
  dataLayer,
  parentId,
  level,
}: {
  parentId: string | null;
  dataLayer: DataLayer;
  level: number;
}): TaxonomyMap => {
  let nodes: TaxonomyMap = {
    [dataLayer.uuid]: {
      name: dataLayer.name,
      children: [] as Array<string>,
      parentId: parentId,
      level: level,
      rank: dataLayer.rank,
    },
  };

  const childNodes = dataLayer.subLevel ?? [];
  nodes[dataLayer.uuid].children = childNodes.map(node => node.uuid);

  for (let i = 0; i < childNodes.length; i++) {
    const childNode = childNodes[i];
    getTaxonomyChildNodes({ dataLayer: childNode, parentId: dataLayer.uuid, level: level + 1 });
    nodes = {
      ...nodes,
      ...getTaxonomyChildNodes({ dataLayer: childNode, parentId: dataLayer.uuid, level: level + 1 }),
    };
  }
  return nodes;
};

export const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const years = ['2019', '2020', '2021', '2020', '2021', '2022', '2023'];

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const dummyFundingData = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export type StartupRadarPermissions = {
  [key in string]: boolean;
};

export interface StartupRadarCtxProps {
  width: number;
  // fundingRounds: FundingRounds[];
  // fundingData: StartupRadarFundingDataType;
  // activeInvestors: StartupRadarActiveInvestorsType;
  // investmentType: StartupRadarInvestorsType[];
  // totalInvestmentType: StartupRadarFundingDataType;
  // totalFundingRounds: StartupRadarFundingDataType;
  // fundingRaised: StartupRadarFundingDataType;
  searchOrganizationDetail: string;
  setSearchOrganizationDetail: (value: string) => void;
  selectedOrganization: Organization | undefined;
  setSelectedOrganization: (organization: Organization | undefined) => void;
  selectedCategory: { uuid: string } | { uuid: '' };
  setSelectedCategory: (uuids: { uuid: string }) => void;
  positioningLogic: Array<DataLayer>;
  positioningLogicMap: TaxonomyMap;
  poisitioningLogicLoading: boolean;
  deliverables: Array<DataLayer>;
  deliverablesMap: TaxonomyMap;
  deliverablesLoading: boolean;
  permissions: StartupRadarPermissions | undefined;
  setEnableSaveButton: (value: boolean) => void;
  enableSaveButton: boolean;

  // Filter Options
  filterOptionsCountries: readonly FilterOptionsCountry[];
  filterOptionsInvestmentTypes: readonly FilterOptionsInvestmentType[];
  selectedCountries: readonly string[];
  selectedFoundedOnYears: readonly number[];
  selectedAnnouncedOnYears: readonly number[];
  selectedInvestmentTypes: readonly string[];
  selectedIndustries: readonly string[];
  displayPinnedCompanies: boolean;
  displayReviewedCompanies: boolean;

  // Filter Options Setters
  setSelectedCountries: (value: string[]) => void;
  setSelectedFoundedOnYears: (value: number[]) => void;
  setSelectedAnnouncedOnYears: (value: number[]) => void;
  setSelectedInvestmentTypes: (value: string[]) => void;
  setSelectedIndustries: (value: string[]) => void;
  setDisplayPinnedCompanies: (value: boolean) => void;
  setDisplayReviewedCompanies: (value: boolean) => void;
}

export const StartupRadarDataCtx = createContext({} as StartupRadarCtxProps);

export const StartupRadarDataProvider: React.FC<Props> = ({ children }) => {
  const { filterOptions } = useFilterOptions();

  const { user, roles } = useContext(AuthCtx);

  //  QUERIES
  const {
    data: { positioningLogic: unsortedPositioningLogic = [] } = { unsortedPositioningLogic: [] },
    loading: poisitioningLogicLoading,
  } = useQuery(GET_POSITIONING_LOGIC);
  const {
    data: { deliverables: unsortedDeliverables = [] } = { unsortedDeliverables: [] },
    loading: deliverablesLoading,
  } = useQuery(GET_DELIVERABLES);

  const positioningLogic = useMemo(() => {
    return unsortedPositioningLogic.slice().sort((a: { rank: number }, b: { rank: number }) => a.rank - b.rank);
  }, [unsortedPositioningLogic]);
  const deliverables = useMemo(
    () => unsortedDeliverables.slice().sort((a: { rank: number }, b: { rank: number }) => a.rank - b.rank),
    [unsortedDeliverables],
  );

  const positioningLogicMap: TaxonomyMap = useMemo(() => {
    let nodes: TaxonomyMap = {};

    for (let i = 0; i < (positioningLogic as Array<DataLayer>).length; i++) {
      const node = positioningLogic[i] as DataLayer;
      nodes = { ...nodes, ...getTaxonomyChildNodes({ dataLayer: node, parentId: null, level: 0 }) };
    }
    return nodes;
  }, [positioningLogic]);
  const deliverablesMap: TaxonomyMap = useMemo(() => {
    let nodes: TaxonomyMap = {};

    for (let i = 0; i < (deliverables as Array<DataLayer>).length; i++) {
      const node = deliverables[i] as DataLayer;
      nodes = { ...nodes, ...getTaxonomyChildNodes({ dataLayer: node, parentId: null, level: 0 }) };
    }
    return nodes;
  }, [deliverables]);

  const [width, setWidth] = useState(window.innerWidth);
  const [searchOrganizationDetail, setSearchOrganizationDetail] = useState<string>('');

  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
  const [selectedCategory, setSelectedCategory] = useState<{ uuid: string }>({ uuid: '' });

  const [permissions, setPermissions] = useState<StartupRadarPermissions>();
  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false);

  // These useState hooks are used to store the selected filters
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedFoundedOnYears, setSelectedFoundedOnYears] = useState<number[]>([]);
  const [selectedAnnouncedOnYears, setSelectedAnnouncedOnYears] = useState<number[]>([]);
  const [selectedInvestmentTypes, setSelectedInvestmentTypes] = useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [displayPinnedCompanies, setDisplayPinnedCompanies] = useState<boolean>(
    false ||
      // ANNOTATION SPECIALISTS have by default the pinned companies displayed
      roles.includes(SURUserRole.SUR_ANNOTATION_SPECIALIST),
  );
  const [displayReviewedCompanies, setDisplayReviewedCompanies] = useState<boolean>(false);

  const detectSize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, 'startupradar_permissions', user?.uid ?? ''),
      snapshot => {
        const data = snapshot.data();
        setPermissions(data as StartupRadarPermissions);
      },
      error => {
        //  TODO: Show error notification
      },
    );
    return unsub;
  }, [user?.uid]);

  React.useEffect(() => {
    window.addEventListener('resize', detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [width]);

  return (
    <StartupRadarDataCtx.Provider
      value={{
        width,
        searchOrganizationDetail: searchOrganizationDetail,
        setSearchOrganizationDetail: setSearchOrganizationDetail,
        selectedOrganization: selectedOrganization,
        setSelectedOrganization: setSelectedOrganization,
        selectedCategory: selectedCategory,
        setSelectedCategory: setSelectedCategory,

        // Taxonomy | Categories
        positioningLogic: positioningLogic,
        positioningLogicMap: positioningLogicMap,
        poisitioningLogicLoading: poisitioningLogicLoading,
        deliverables: deliverables,
        deliverablesMap: deliverablesMap,
        deliverablesLoading: deliverablesLoading,
        permissions: permissions,
        enableSaveButton: enableSaveButton,
        setEnableSaveButton: setEnableSaveButton,

        // Filter Options
        filterOptionsCountries: filterOptions.countries,
        filterOptionsInvestmentTypes: filterOptions.investmentTypes,
        selectedCountries: selectedCountries,
        selectedFoundedOnYears: selectedFoundedOnYears,
        selectedAnnouncedOnYears: selectedAnnouncedOnYears,
        selectedInvestmentTypes: selectedInvestmentTypes,
        selectedIndustries: selectedIndustries,
        displayPinnedCompanies: displayPinnedCompanies,
        displayReviewedCompanies: displayReviewedCompanies,

        // Filter Options Setters
        setSelectedCountries: setSelectedCountries,
        setSelectedFoundedOnYears: setSelectedFoundedOnYears,
        setSelectedAnnouncedOnYears: setSelectedAnnouncedOnYears,
        setSelectedInvestmentTypes: setSelectedInvestmentTypes,
        setSelectedIndustries: setSelectedIndustries,
        setDisplayPinnedCompanies: setDisplayPinnedCompanies,
        setDisplayReviewedCompanies: setDisplayReviewedCompanies,
      }}
    >
      {children}
    </StartupRadarDataCtx.Provider>
  );
};
