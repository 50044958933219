import { useContext } from 'react';
import { Stack, styled, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// CUSTOM
import HeroImage from '../../assets/startup-radar-hero.png';
import StartupRadarLogo from '../../assets/startup-radar-logo.png';
import useWindowSize from '../../hooks/useWindowSize';
import { SURUserRole } from '../AdminPage/types';
import { AuthCtx } from '../../AuthProvider';

const MenuButton = styled(Button)({
  background: 'white',
  borderRadius: 7,
  textTransform: 'none',
  display: '-webkit-inline-flex',
  fontSize: '1.5rem',
  cursor: 'pointer',
  color: '#000000',
  fontFamily: 'OpenSans-Regular',
  maxWidth: '300px',
  fontWeight: 500,
  '&:hover': {
    background: 'white',
  },
  '&.MuiButton-root': {
    padding: '1rem 1.5rem',
  },
});

const StartupRadarHomePage = () => {
  const { roles } = useContext(AuthCtx);
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  return (
    <Stack
      style={{
        height: '100%',
        // background: 'linear-gradient(312deg, #e3e3e3, #d0e7e8, #d0e7e8, #f5ded1)',
        background: 'linear-gradient(312deg, #e3e3e3, #d0e7e8, #fff9d6, #f5ded1)',
        backgroundSize: '800% 800%',
        animation: 'AnimationName 15s ease infinite',
      }}
    >
      <Stack direction="row" sx={{ height: '100%' }}>
        {windowSize.width && windowSize.width > 1024 && (
          <Stack flex={1} direction="row">
            <Box
              flex={2}
              sx={{
                backgroundImage: `url(${HeroImage})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          </Stack>
        )}
        <Stack flex={1}>
          <Stack
            flex={1}
            spacing={2}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                mb: 4,
              }}
              spacing={2}
            >
              <Typography
                sx={{
                  fontWeight: '700',
                  fontSize: '2.5rem',
                  lineHeight: '116px',
                  // marginLeft: -15,
                  fontFamily: 'OpenSans-Regular',
                }}
              >
                Startup Radar
              </Typography>
              <img src={StartupRadarLogo} style={{ width: '90px', height: '90px' }} alt="Startup Radar Logo" />
            </Stack>
            {
              // Check either SUR_ADMIN or SUR_ANNOTATION_SPECIALIST is present in the user roles
              (roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 || roles.indexOf(SURUserRole.SUR_USER) !== -1) && (
                <>
                  <MenuButton onClick={() => navigate('market')}>Market Activities</MenuButton>
                  <MenuButton onClick={() => navigate('startups')}>Startups</MenuButton>
                  <MenuButton onClick={() => navigate('investors')}>Investors</MenuButton>
                </>
              )
            }
            {
              // Check either SUR_ADMIN or SUR_ANNOTATION_SPECIALIST is present in the user roles
              (roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 ||
                roles.indexOf(SURUserRole.SUR_ANNOTATION_SPECIALIST) !== -1) && (
                <MenuButton onClick={() => navigate('classification')}>Classification</MenuButton>
              )
            }
            {
              // Check either SUR_ADMIN or SUR_ANNOTATION_SPECIALIST is present in the user roles
              roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 && (
                <MenuButton onClick={() => navigate('/admin')}>Admin Panel</MenuButton>
              )
            }
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StartupRadarHomePage;
