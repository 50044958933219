import React, { FC, useState } from 'react';
// react-chart Library
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// MUI Material
import { Stack, Box, Select, MenuItem, SelectChangeEvent, Typography, OutlinedInput } from '@mui/material';

Chart.register(...registerables);

// Bar Chart
interface DisplayBarChartProps {
  data: Array<number>;
  label: Array<string>;
  onCWSelect: (calendarWeek: string) => void;
  month?: string;
}

const DisplayBarChart: FC<DisplayBarChartProps> = ({ data, label, onCWSelect, month }) => (
  <Bar
    data={{
      labels: label,
      datasets: [
        {
          label: 'no_of_companies',
          data: data,
          backgroundColor: ['#eaedef', '#c2cfd7', '#9aa7af', '#7f919d', '#eaedef'],
        },
      ],
    }}
    options={{
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          stacked: true,
          title: {
            display: true,
            text: month,
          },
          ticks: {
            autoSkip: false,
            font: {
              size: 8,
            },
            stepSize: 0,
          },
        },
        y: {
          grid: {
            display: true,
          },
          title: {
            display: true,
            text: '# of companies',
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Labeled Companies',
        },
        legend: {
          position: 'bottom',
          labels: {
            generateLabels: chart => {
              if (chart.data.labels !== undefined) {
                return chart.data?.labels.map((chart_label, index: number) => {
                  interface backgroundColorType {
                    [key: string]: string;
                  }
                  const text = chart?.data?.labels as Array<string>;
                  const fillStyle = chart?.data?.datasets?.[0]?.backgroundColor as backgroundColorType;
                  return {
                    text: text[index],
                    strokeStyle: fillStyle[index],
                    fillStyle: fillStyle[index],
                    hidden: false,
                  };
                });
              } else {
                return [];
              }
            },
          },
        },
      },
      onClick: function (evt, element) {
        if (element.length > 0) {
          const clickedIndex = element[0].index;
          onCWSelect(label[clickedIndex]);
        }
      },
    }}
  />
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IStatisticGraphProps {
  data: Array<{ year: number; CW: number; count: number }>;
  months: Array<string>;
  selectedMonth: string;
  onMonthSelect: (month: string) => void;
  onCWSelect: (cw: string) => void;
}
const StatisticsGraph: FC<IStatisticGraphProps> = ({
  data,
  months,
  selectedMonth,
  onMonthSelect,
  onCWSelect,
}: IStatisticGraphProps) => {
  // Use Context values
  const [calendarWeekLabels, setCalendarWeekLabels] = useState<Array<string>>([]);
  const [sumOfOrganizationByCw, setSumOfOrganizationByCw] = useState<Array<number>>([]);

  const handleMonthChange = (event: SelectChangeEvent) => {
    onMonthSelect(event.target.value as string);
    // const month = calculateLabelledSumOfOrganizationGraphData(parseInt(event.target.value) + 1);
  };

  React.useEffect(() => {
    const labels: Array<string> = [];
    const numbers: Array<number> = [];
    data.forEach(_item => {
      labels.push(`CW${_item.CW} ${_item.year}`);
      numbers.push(_item.count);
    });
    setCalendarWeekLabels(labels);
    setSumOfOrganizationByCw(numbers);
  }, [data]);

  return (
    <Stack sx={{ height: '75vh' }}>
      <Stack direction="row" flex={1}>
        <Stack flex={1}>
          <Box
            sx={{
              flex: 1,
              margin: '4px',
              position: 'relative',
              border: '1px solid white',
              height: '250px',
            }}
          >
            <DisplayBarChart
              data={sumOfOrganizationByCw}
              label={calendarWeekLabels}
              month={(months[Number(selectedMonth)] ?? '').split(' ')[0]}
              onCWSelect={onCWSelect}
            />
          </Box>
        </Stack>
        <Stack flex={1} sx={{ maxWidth: '140px' }}>
          <Typography>Month</Typography>
          <Select
            value={`${selectedMonth}`}
            onChange={handleMonthChange}
            sx={{
              background: '#D9D9D9',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}
            size="small"
            displayEmpty
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={selected => {
              return (months[Number(selected)] ?? '').split(' ')[0];
            }}
          >
            {months.map((month, index) => (
              <MenuItem key={month} value={index}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StatisticsGraph;
