import React, { FC, useEffect, useState } from 'react';

// MATERIAL UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useFilter from '../../pages/StartupRadar/components/hooks/useFilter';

const CenterDisplay = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
});

interface InvestmentTypePickerProps {
  onInvestmentTypeChange?: (investmentType: string[]) => void;
  setReset?: boolean;
  onReset: () => void;
}

const InvestmentTypePicker: FC<InvestmentTypePickerProps> = ({ onInvestmentTypeChange, setReset, onReset }) => {
  const { filterOptions } = useFilter();
  const [selectedInvestmentTypes, setSelectedInvestmentTypes] = useState<string[]>([]);

  useEffect(() => {
    if (setReset) {
      setSelectedInvestmentTypes([]);
      onReset();
    }
  }, [setReset, onReset]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleInvestmentTypeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const newSelectedInvestmentTypes = Array.isArray(value) ? value : [value];
    setSelectedInvestmentTypes(newSelectedInvestmentTypes);
    if (onInvestmentTypeChange) {
      onInvestmentTypeChange(newSelectedInvestmentTypes);
    }
  };

  return (
    <Stack spacing={1} px={2} pr={3} sx={{ display: 'flex', position: 'relative' }}>
      <CenterDisplay>
        <FormControl sx={{ m: 1, display: 'flex', flex: 3 }}>
          <InputLabel id="investment-type-multiple-checkbox-label">Investment type</InputLabel>
          <Select
            labelId="investment-type-checkbox-label"
            id="investment-type-multiple-checkbox"
            multiple
            value={selectedInvestmentTypes ?? []}
            onChange={handleInvestmentTypeChange}
            input={<OutlinedInput label="Investment Type" />}
            MenuProps={MenuProps}
            renderValue={selected =>
              selected
                .map(name_value => {
                  const { name } =
                    filterOptions?.investmentTypes?.level_0?.find(({ name: id }) => id === name_value) ??
                    filterOptions?.investmentTypes?.level_1?.find(({ name: id }) => id === name_value) ??
                    filterOptions?.investmentTypes?.level_2?.find(({ name: id }) => id === name_value) ??
                    {};
                  return name;
                })
                .join(', ')
            }
          >
            {Object.keys(filterOptions?.investmentTypes).map(key => {
              return key === 'level_0' ? (
                // Level 0 checkboxes
                filterOptions?.investmentTypes[key].map(item => (
                  <MenuItem key={item.uuid} value={item.name}>
                    <Checkbox
                      checked={selectedInvestmentTypes.includes(item.name)}
                      onChange={event => {
                        const newChecked = [...(selectedInvestmentTypes ?? [])];
                        if (event.target.checked) {
                          newChecked.push(item.name);
                        } else {
                          const index = newChecked.indexOf(item.name);
                          if (index > -1) {
                            newChecked.splice(index, 1);
                          }
                        }
                        setSelectedInvestmentTypes(newChecked);
                      }}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))
              ) : key === 'level_1' ? (
                // Level 1 checkboxes
                <Accordion key={key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Later Stage</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MenuList>
                      {filterOptions?.investmentTypes[key].map(item => (
                        <MenuItem key={item.name} value={item.name}>
                          <Checkbox
                            checked={selectedInvestmentTypes.includes(item.name)}
                            onChange={event => {
                              const newChecked = [...(selectedInvestmentTypes ?? [])];
                              if (event.target.checked) {
                                newChecked.push(item.name);
                              } else {
                                const index = newChecked.indexOf(item.name);
                                if (index > -1) {
                                  newChecked.splice(index, 1);
                                }
                              }
                              setSelectedInvestmentTypes(newChecked);
                            }}
                          />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </MenuList>
                  </AccordionDetails>
                </Accordion>
              ) : key === 'level_2' ? (
                // Level 2 checkboxes
                <Accordion key={key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Other forms of funding</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {filterOptions?.investmentTypes[key].map(item => (
                      <MenuItem key={item.name} value={item.name}>
                        <Checkbox
                          checked={selectedInvestmentTypes.includes(item.name)}
                          onChange={event => {
                            const newChecked = [...(selectedInvestmentTypes ?? [])];
                            if (event.target.checked) {
                              newChecked.push(item.name);
                            } else {
                              const index = newChecked.indexOf(item.name);
                              if (index > -1) {
                                newChecked.splice(index, 1);
                              }
                            }
                            setSelectedInvestmentTypes(newChecked);
                          }}
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : null;
            })}
          </Select>
        </FormControl>
      </CenterDisplay>
      <Divider />
    </Stack>
  );
};

export default InvestmentTypePicker;
