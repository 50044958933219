import { Stack, Typography, styled } from '@mui/material';
import ActiveInvestors from './StartupRadar/components/ActiveInvestors/ActiveInvestors';

const GraphTitle = styled(Typography)(() => ({
  fontSize: '0.8rem',
  color: '#000000',
  fontWeight: 700,
}));

const StyledSectionCard = styled(Stack)(() => ({
  boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '13px',
  background: 'white',
  height: '400px',
  width: '800px',
}));

const DevPage = () => {
  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      }}
    >
      <StyledSectionCard>
        <GraphTitle gutterBottom>Active Investors</GraphTitle>
        <ActiveInvestors />
      </StyledSectionCard>
    </Stack>
  );
};

export default DevPage;
