export const gqlOrganization = `
  uuid
  name
  cbUrl
  region
  homepageUrl
  description
  shortDescription
  totalFundingUsd
  numFundingRounds
  status
  foundedOn
  countryCode
  logoUrl
  city
  pinned
  reviewed
  reviewedBy
  taxonomy {
    type
    uuid
  }
`;

export const gqlInvestor = `
  name
  city
  countryCode
  source
  uuid
  cbUrl
  funds{
    name
    raisedAmountUsd
  }
`;
