import React, { useContext, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import './App.css';

// Firebase
import { getAuth, signOut } from 'firebase/auth';

// MUI
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Container, Stack } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// Auth Context
import { AuthCtx } from './AuthProvider';

// Custom Components
import LoadingPage from './pages/LoadingPage';
import UserProfileImage from './components/UserProfileImage';
import { Title } from './components/Shared/Title';
import UnauthorizedPage from './pages/UnauthorizedPage';
import NotificationManager from './pages/NotificationManager';
import { UIContext } from './UIProvider';
import { SURUserRole } from './pages/AdminPage/types';
import AccessDenied from './components/AccessDenied';

const PageContainer = styled('div')({
  left: '60px',
  right: '0',
  bottom: '0',
  display: 'flex',
  flexDirection: 'column',
});

const UserIconButton = styled(IconButton)({
  position: 'absolute',
  right: '0',
  top: '0',
  zIndex: 10,
});

const StyledPageContainer = styled(PageContainer)({
  overflowY: 'auto',
  flex: 1,
});
export interface SecureLayoutProps {
  title?: string;
  adminRequired?: boolean;
  permissionRequired?: boolean;
  permissionName?: string;
  acceptedRoles?: string[];
  overflow?: boolean;
  restrictToRoles?: string[];
  accessableByRoles?: string[];
}

const SecureLayout = ({
  children,
  title,
  adminRequired,
  permissionRequired,
  permissionName,
  acceptedRoles = [],
  overflow = true,
  restrictToRoles = [],
  accessableByRoles = [],
}: { children: JSX.Element } & SecureLayoutProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { darkMode } = useContext(UIContext);
  const { user, customClaims, loading: loadingUser, permissions, roles } = useContext(AuthCtx);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  const location = useLocation();

  const isAccessible = useMemo(() => {
    const restrictions = roles.map(userRole => {
      if (restrictToRoles.includes(userRole)) return true;
      if (accessableByRoles.length && !accessableByRoles.includes(userRole)) return true;
      return false;
    });

    return restrictions.includes(false);
  }, [roles, restrictToRoles, accessableByRoles]);

  if (loadingUser) {
    return <LoadingPage />;
  } else if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (adminRequired && (customClaims == null || !customClaims.isAdmin)) {
    return <UnauthorizedPage />;
  }

  if (permissionRequired && permissionName && permissions.indexOf(permissionName) === -1) {
    return <UnauthorizedPage />;
  }

  if (
    permissionRequired &&
    acceptedRoles.length > 0 &&
    // Make sure the user has at least one of the accepted roles
    roles.filter(role => acceptedRoles.indexOf(role) !== -1).length === 0
  ) {
    return <UnauthorizedPage />;
  }

  if (!isAccessible) {
    return <AccessDenied />;
  }

  const setMargin = !!title;

  return (
    <>
      <NotificationManager />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          // Check if SUR_ADMIN is present in the user roles
          roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 && (
            <MenuItem onClick={() => navigate('/admin')}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="small" />
              </ListItemIcon>
              Admin Panel
            </MenuItem>
          )
        }
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <UserIconButton size="large" edge="start" color="info" aria-label="menu" onClick={handleClick}>
        <UserProfileImage />
      </UserIconButton>
      <StyledPageContainer
        sx={{
          top: setMargin ? '160px' : 0,
          background: darkMode ? 'black' : 'white',
        }}
      >
        <Container maxWidth="xl" sx={{ position: 'relative' }}>
          {title ? (
            <Title gutterBottom sx={{ color: darkMode ? 'white' : 'black' }}>
              {title}
            </Title>
          ) : null}
        </Container>
        <Stack maxWidth={undefined} sx={{ position: 'relative', flex: 1, overflowY: overflow ? 'auto' : 'none' }}>
          {children}
        </Stack>
      </StyledPageContainer>
    </>
  );
};

export default SecureLayout;
