import { Button, Stack } from '@mui/material';
import { useContext } from 'react';
import { StartupRadarDataCtx } from '../StartupRadarDataProvider';

interface IFooterToolbar {
  saveDisabled?: boolean;
  onSave: () => void;
}

const FooterToolbar = ({ saveDisabled = false, onSave }: IFooterToolbar) => {
  const { enableSaveButton } = useContext(StartupRadarDataCtx);

  return (
    <Stack flex="2" alignItems="flex-end" pr={1} justifyContent="center">
      <Button
        onClick={onSave}
        disabled={!enableSaveButton}
        color="primary"
        variant="contained"
        sx={{ width: '110px', height: '36px', borderRadius: 0 }}
      >
        Save
      </Button>
    </Stack>
  );
};

export default FooterToolbar;
