export const colorMap: { [key: string]: string } = {
  angel: '#90BE6D',
  pre_seed: '#43AA8B',
  seed: '#4D908E',
  series_a: '#577590',
  series_b: '#F3722C',
  series_c: '#F8961E',
  series_d: '#F9844A',
  series_e: '#F9C74F',
  series_f: '#720026',
  series_g: '#CE4257',
  series_h: '#FF7F51',
  series_i: '#FF9B54',
  series_j: '#366666',
  corporate_round: '#277DA1',
  post_ipo_debt: '#B1CECF',
  post_ipo_equity: '#21B6A8',
  post_ipo_secondary: '#5E4D38',
  private_equity: '#153250',
  debt_financing: '#FBB80F',
  secondary_market: '#B4F8C8',
  convertible_note: '#ff2160',
  equity_crowdfunding: '#3fb7be',
  grant: '#3B2244',
  initial_coin_offering: '#970C10',
  non_equity_assistance: '#2486a5',
  product_crowdfunding: '#FBE7C6',
  series_unknown: '#edeec9',
  undisclosed: '#787D12',
  default: '#d44a51',
  Other: '#6E7071',
};

export const fixColorMapList = [
  '#90BE6D',
  '#43AA8B',
  '#4D908E',
  '#577590',
  '#F3722C',
  '#F8961E',
  '#F9844A',
  '#F9C74F',
  '#720026',
  '#CE4257',
  '#FF7F51',
  '#FF9B54',
  '#366666',
  '#277DA1',
  '#B1CECF',
  '#21B6A8',
  '#5E4D38',
  '#153250',
  '#FBB80F',
  '#B4F8C8',
  '#ff2160',
  '#3fb7be',
  '#3B2244',
  '#970C10',
  '#2486a5',
  '#FBE7C6',
  '#edeec9',
  '#787D12',
  '#d44a51',
  '#6E7071',
];

export const industryColorMap: { [key: string]: string } = {
  '25f87bad-ef61-4f9e-8dea-8f6d6c4cd2cf': '#ED0A3F',
  '0ff1b57e-b6d8-497f-b5de-306a5d5f95e2': '#C32148',
  '02a2ed47-2be0-48c9-88ee-8bbc052b53f8': '#FD0E35',
  '2171f150-cccb-40d9-9d01-5eee24f4444c': '#C62D42',
  'd73126bd-dc00-4301-9b30-3a91727e87c7': '#CC474B',
  '067da033-3d2c-4cc7-811a-622122d81b3c': '#CC3336',
  'ad9b3eaa-1b8c-4571-a672-d5db9cf0606d': '#E12C2C',
  'deb90e60-0138-4b5a-8966-aea3b4112c5b': '#D92121',
  '2252e111-d101-43a4-963b-69bf6ce9d45a': '#B94E48',
  '094f4a64-1c67-4df1-a0d9-e42c2d65b8d8': '#FF5349',
  '63330d32-c9ba-40e1-8d64-41fd59c6df7a': '#FE4C40',
  '19263f0d-073b-43eb-909e-dd1572ce6f30': '#FE6F5E',
  '9356e07b-23cb-4799-a9ab-3e1e340a2db9': '#B33B24',
  '1f8609a8-a3f5-40f4-831f-6cc3b76d950b': '#CC553D',
  'ee3044e2-982e-48c9-ab65-8f9d9b1d1d6f': '#E6735C',
  '69899972-6118-4de1-b1de-16060447f7bb': '#FF9980',
  '52bcb739-75d2-4cb0-ac6a-829933eae3bb': '#E58E73',
  'd48d984c-70a4-4760-84ae-15afe9d4ed20': '#FF7F49',
  'b3b059cd-6e9d-4ea4-ad5b-6bba7d29d1b5': '#FF681F',
  'c420f29d-34d0-4d8f-9e3e-0648a5c978b1': '#FF8833',
  '09e54167-c8b5-4910-9dba-6b1b6b353bc5': '#FFB97B',
  '79fab900-bd68-46aa-b9ac-8c57c974bbb9': '#ECB176',
  '1e74c50d-4424-49e1-b0a7-c5d5a7bb7b74': '#E77200',
  '7291eac7-0554-42ae-83fc-033d7b4c74a7': '#FFAE42',
  '49f30ebb-0fe8-4517-9885-62f6591ed9e1': '#F2BA49',
  '99b804a2-99e2-4abf-84f4-394cd2978965': '#FBE7B2',
  '6df853f1-cf80-479c-a93d-783dc231ece4': '#F2C649',
  '9cce86e5-ecca-450c-a98a-55f91e552b2b': '#F8D568',
  'cb28d660-d816-4a92-8d3d-e913af7fd455': '#FCD667',
  '7ab963f5-137d-4a23-96b9-8bf09d5ad6c8': '#FED85D',
  '95ffc722-b1ed-4868-8bcc-e6bbba66442f': '#FBE870',
  'f840d181-f0e2-41f9-9f81-bacff1c393ec': '#F1E788',
  '91db0b2e-4be6-48f0-924a-d0b3162253bc': '#FFEB00',
  '4376c505-4d9e-4f44-88f0-129e179ee38b': '#B5B35C',
  '2789d36b-72fc-43b9-97ab-25bdf83289dd': '#FAFA37',
  '7c782a73-5d56-41ba-baf2-ffaabf2ea71c': '#FFFF99',
  '80c46877-1306-41d9-a64d-0036ee45ecb1': '#FFFF9F',
  'afed5555-b726-4643-845a-3a81f844081c': '#D9E650',
  'c3a18597-99f4-4158-abae-48ae6bc64330': '#ACBF60',
  '5ff573bb-5e78-4569-b8b6-af5add876bd9': '#AFE313',
  '35282052-8a50-450e-b994-d823a7e072dc': '#BEE64B',
  'bd7b0018-5a50-44e2-be6b-b97282e165ab': '#C5E17A',
  '5ed83694-9ff6-4850-9f4a-d4cfa3224a65': '#5E8C31',
  '479562d6-c9ea-4d60-9b87-d1d831d3261b': '#7BA05B',
  'ea18ad99-7329-4a40-b473-b43cfd21c035': '#9DE093',
  '15e40fa2-c687-41d5-b8f5-fc94796b7be7': '#63B76C',
  '50bff467-54a5-40a2-9830-2213312dd569': '#4D8C57',
  'ef5e82c4-f685-4fec-b170-d13081d5e824': '#3AA655',
  'bd303173-0e6a-4f30-a51c-a6fb513ee10e': '#6CA67C',
  '2581d7d6-0481-423f-afc3-38fd785da09e': '#5FA777',
  'da17628b-9a96-4c3d-82f9-4d25bbdf03b2': '#93DFB8',
  '56418b88-3ea0-43e9-be2b-900fd5026244': '#33CC99',
  '41f18a2f-bc29-43d0-97cb-d16c11bc2636': '#1AB385',
  '18b4dc70-377b-47ae-bece-feb312128b8d': '#29AB87',
  'eaba1a80-14a1-4d22-b523-932a51cfe117': '#00CC99',
  '054aa7b5-d71f-41dc-b60c-76c9a5fcfe20': '#00755E',
  '2fcc7942-fd11-46fd-84e8-8fcaa0eaa287': '#8DD9CC',
  'a1c86172-2d8d-4c0d-8c30-dc04b9099b8b': '#01786F',
  'e45beae4-7953-48db-bcc3-efe012f2d165': '#30BFBF',
  '014784d4-e791-482a-bf8c-b6a5cbeb9762': '#00CCCC',
  '5fdb77de-76ac-4e74-9b84-11bd83360a02': '#008080',
  'ab0915f8-7b6a-41b9-a903-64ad76f7cc36': '#8FD8D8',
  'c450f0f1-224c-4acb-991f-cf285b6a9f2a': '#95E0E8',
  'f8a75120-893c-47fd-8533-cecaf9425f3d': '#6CDAE7',
  '133218ab-88a3-4174-bd9a-8ae79907e1fb': '#2D383A',
  'a5bcc623-2db6-4060-aa4f-5bf18125fbd8': '#76D7EA',
  'c8ca2ea1-9059-4025-9f6d-59fcf881941f': '#7ED4E6',
  '21abe462-0762-493d-b034-dc251f81f1bc': '#0095B7',
  '86fc9568-f05d-4721-9b95-5083ea7245d4': '#009DC4',
  '603dbfd8-9fbe-4c60-a111-9a9f9fd8d3af': '#02A4D3',
  '1a8ab4df-91b1-43a3-97c6-babc0e46c489': '#47ABCC',
  '3f63be22-cf1d-46c0-9ded-fda306b141c8': '#4997D0',
  '8669a629-f0ea-4684-a9a8-7ed1e94ed492': '#339ACC',
  'd30842c1-726f-4f60-845e-9d8ff52c2b60': '#93CCEA',
  'f722fed2-125e-464e-9a01-88956f8931c7': '#2887C8',
  '98a33f94-c9ca-44f3-88cf-4b13ecd28387': '#00468C',
  '20868b4b-43b0-4759-945d-ee9c09c6cd50': '#0066CC',
  '41e0d784-24d5-40f8-816b-a5b7735027d3': '#1560BD',
  'dcd6914f-262b-4dee-8cca-923cb2328a20': '#0066FF',
  'd569c4f0-b821-41ef-83db-0bec2bea983d': '#A9B2C3',
  '2ce8ee26-a452-4252-8935-7581e99b7842': '#C3CDE6',
  '061212e5-72ad-4360-a972-b8b5c025ea59': '#4570E6',
  '26bbff64-aeed-4468-982f-9b8a8e548aaa': '#7A89B8',
  '2963388c-959d-4b03-9700-6c6a4d9923f4': '#4F69C6',
  '3bc0e8c1-054c-425d-bc29-e440a0633197': '#8D90A1',
  '543dcf6a-5844-483a-bc74-13a98a3b503f': '#8C90C8',
  '12729ca6-3330-4aa4-b775-75aaa6c9addf': '#7070CC',
  '6ddd23a4-dc15-4e82-a354-759ab9f654e5': '#9999CC',
  '720a188b-587c-4e5e-abee-3720f10568f1': '#ACACE6',
  '75002657-0fea-4f17-a7c1-39195a6fcb33': '#766EC8',
  '2f96e63e-713b-405f-8b7f-6136940b8385': '#6456B7',
  'c60e1fc6-f634-4eda-92fa-8669f2302a3d': '#3F26BF',
  'fad4eec8-86aa-4087-801b-4e679649c198': '#8B72BE',
  '62b48dfd-c937-43b4-bfab-56194e001af4': '#652DC1',
  '0e245446-9e93-406d-8904-e9f184458433': '#6B3FA0',
  'ec2dd331-d713-497b-babb-7db0cd5aaf87': '#8359A3',
  '549c300c-06c0-4ae4-80f1-a63a5bae0c34': '#8F47B3',
  'bdacf502-899b-436a-8fbe-1e040132a202': '#C9A0DC',
  'd7e3eba6-8c24-40e0-ad9a-9e4ff1e39647': '#BF8FCC',
  '0120b58a-0168-425e-b22c-b25832171153': '#803790',
  '5073b9c2-b953-4247-b0fd-cd426c5a8b3b': '#733380',
  'a35a8f2d-55dc-425b-a8b4-40970e923155': '#D6AEDD',
  '8978d9bd-d13a-49db-bf65-9bba71fab0bc': '#C154C1',
  '7720e474-ec5f-4f2e-8b35-f47db4efdda5': '#FC74FD',
  '885dfefb-6e12-4585-b2ed-4f06c62e6159': '#732E6C',
  '4f8c551e-d035-41be-ab03-58b20518cb97': '#E667CE',
  '5a72b2c9-00c5-4b70-ae9c-6d24289f491f': '#E29CD2',
  'b505cda0-9fc1-4d06-a276-b0580d2748c8': '#8E3179',
  '8c233ebf-c4dc-4116-babf-9f8fceb5ec00': '#D96CBE',
  '529b2085-c761-4b0c-9b36-0a1ff9cbe283': '#EBB0D7',
  'a17a2c55-1b2d-4fc2-93c9-092cac232c70': '#C8509B',
  'c4fb87b0-db0a-428b-b80c-c3d9856945a3': '#BB3385',
  'b6fb91ea-a027-474f-8d26-e02df90ab797': '#D982B5',
  'a4177cb0-35b9-434b-b742-7f2182a3864d': '#A63A79',
  'b758c2fd-dc51-4ac5-9e39-600a84d0b31a': '#A50B5E',
  '10170761-8df2-494e-b31c-5c9f038b3885': '#614051',
  'dfc3b254-77d5-44b3-b63e-923c11342996': '#F653A6',
  '2280ccc6-a5f3-4bab-81ce-901aed3def45': '#DA3287',
  '8b6031a5-c786-411b-b8c9-8b0e486f99ed': '#FF3399',
  'f0944ce1-21a8-417e-a4a6-422d962d10c3': '#FBAED2',
  'fa3d3eca-08ac-4343-af9b-273a16c7865f': '#FFB7D5',
  '8d22c625-3109-4f99-aaa6-32805bbbb2f2': '#FFA6C9',
  '66b7668d-67dc-4bd0-ae06-ce9c02a401bc': '#F7468A',
  'cfba540c-9e02-497d-883c-80c034c68df7': '#E30B5C',
};
