import { useQuery, TypedDocumentNode, gql } from '@apollo/client';
import { Investor } from '../types';
import { gqlInvestor } from './shared';

interface InvestorsData {
  investors: {
    items: Investor[];
    totalCount: number;
    count: number;
  };
}

interface InvestorsVars {
  searchText?: string;
  page?: number;
  categories?: string[];
  deliveryTypes?: string[];
  countries?: string[];
  limit?: number;
}

export const INVESTORS: TypedDocumentNode<InvestorsData, InvestorsVars> = gql`
query Investors(
  $searchText: String
  $page: Int
  $categories: [String!]
  $deliveryTypes: [String!]
  $countries: [String!]
  $limit: Int
) {
    investors(searchText: $searchText, page: $page, categories: $categories, deliveryTypes: $deliveryTypes, countries: $countries, limit: $limit) {
      totalCount
      count
      items {
        ${gqlInvestor}
      }
    }
  }
`;

const useInvestors = (vars: InvestorsVars) => {
  const {
    data: { investors } = { totalCount: 0, items: [] },
    loading,
    refetch,
  } = useQuery(INVESTORS, { variables: vars });

  return { investors, loading, refetch };
};

export type { InvestorsData, InvestorsVars };
export default useInvestors;
