import { FC, useCallback, useEffect, useMemo, useState } from 'react';
// MUI
import { Stack } from '@mui/material';
// apollo
import { ChartData, Plugin } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut as Pie } from 'react-chartjs-2';
import MarketAnalysisGridCardHeader from '../../MarketAnalysis/MarketAnalysisGridCardHeader';
import { formatString, investmentOrders } from '../../Utils';
import useInvestmentTypesAllocation from '../../graphql/useAllocationOfInvestmentTypes';
import SURGraphModal from '../SURGraphModal';
import { colorMap } from '../UIProvider';

interface RenderAllocationOfInvestmentTypesGraphProps {
  loading: boolean;
  data: ChartData<'doughnut', number[], unknown>;
  displayLegend?: boolean;
}

const RenderAllocationOfInvestmentTypesGraph: FC<RenderAllocationOfInvestmentTypesGraphProps> = ({
  loading,
  data,
  displayLegend = false,
}) => {
  const formattedInvestmentType = (
    _value: number,
    context: {
      dataIndex: number;
      dataset: { data: Array<number> };
      chart: { data: { labels: Array<string> } };
    },
  ) => {
    const label = context.chart.data.labels[context.dataIndex];
    const totalInvestment = context.dataset.data.reduce((total, item) => total + item, 0);
    const investmentPercentage = (context.dataset.data[context.dataIndex] / totalInvestment) * 100;
    const text = label
      ? label.replace('_', ' ').replace(/\w\S*/g, function (txt) {
          return `${txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()}`;
        })
      : '';
    if (investmentPercentage < 5) {
      return `Other\n${investmentPercentage.toFixed(2)}%`;
    } else {
      return `${text}\n${investmentPercentage.toFixed(2)}%`;
    }
  };
  if (loading) {
    return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}> Loading...</Stack>;
  }

  return (
    <Pie
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        cutout: '30%',
        plugins: {
          legend: {
            display: displayLegend ? true : false,
            position: 'right',
            labels: {
              generateLabels(chart) {
                if (chart.data.labels !== undefined) {
                  return chart.data?.labels.map((chart_label, index: number) => {
                    interface backgroundColorType {
                      [key: string]: string;
                    }
                    const text = chart?.data?.labels as Array<string>;
                    const fillStyle = chart?.data?.datasets?.[0]?.backgroundColor as backgroundColorType;
                    return {
                      text: formatString(text[index]),
                      strokeStyle: fillStyle[index],
                      fillStyle: fillStyle[index],
                      hidden: false,
                    };
                  });
                } else {
                  return [];
                }
              },
            },
          },
          tooltip: {
            callbacks: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              label: function (context: any): string {
                const value = context.raw;
                const label = context.label;
                // convert value to billions, millions, or thousands
                let formattedValue;
                if (value >= 1_000_000_000) {
                  formattedValue = (value / 1_000_000_000).toFixed(2) + 'B';
                } else if (value >= 1_000_000) {
                  formattedValue = (value / 1_000_000).toFixed(2) + 'M';
                } else if (value >= 1000) {
                  formattedValue = (value / 1000).toFixed(2) + 'K';
                } else {
                  formattedValue = value.toString();
                }
                // return the label with the formatted value
                return formatString(label) + ': $' + formattedValue;
              },
              title: function (context) {
                const title = formatString(context[0].label);
                return title;
              },
            },
          },
        },
      }}
      plugins={
        [
          {
            ...ChartDataLabels,
            defaults: {
              labels: {
                title: {
                  font: {
                    size: 10,
                  },
                  color: 'black',
                },
              },
              anchor: 'right',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              rotation: function (ctx: any) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a: any, b: any) => a + b, 0);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const sum = ctx.dataset.data.reduce((a: any, b: any) => a + b, 0);
                const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum) * 360;
                return rotation < 180 ? rotation - 90 : rotation + 90;
              },
              formatter: (
                _value: number,
                context: {
                  dataIndex: number;
                  dataset: { data: Array<number> };
                  chart: { data: { labels: Array<string> } };
                },
              ) => formattedInvestmentType(_value, context),
            },
          },
        ] as unknown as Plugin<'doughnut'>[]
      }
    />
  );
};

interface AllocationOfInvestmentTypesVars {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
  onLegendUpdate: (investmentType: string[], categories: string[]) => void;
}

const AllocationOfInvestmentTypes: FC<AllocationOfInvestmentTypesVars> = ({
  countries,
  investmentTypes,
  categories,
  years,
  onLegendUpdate,
}) => {
  const vars = { countries, investmentTypes, categories, years };
  const { investmentTypesAllocation, loading } = useInvestmentTypesAllocation(vars);

  // use state variable to track expanded state
  const [expanded, setExpanded] = useState<boolean>(false);

  const data: ChartData<'doughnut', number[], unknown> = useMemo(() => {
    const labels: Array<string> = [];
    const dataset: {
      label: string;
      data: Array<number>;
      backgroundColor: Array<string>;
      borderColor: Array<string>;
      borderWidth: number;
    } = {
      label: 'Allocation of Investments',
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    };

    const totalRaisedAmount = investmentTypesAllocation.reduce((sum, item) => {
      return sum + Number(item.raisedAmountUsd);
    }, 0);

    if (totalRaisedAmount === 0) {
      labels.push('No data');
      dataset.data.push(1);
      dataset.backgroundColor.push(colorMap.default);
      dataset.borderColor.push(colorMap.default);
    } else {
      let otherAmount = 0;
      let hasOtherAddedSlices = false;
      [...(investmentTypesAllocation ?? [])]
        .sort((a, b) => {
          return investmentOrders.indexOf(a.investmentType) - investmentOrders.indexOf(b.investmentType);
        })
        .forEach(item => {
          const raisedAmount = Number(item.raisedAmountUsd);
          const percentage = raisedAmount / totalRaisedAmount;
          if (percentage < 0.05) {
            otherAmount += raisedAmount;
            if (!hasOtherAddedSlices) {
              dataset.data.push(otherAmount);
              dataset.backgroundColor.push(colorMap['Other'] ?? colorMap.default);
              dataset.borderColor.push(colorMap['Other'] ?? colorMap.default);
              labels.push('Other');
              hasOtherAddedSlices = true;
            } else {
              const index = labels.findIndex(label => label === 'Other');
              dataset.data[index] = otherAmount;
            }
          } else {
            dataset.data.push(raisedAmount);
            dataset.backgroundColor.push(colorMap[item.investmentType] ?? colorMap.default);
            dataset.borderColor.push(colorMap[item.investmentType] ?? colorMap.default);
            labels.push(item.investmentType);
          }
        });
    }

    return {
      labels,
      datasets: [dataset],
    };
  }, [investmentTypesAllocation]);

  const onLegendUpdateCallback = useCallback(
    (investmentTypeLabels: string[], categoryLabels: string[]) => {
      onLegendUpdate(investmentTypeLabels, categoryLabels);
    },
    [onLegendUpdate],
  );

  const handleExpandClose = () => {
    setExpanded(false);
  };

  useEffect(() => {
    // on legend update
    if (vars.categories?.length && vars.categories?.length >= 1) {
      // convert READONLY ARRAY to ARRAY
      const tempCategories = vars.categories as string[];
      if (data.labels) {
        const tempLabels: string[] = data.labels as string[];
        onLegendUpdateCallback(tempLabels, tempCategories);
      }
    } else {
      if (data.labels) {
        const tempLabels: string[] = data.labels as string[];
        onLegendUpdateCallback(tempLabels, []);
      }
    }
  }, [vars.categories, onLegendUpdateCallback, data.labels]);

  const graphData = {
    loading,
    data,
  };

  return (
    <Stack>
      <MarketAnalysisGridCardHeader
        id={'allocation-of-investment-types'}
        title={'Allocation of Investment Types'}
        exportOptions={['csv', 'image']}
        onClickExpandIcon={() => {
          setExpanded(true);
        }}
      >
        <RenderAllocationOfInvestmentTypesGraph {...graphData} />
      </MarketAnalysisGridCardHeader>
      {expanded && (
        <SURGraphModal
          open={expanded}
          onClose={handleExpandClose}
          loading={loading}
          title="Allocation of Investment Types"
        >
          <Stack sx={{ minHeight: '300px', height: '80vh', maxHeight: '600px' }}>
            <RenderAllocationOfInvestmentTypesGraph {...graphData} displayLegend={true} />
          </Stack>
        </SURGraphModal>
      )}
    </Stack>
  );
};

export default AllocationOfInvestmentTypes;
