import React, { FC, useContext, useEffect, useState } from 'react';

// MATERIAL UI
import {
  Checkbox,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import countries from '../../../pages/StartupRadar/assets/countries.json';
import { StartupRadarDataCtx } from '../../../pages/StartupRadar/StartupRadarDataProvider';

// import group of countries
import {
  dachCountryCodes,
  naftaCountryCodes,
  africaCountryCodes,
  asiaCountryCodes,
  europeCountryCodes,
  restofWorldCountryCodes,
} from './groupOfCountries';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CenterDisplay = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
});
interface CountryPickerProps {
  onCountryChange?: (country: string[]) => void;
  setReset?: boolean;
  onReset: () => void;
}

const CountryPicker: FC<CountryPickerProps> = ({ onCountryChange, setReset, onReset }) => {
  const { filterOptionsCountries } = useContext(StartupRadarDataCtx);

  // const { filterOptions, setSelectedCountriesToApply } = useFilter();
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [sortedFilterOptionCountries, setSortedFilterOptionCountries] = useState<{ code: string; name: string }[]>([]);

  const [chipFilled, setChipFilled] = React.useState({
    DACH: false,
    europe: false,
    NAFTA: false,
    Asia: false,
    Africa: false,
    RoW: false,
  });

  useEffect(() => {
    // Sort the filter options countries by name
    const countriesJson = countries as { [key: string]: { code: string; name: string; eu: boolean } };
    setSortedFilterOptionCountries([...filterOptionsCountries].sort((a, b) => a.code.localeCompare(b.code)));
    const sortArray = [];
    for (let i = 0; i < filterOptionsCountries.length; i++) {
      for (const key of Object.keys(countries)) {
        if (filterOptionsCountries[i].name === key) {
          sortArray.push({
            code: filterOptionsCountries[i].code,
            name: countriesJson[key].name,
          });
        }
      }
    }

    setSortedFilterOptionCountries(sortArray.sort((a, b) => a.name.localeCompare(b.name)));
  }, [filterOptionsCountries]);

  useEffect(() => {
    if (setReset) {
      setChipFilled({
        DACH: false,
        europe: false,
        NAFTA: false,
        Asia: false,
        Africa: false,
        RoW: false,
      });
      setSelectedCountries([]);
      onReset();
    }
  }, [setReset, onReset]);

  const handleChipFilled = (selectedValues: string[]) => {
    if (chipFilled.DACH === true) {
      if (dachCountryCodes.every(countryCode => selectedValues.includes(countryCode))) {
        setChipFilled({
          ...chipFilled,
          DACH: true,
        });
      } else {
        setChipFilled({
          ...chipFilled,
          DACH: false,
        });
      }
    }
    if (chipFilled.europe === true) {
      if (europeCountryCodes.every(countryCode => selectedValues.includes(countryCode))) {
        setChipFilled({
          ...chipFilled,
          europe: true,
        });
      } else {
        setChipFilled({
          ...chipFilled,
          europe: false,
        });
      }
    }
    if (chipFilled.NAFTA === true) {
      if (naftaCountryCodes.every(countryCode => selectedValues.includes(countryCode))) {
        setChipFilled({
          ...chipFilled,
          NAFTA: true,
        });
      } else {
        setChipFilled({
          ...chipFilled,
          NAFTA: false,
        });
      }
    }
    if (chipFilled.Asia === true) {
      if (asiaCountryCodes.every(countryCode => selectedValues.includes(countryCode))) {
        setChipFilled({
          ...chipFilled,
          Asia: true,
        });
      } else {
        setChipFilled({
          ...chipFilled,
          Asia: false,
        });
      }
    }
    if (chipFilled.Africa === true) {
      if (africaCountryCodes.every(countryCode => selectedValues.includes(countryCode))) {
        setChipFilled({
          ...chipFilled,
          Africa: true,
        });
      } else {
        setChipFilled({
          ...chipFilled,
          Africa: false,
        });
      }
    }
    if (chipFilled.RoW === true) {
      if (restofWorldCountryCodes.every(countryCode => selectedValues.includes(countryCode))) {
        setChipFilled({
          ...chipFilled,
          RoW: true,
        });
      } else {
        setChipFilled({
          ...chipFilled,
          RoW: false,
        });
      }
    }
  };

  /**
   * Handle the country change event
   */
  const handleCountryChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const newSelectedCountries = typeof value === 'string' ? value.split(',') : value;
    setSelectedCountries(newSelectedCountries);
    if (onCountryChange) {
      onCountryChange(newSelectedCountries);
    }
    handleChipFilled(newSelectedCountries);
  };

  function onSelectGroupofCountries(value: boolean, group: string): void {
    //  check if the group is selected or not and set the value to the opposite of the current value
    if (group === 'DACH') {
      setChipFilled({
        ...chipFilled,
        DACH: !value,
      });
      if (!value === true) setSelectedCountries([...selectedCountries, ...dachCountryCodes]);
      else {
        const newSelectedCountries = selectedCountries.filter(
          selectedCountryCode => !dachCountryCodes.includes(selectedCountryCode),
        );
        setSelectedCountries(newSelectedCountries);
      }
    } else if (group === 'Europe') {
      setChipFilled({
        ...chipFilled,
        europe: !value,
      });
      if (!value === true) setSelectedCountries([...selectedCountries, ...europeCountryCodes]);
      else {
        const newSelectedCountries = selectedCountries.filter(
          selectedCountryCode => !europeCountryCodes.includes(selectedCountryCode),
        );
        setSelectedCountries(newSelectedCountries);
      }
    } else if (group === 'NAFTA') {
      setChipFilled({
        ...chipFilled,
        NAFTA: !value,
      });
      if (!value === true) setSelectedCountries([...selectedCountries, ...naftaCountryCodes]);
      else {
        const newSelectedCountries = selectedCountries.filter(
          selectedCountryCode => !naftaCountryCodes.includes(selectedCountryCode),
        );
        setSelectedCountries(newSelectedCountries);
      }
    } else if (group === 'Asia') {
      setChipFilled({
        ...chipFilled,
        Asia: !value,
      });
      if (!value === true) setSelectedCountries([...selectedCountries, ...asiaCountryCodes]);
      else {
        const newSelectedCountries = selectedCountries.filter(
          selectedCountryCode => !asiaCountryCodes.includes(selectedCountryCode),
        );
        setSelectedCountries(newSelectedCountries);
      }
    } else if (group === 'Africa') {
      setChipFilled({
        ...chipFilled,
        Africa: !value,
      });
      if (!value === true) setSelectedCountries([...selectedCountries, ...africaCountryCodes]);
      else {
        const newSelectedCountries = selectedCountries.filter(
          selectedCountryCode => !africaCountryCodes.includes(selectedCountryCode),
        );
        setSelectedCountries(newSelectedCountries);
      }
    } else if (group === 'RoW') {
      setChipFilled({
        ...chipFilled,
        RoW: !value,
      });
      if (!value === true) setSelectedCountries([...selectedCountries, ...restofWorldCountryCodes]);
      else {
        const newSelectedCountries = selectedCountries.filter(
          selectedCountryCode => !restofWorldCountryCodes.includes(selectedCountryCode),
        );
        setSelectedCountries(newSelectedCountries);
      }
    }
  }

  return (
    <Stack spacing={1} px={2} pr={3} sx={{ display: 'flex', position: 'relative' }}>
      <CenterDisplay>
        <FormControl sx={{ m: 1, display: 'flex', flex: 3 }}>
          <InputLabel id="countries-multiple-checkbox-label">Country</InputLabel>
          <Select
            labelId="country-checkbox-label"
            id="country-type-multiple-checkbox"
            multiple
            value={[
              ...new Set(
                selectedCountries.filter(countryCode =>
                  sortedFilterOptionCountries.some(({ code }) => code === countryCode),
                ),
              ),
            ]}
            onChange={handleCountryChange}
            input={<OutlinedInput label="Country" />}
            renderValue={selected =>
              selected
                .map(selectedCountryCode => {
                  const selectedCountry = sortedFilterOptionCountries.find(({ code }) => code === selectedCountryCode);
                  return selectedCountry ? selectedCountry.name : null;
                })
                .filter(Boolean) // Exclude null values
                .join(', ')
            }
            MenuProps={MenuProps}
          >
            {sortedFilterOptionCountries.map(item => (
              <MenuItem key={item.code} value={item.code} sx={{ maxHeight: '25px', backgroundColor: '#EAECF0' }}>
                <Checkbox checked={selectedCountries?.includes(item.code)} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CenterDisplay>
      <Stack direction="row" spacing="2px" marginBottom="10px">
        <Stack direction="row" spacing="2px">
          <Chip
            label="DACH"
            variant={chipFilled.DACH ? 'filled' : 'outlined'}
            onClick={() => onSelectGroupofCountries(chipFilled.DACH, 'DACH')}
          />
          <Chip
            label="Europe"
            variant={chipFilled.europe ? 'filled' : 'outlined'}
            onClick={() => onSelectGroupofCountries(chipFilled.europe, 'Europe')}
          />
          <Chip
            label="NAFTA"
            variant={chipFilled.NAFTA ? 'filled' : 'outlined'}
            onClick={() => onSelectGroupofCountries(chipFilled.NAFTA, 'NAFTA')}
          />
          <Chip
            label="Asia"
            variant={chipFilled.Asia ? 'filled' : 'outlined'}
            onClick={() => onSelectGroupofCountries(chipFilled.Asia, 'Asia')}
          />
          <Chip
            label="Africa"
            variant={chipFilled.Africa ? 'filled' : 'outlined'}
            onClick={() => onSelectGroupofCountries(chipFilled.Africa, 'Africa')}
          />
          <Chip
            label="RoW"
            variant={chipFilled.RoW ? 'filled' : 'outlined'}
            onClick={() => onSelectGroupofCountries(chipFilled.RoW, 'RoW')}
          />
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};

export default CountryPicker;
