import { useQuery, TypedDocumentNode, gql } from '@apollo/client';
import { Organization } from '../types';
import { gqlOrganization } from './shared';

interface OrganizationData {
  organization: Organization;
}

interface OrganizationVars {
  uuid?: string;
}

export const ORGANIZATION: TypedDocumentNode<OrganizationData, OrganizationVars> = gql`
query Organization($uuid: String!) {
    organization(uuid: $uuid) {
    ${gqlOrganization}
        labelStatusRel {
            deliveryTypes
            categories
            timestamp
            user
            reviewed
            done
        }
    }
}
`;

const useOrganization = (vars: OrganizationVars) => {
  const {
    data: { organization } = { totalCount: 0, items: [] },
    loading,
    refetch,
  } = useQuery(ORGANIZATION, { notifyOnNetworkStatusChange: true, variables: vars });

  return { organization, loading, refetch };
};

export type { OrganizationData, OrganizationVars };
export default useOrganization;
