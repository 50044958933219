import axios from 'axios';
import { API_URL } from './config';
import { headerConfig } from './Firebase';
import {
  CompanyAndRelations,
  StartupDiscoveryFundingResponse,
  TrendDetails,
  Trends,
} from './pages/StartupRadar/ifaces';
import { Organization } from './pages/StartupRadar/types';
import { SURUser, SURUserRole } from './pages/AdminPage/types';

export const getCompanies = async (): Promise<CompanyAndRelations> => {
  const res = await axios.get(`${API_URL}/companies`, await headerConfig());
  return res.data as CompanyAndRelations;
};
export const getTrends = async (): Promise<Trends> => {
  const res = await axios.get(`${API_URL}/trends`, await headerConfig());
  return res.data as Trends;
};
export const getTopicDetail = async (id: string): Promise<TrendDetails> => {
  const res = await axios.get(`${API_URL}/trends/${id}`, await headerConfig());
  return res.data as TrendDetails;
};

export const getStartups = async (): Promise<StartupDiscoveryFundingResponse> => {
  const res = await axios.get(`${API_URL}/discovery-funding`, await headerConfig());
  return res.data as StartupDiscoveryFundingResponse;
};

export type UpdateOrganization = {
  pinned?: boolean;
  reviewed?: boolean;
};

export const updateOrganization = async (org_uuid: string, data: UpdateOrganization): Promise<Organization> => {
  const res = await axios.patch(`${API_URL}/organizations/${org_uuid}`, data, await headerConfig());
  return res.data;
};

export const getUsers = async (): Promise<Array<SURUser>> => {
  const res = await axios.get(`${API_URL}/users/`, await headerConfig());
  return res.data as SURUser[];
};

export const getUser = async (uid: string): Promise<SURUser> => {
  const res = await axios.get(`${API_URL}/users/${uid}/`, await headerConfig());
  return res.data as SURUser;
};

export const assignUserRoles = async (uid: string, roles: SURUserRole[]): Promise<void> => {
  await axios.put(`${API_URL}/users/assign-roles`, { uid: uid, roles: roles }, await headerConfig());
};

// Classification

export const toogleClassificationPause = async (): Promise<boolean> => {
  const res = await axios.put(`${API_URL}/classification/toogle-pause`, {}, await headerConfig());
  return res.data.pause as boolean;
};

export const getClassificationPauseState = async (): Promise<boolean> => {
  const res = await axios.get(`${API_URL}/classification/pause-state`, await headerConfig());
  return res.data.pause as boolean;
};
