import * as React from 'react';
import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

// Material UI
import { Stack } from '@mui/material';

// Custom imports
import StartupRadarHeader from '../../../components/NavigationHeader/StartupRadarHeader';
import OrganizationsLabelledList from '../components/OrganizationsLabelledList';
import StatisticsGraph from '../components/StatisticsGraph';

import { GET_LABEL_STATISTICS } from '../Models';

const SettingsPage = () => {
  const months = useMemo(() => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const today = new Date();
    let d;
    let month;

    const options = [];

    for (let i = 12; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = monthNames[d.getMonth()];
      options.push(`${month} ${d.getFullYear()}`);
    }
    return options;
  }, []);

  // Use state values
  const [selectedMonth, setSelectedMonth] = useState(`${months.length - 1}`);
  const [fromDate, setFromDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCW, setSelectedCW] = useState<string>('');

  //  REFETCH LABEL STATISTICS
  const { data: { labellingStatistics = [] } = { labellingStatistics: [] }, refetch: refetchLabelStatistics } =
    useQuery(GET_LABEL_STATISTICS, {
      variables: { fromDate: fromDate, toDate: endDate },
    });
  React.useEffect(() => {
    setFromDate(moment(new Date(months[Number(selectedMonth)])).format('YYYY-MM-DD'));
    setEndDate(
      moment(new Date(months[Number(selectedMonth)]))
        .add(1, 'M')
        .format('YYYY-MM-DD'),
    );
    refetchLabelStatistics({
      fromDate: fromDate,
      toDate: endDate,
    });
  }, [endDate, fromDate, months, refetchLabelStatistics, selectedMonth]);

  const selectedCWNumber = useMemo(() => Number(selectedCW.replace('CW', '').split(' ')[0]), [selectedCW]);

  return (
    <Stack px={1}>
      <StartupRadarHeader />
      <Stack direction="row">
        <Stack flex={3} sx={{ overflow: 'scroll' }}>
          <OrganizationsLabelledList
            months={months}
            selectedMonth={months[Number(selectedMonth)]}
            selectedYear={moment(fromDate).format('YYYY')}
            selectedCW={selectedCWNumber}
          />
        </Stack>
        <Stack flex={4}>
          <StatisticsGraph
            data={labellingStatistics}
            months={months}
            selectedMonth={selectedMonth}
            onMonthSelect={setSelectedMonth}
            onCWSelect={setSelectedCW}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SettingsPage;
