import {
  Stack,
  Chip,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
  FormControl,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';

type Props = {
  title: string;
  onUpdateYearRangePicker: (value: Array<number>) => void;
  setReset?: boolean;
  onReset: () => void;
};

const currentYear = moment().year();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AnnouncedOnYearRangePicker = ({ title, onUpdateYearRangePicker, setReset, onReset }: Props) => {
  // Use State variables to keep track of the selected chips
  const [chipFilled, setChipFilled] = React.useState({
    currentYear: false,
    last3Years: false,
    last5Years: false,
    last10Years: false,
    currentYearMinus1: false,
    currentYearMinus2: false,
    currentYearMinus3: false,
  });

  // Use State variables to keep track of the selected years
  const [selectedYears, setSelectedYears] = React.useState<{ from: number; to: number }>({ from: 0, to: 0 });

  // Filter Options
  const yearsOptions = Array.from({ length: 15 }, (_, index) => currentYear - index);
  const fromOptions = yearsOptions.filter(year => (!selectedYears?.to ? true : year <= selectedYears?.to));
  const toOptions = yearsOptions.filter(year => (!selectedYears?.from ? true : year >= selectedYears?.from));

  useEffect(() => {
    if (setReset) {
      setChipFilled({
        currentYear: false,
        last3Years: false,
        last10Years: false,
        last5Years: false,
        currentYearMinus1: false,
        currentYearMinus2: false,
        currentYearMinus3: false,
      });
      setSelectedYears({ from: 0, to: 0 });
      onReset();
    }
  }, [setReset, onReset]);

  const onSelectLastNYears = (numOfYears: number, selectedYear?: number) => {
    // create a copy of the current state object
    const updatedChipFilled = {
      currentYear: false,
      last3Years: false,
      last5Years: false,
      last10Years: false,
      currentYearMinus1: false,
      currentYearMinus2: false,
      currentYearMinus3: false,
    };

    // set the filled state of the selected chip
    if (selectedYear) {
      updatedChipFilled.currentYear = selectedYear === currentYear;
      updatedChipFilled.currentYearMinus1 = selectedYear === currentYear - 1;
      updatedChipFilled.currentYearMinus2 = selectedYear === currentYear - 2;
      updatedChipFilled.currentYearMinus3 = selectedYear === currentYear - 3;
      handleOnUpdateYearRangePicker({ from: selectedYear, to: selectedYear });
    } else {
      updatedChipFilled.last3Years = numOfYears === 3;
      updatedChipFilled.last5Years = numOfYears === 5;
      updatedChipFilled.last10Years = numOfYears === 10;
      handleOnUpdateYearRangePicker({ from: currentYear - numOfYears, to: currentYear });
    }

    // update the state with the new filled state object
    setChipFilled(updatedChipFilled);
  };

  const handleOnUpdateYearRangePicker = (range: { from?: number; to?: number }) => {
    range.from === undefined && (range.from = selectedYears.from);
    range.to === undefined && (range.to = selectedYears.to);
    setSelectedYears({ from: range.from, to: range.to });
    const yearRangeArray: number[] = [];
    if (range.from && range.to) {
      for (let i = range.from; i <= range.to; i++) {
        yearRangeArray.push(i);
      }
    }
    onUpdateYearRangePicker?.(yearRangeArray);
  };

  return (
    <Stack sx={{ display: 'flex', padding: '7px' }}>
      <Typography sx={{ color: '#667085', fontSize: '11px', marginBottom: '10px' }}>{title}</Typography>
      <FormControl sx={{ display: 'flex', flex: 3, marginBottom: '25px' }}>
        <InputLabel id="investment-type-multiple-checkbox-label">From</InputLabel>
        <Select
          fullWidth
          value={selectedYears?.from || ''}
          input={<OutlinedInput label="From" />}
          onChange={event => {
            setChipFilled({
              currentYear: false,
              last3Years: false,
              last5Years: false,
              last10Years: false,
              currentYearMinus1: false,
              currentYearMinus2: false,
              currentYearMinus3: false,
            });
            handleOnUpdateYearRangePicker({ from: event.target.value as number });
          }}
          MenuProps={MenuProps}
          style={{ height: '50px' }}
        >
          {fromOptions.map(year => (
            <MenuItem key={year} value={year} sx={{ maxHeight: '25px', backgroundColor: '#EAECF0' }}>
              <ListItemText primary={year} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ display: 'flex', flex: 3, marginBottom: '15px' }}>
        <InputLabel id="investment-type-multiple-checkbox-label">To</InputLabel>
        <Select
          fullWidth
          value={selectedYears?.to || ''}
          input={<OutlinedInput label="To" />}
          onChange={event => {
            setChipFilled({
              currentYear: false,
              last3Years: false,
              last5Years: false,
              last10Years: false,
              currentYearMinus1: false,
              currentYearMinus2: false,
              currentYearMinus3: false,
            });
            handleOnUpdateYearRangePicker({ to: event.target.value as number });
          }}
          MenuProps={MenuProps}
          style={{ height: '50px' }}
        >
          {toOptions.map(year => (
            <MenuItem key={year} value={year} sx={{ maxHeight: '25px', backgroundColor: '#EAECF0' }}>
              <ListItemText primary={year} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction="row" spacing="2px" marginBottom="10px">
        <Stack direction="row" spacing="2px">
          <Chip
            label="Last 10 Years"
            variant={chipFilled.last10Years ? 'filled' : 'outlined'}
            onClick={() => onSelectLastNYears(10)}
          />
          <Chip
            label="Last 5 Years"
            variant={chipFilled.last5Years ? 'filled' : 'outlined'}
            onClick={() => onSelectLastNYears(5)}
          />
          <Chip
            label="Last 3 Years"
            variant={chipFilled.last3Years ? 'filled' : 'outlined'}
            onClick={() => onSelectLastNYears(3)}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing="2px">
        <Chip
          label={currentYear}
          variant={chipFilled.currentYear ? 'filled' : 'outlined'}
          onClick={() => onSelectLastNYears(1, currentYear)}
        />
        <Chip
          label={currentYear - 1}
          variant={chipFilled.currentYearMinus1 ? 'filled' : 'outlined'}
          onClick={() => onSelectLastNYears(1, currentYear - 1)}
        />
        <Chip
          label={currentYear - 2}
          variant={chipFilled.currentYearMinus2 ? 'filled' : 'outlined'}
          onClick={() => onSelectLastNYears(1, currentYear - 2)}
        />
        <Chip
          label={currentYear - 3}
          variant={chipFilled.currentYearMinus3 ? 'filled' : 'outlined'}
          onClick={() => onSelectLastNYears(1, currentYear - 3)}
        />
      </Stack>
    </Stack>
  );
};

export default AnnouncedOnYearRangePicker;
