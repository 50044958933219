import { useContext, useEffect, useState } from 'react';
import useFilterOptions from '../../graphql/useFilterOptions';
import { StartupRadarDataCtx } from '../../StartupRadarDataProvider';
import { formatString } from '../../Utils';

// Meant to be used with the useFilterOptions
// graphql hook for sorting purpose
type InvestmentTypeFilterFieldItem = {
  name: string;
  uuid: string;
  rank?: number;
};

export type Filters = {
  countries?: string[];
  fundingYears?: number[];
  fundingYearsRange?: { from?: number; to?: number };
  fundingYearsManual?: number[];
  investmentTypes?: string[];
  industries?: string[];
};

// const getYearsRangeArray = (start?: number, stop?: number) =>
//   !(start && stop) ? [] : Array.from({ length: stop - start + 1 }, (_, index) => start + index);

const useFilter = () => {
  const {
    selectedCountries,
    selectedFoundedOnYears,
    selectedAnnouncedOnYears,
    selectedInvestmentTypes,
    selectedIndustries,
    setSelectedCountries,
    setSelectedFoundedOnYears,
    setSelectedAnnouncedOnYears,
    setSelectedInvestmentTypes,
    setSelectedIndustries,
  } = useContext(StartupRadarDataCtx);

  // These are the filter options fetched using the useFilterOptions graphql hook
  const { filterOptions } = useFilterOptions();

  // Filters to apply once the apply button is clicked
  const [selectedCountriesToApply, setSelectedCountriesToApply] = useState<string[]>([]);
  const [selectedInvestmentTypesToApply, setSelectedInvestmentTypesToApply] = useState<string[]>([]);
  const [selectedIndustriesToApply, setSelectedIndustriesToApply] = useState<string[]>([]);
  const [selectedFoundedOnYearsToApply, setSelectedFoundedOnYearsToApply] = useState<number[]>([]);
  const [selectedAnnouncedOnYearsToApply, setSelectedAnnouncedOnYearsToApply] = useState<number[]>([]);

  // These useState hooks are used to store the sorted filter options
  // const [sortedFilterOptionCountries, setSortedFilterOptionCountries] = useState<{ code: string; name: string }[]>([]);
  const [sortedFilterOptionInvestmentTypes, setSortedFilterOptionInvestmentTypes] = useState<{
    level_0: InvestmentTypeFilterFieldItem[];
    level_1: InvestmentTypeFilterFieldItem[];
    level_2: InvestmentTypeFilterFieldItem[];
  }>({ level_0: [], level_1: [], level_2: [] });

  useEffect(() => {
    // Group the filter options investment types by levels
    const filterOptionInvestmentTypes = filterOptions.investmentTypes;

    const formattedFilterOptionInvestmentTypes = filterOptionInvestmentTypes.map(function (item) {
      const newItem = { ...item };
      if (newItem.hasOwnProperty('name')) {
        newItem.name = formatString(newItem.name);
      }
      return newItem;
    });

    const tempObject = {
      level_0: formattedFilterOptionInvestmentTypes.filter(item => item.rank >= 0 && item.rank < 300),
      level_1: formattedFilterOptionInvestmentTypes.filter(item => item.rank >= 300 && item.rank < 400),
      level_2: formattedFilterOptionInvestmentTypes.filter(item => item.rank >= 400),
    };

    // sort by rank
    tempObject.level_0.sort((a, b) => a.rank - b.rank);
    tempObject.level_1.sort((a, b) => a.rank - b.rank);
    tempObject.level_2.sort((a, b) => a.rank - b.rank);

    setSortedFilterOptionInvestmentTypes(tempObject);
  }, [filterOptions]);

  const applyFilters = () => {
    setSelectedCountries(selectedCountriesToApply);
    setSelectedInvestmentTypes(selectedInvestmentTypesToApply);
    setSelectedIndustries(selectedIndustriesToApply);
    setSelectedFoundedOnYears(selectedFoundedOnYearsToApply);
    setSelectedAnnouncedOnYears(selectedAnnouncedOnYearsToApply);
  };

  const resetFilters = () => {
    setSelectedCountries([]);
    setSelectedFoundedOnYears([]);
    setSelectedAnnouncedOnYears([]);
    setSelectedInvestmentTypes([]);
    setSelectedIndustries([]);
    setSelectedCountriesToApply([]);
    setSelectedInvestmentTypesToApply([]);
    setSelectedIndustriesToApply([]);
    setSelectedFoundedOnYearsToApply([]);
    setSelectedAnnouncedOnYearsToApply([]);
  };

  return {
    // these options come from the useFilterOptions graphql hook
    filterOptions: {
      // countries: sortedFilterOptionCountries,
      investmentTypes: sortedFilterOptionInvestmentTypes,
      industries: filterOptions.industries,
    },
    // These are the filters currently applied
    filters: {
      countries: selectedCountries,
      fundingYears: selectedFoundedOnYears,
      fundingYearsManual: selectedAnnouncedOnYears,
      investmentTypes: selectedInvestmentTypes,
      industries: selectedIndustries,
    },
    // Use these functions to change the filter values directly
    setSelectedCountries,
    // setSelectedFundingYearsRange: onSelectedFundingYearsRange,
    setSelectedFoundedOnYears,
    setSelectedAnnouncedOnYears,
    setSelectedInvestmentTypes,
    setSelectedIndustries,
    // Use these functions to change the filter values to be applied
    // without applying them directly
    setSelectedCountriesToApply,
    setSelectedInvestmentTypesToApply,
    setSelectedIndustriesToApply,
    setSelectedAnnouncedOnYearsToApply,
    setSelectedFoundedOnYearsToApply,
    // Use these method to apply the filters
    applyFilters,
    // Use this method to reset the filters
    resetFilters,
  };
};

export default useFilter;
