import React, { createContext, useState } from 'react';
import { Props } from './ifaces';

// --------------------------------------------------------

// Context to hold Notifications Data.

export interface TISNotification {
  id?: string;
  type: 'success' | 'warning' | 'error' | 'info';
  message: string;
}

interface TISUIProps {
  notifications: Array<TISNotification>;
  addNotification: (notification: TISNotification) => void;
  removeNotification: (id: string) => void;
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UIContext = createContext({} as TISUIProps);

export const UIProvider: React.FC<Props> = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<TISNotification>>([]);
  const [darkMode, setDarkMode] = useState(false);

  //  handlers for notifications
  const removeNotification = (id: string) => {
    // remove notification with the given id
    setNotifications([...notifications].filter(notification => notification.id !== id));
  };

  const addNotification = (notification: TISNotification) => {
    setNotifications([...notifications, { ...notification }]);
  };

  return (
    <UIContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        darkMode,
        setDarkMode,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
