import { useCallback, useContext, useEffect, useRef, useState } from 'react';
// Material UI
import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import MainLayout from '../MainLayout';
import { StartupRadarDataCtx } from '../StartupRadar/StartupRadarDataProvider';
import useOrganizations from '../StartupRadar/graphql/useOrganizations';
import { Organization } from '../StartupRadar/types';
import LoadingPage from '../LoadingPage';

const TableHeaderCellStyle = styled(TableCell)<TableCellProps>(() => ({
  backgroundColor: '#c3ced7',
}));

const StartupsPage = () => {
  const itemsPerPage = 10;
  const startUpsPageListRef = useRef<HTMLDivElement>(null);
  const { organizations, loading, refetch } = useOrganizations({ page: 1, limit: itemsPerPage });
  const { positioningLogicMap, deliverablesMap } = useContext(StartupRadarDataCtx);
  const [startupsPageFetchNextPage, setStartupsPageFetchNextPage] = useState({
    page: 1,
    limit: itemsPerPage,
  });

  const getOrganizationImage = useCallback((organization: Organization) => {
    if (
      !organization?.logoUrl ||
      organization.logoUrl.toLowerCase() === 'nan' ||
      organization?.logoUrl.endsWith('.ai')
    ) {
      return `https://via.placeholder.com/70x40/000000/fff?Text=${organization?.name}`;
    }
    return organization?.logoUrl;
  }, []);

  const refetchStartupsPage = useCallback(
    (page_number: number, _limit: number) => {
      refetch({
        page: page_number,
        limit: _limit,
      });
    },
    [refetch],
  );

  useEffect(() => {
    refetchStartupsPage(startupsPageFetchNextPage.page, startupsPageFetchNextPage.limit);
  }, [startupsPageFetchNextPage.page, startupsPageFetchNextPage.limit, refetchStartupsPage]);

  return loading ? (
    <LoadingPage />
  ) : (
    <MainLayout>
      <Stack sx={{ position: 'absolute', top: '60px', right: '0', left: '0', bottom: '15px' }}>
        <TableContainer
          component={Stack}
          ref={startUpsPageListRef}
          sx={{
            overflowY: 'auto',
            marginBottom: '20px',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,100,.1)',
              borderRadius: '10px',
            },
          }}
        >
          <Table aria-label="Startups" stickyHeader>
            <TableHead>
              <TableRow>
                <TableHeaderCellStyle></TableHeaderCellStyle>
                <TableHeaderCellStyle>Name</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Description</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Status</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Industries</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Last Funding Types</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Location</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Funding Amount</TableHeaderCellStyle>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations?.items.map((row, index) => (
                <TableRow key={`startup-row-${index}`}>
                  <TableCell>
                    <img
                      width={50}
                      height={50}
                      src={getOrganizationImage(organizations.items[index])}
                      alt={`${organizations.items[index].name}`}
                      loading="lazy"
                      style={{ objectFit: 'contain', background: 'white', borderRadius: '10%' }}
                    />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">
                    {row.taxonomy
                      .map(taxonomy => {
                        if (taxonomy.type === 'CATEGORY') {
                          if (positioningLogicMap[taxonomy.uuid]) {
                            return positioningLogicMap[taxonomy.uuid].name;
                          }
                        } else if (taxonomy.type === 'DELIVERY_TYPE') {
                          if (deliverablesMap[taxonomy.uuid]) {
                            return deliverablesMap[taxonomy.uuid].name;
                          }
                        }
                        return '-';
                      })
                      .join(', ')}
                  </TableCell>
                  <TableCell align="right">{row.numFundingRounds}</TableCell>
                  <TableCell align="right">{row.region}</TableCell>
                  <TableCell align="right">{row.totalFundingUsd}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'center',
            // marginTop: '100px',
            // marginBottom: '20px',
          }}
        >
          <Pagination
            count={Math.ceil((organizations?.totalCount || 0) / itemsPerPage)}
            defaultPage={startupsPageFetchNextPage.page}
            boundaryCount={2}
            // Max number of pages to show
            siblingCount={0}
            size="small"
            // On page number selection
            onChange={(event, value) => {
              setStartupsPageFetchNextPage({ ...startupsPageFetchNextPage, page: value });
              startUpsPageListRef.current?.scrollTo(0, 0);
            }}
            sx={{
              // Adjust font size of the pagination buttons
              '& .MuiPaginationItem-root': {
                fontSize: '0.6rem',
                minWidth: '20px',
                height: '20px',
              },
            }}
          />
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default StartupsPage;
