export enum SURUserRole {
  SUR_ADMIN = 'SUR_ADMIN',
  SUR_USER = 'SUR_USER',
  SUR_ANNOTATION_SPECIALIST = 'SUR_ANNOTATION_SPECIALIST',
}

export type SURUser = {
  uid: string;
  name: string;
  email: string;
  roles: SURUserRole[];
};
