import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import './index.css';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// FIREBASE
// Importing the firebase module will initialize the firebase app client
import './Firebase';

import theme from './theme';
import StartupRadarTheme from './pages/StartupRadar/theme';

// Context
import { AuthProvider } from './AuthProvider';
import { UIProvider } from './UIProvider';

// Apollo Context
import ApolliClient from './ApolloClient/client';

// IMPORT PAGES
import SecureLayout from './SecureLayout';
import SignInPage from './pages/SignInPage';
import ClassificationPage from './pages/ClassificationPage/ClassificationPage';
import MarketAnalysisPage from './pages/StartupRadar/MarketAnalysis/MarketAnalysisPage';
import NotFoundPage from './pages/NotFoundPage';
import StartupRadarHomePage from './pages/HomePage/StartupRadarHomePage';
import { StartupRadarDataProvider } from './pages/StartupRadar/StartupRadarDataProvider';
import SettingsPage from './pages/StartupRadar/Settings/SettingsPage';
import DevPage from './pages/DevPage';

import reportWebVitals from './reportWebVitals';
import StartupsPage from './pages/StartupsPage/StartupsPage';
import InvestorsPage from './pages/InvestorsPage/InvestorsPage';
import AdminPage from './pages/AdminPage/AdminPage';
import { SURUserRole } from './pages/AdminPage/types';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <UIProvider>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<SignInPage />} />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                       STARTUP RADAR ROUTES                     //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route path="/">
                <Route
                  path=""
                  element={
                    <ThemeProvider theme={StartupRadarTheme}>
                      <ApolloProvider client={ApolliClient}>
                        <SecureLayout title="" permissionRequired>
                          <StartupRadarHomePage />
                        </SecureLayout>
                      </ApolloProvider>
                    </ThemeProvider>
                  }
                />

                <Route path="/classification">
                  <Route
                    path=":org_uuid"
                    element={
                      <ThemeProvider theme={StartupRadarTheme}>
                        <ApolloProvider client={ApolliClient}>
                          <SecureLayout restrictToRoles={[SURUserRole.SUR_USER]} title="" permissionRequired>
                            <StartupRadarDataProvider>
                              <ClassificationPage />
                            </StartupRadarDataProvider>
                          </SecureLayout>
                        </ApolloProvider>
                      </ThemeProvider>
                    }
                  />
                  <Route
                    path=""
                    element={
                      <ThemeProvider theme={StartupRadarTheme}>
                        <ApolloProvider client={ApolliClient}>
                          <SecureLayout restrictToRoles={[SURUserRole.SUR_USER]} title="" permissionRequired>
                            <StartupRadarDataProvider>
                              <ClassificationPage />
                            </StartupRadarDataProvider>
                          </SecureLayout>
                        </ApolloProvider>
                      </ThemeProvider>
                    }
                  />
                </Route>

                <Route
                  path="/market"
                  element={
                    <ThemeProvider theme={StartupRadarTheme}>
                      <ApolloProvider client={ApolliClient}>
                        <SecureLayout
                          restrictToRoles={[SURUserRole.SUR_ANNOTATION_SPECIALIST]}
                          title=""
                          permissionRequired
                          overflow={false}
                        >
                          <StartupRadarDataProvider>
                            <MarketAnalysisPage />
                          </StartupRadarDataProvider>
                        </SecureLayout>
                      </ApolloProvider>
                    </ThemeProvider>
                  }
                />

                <Route
                  path="/startups"
                  element={
                    <ThemeProvider theme={StartupRadarTheme}>
                      <ApolloProvider client={ApolliClient}>
                        <SecureLayout
                          restrictToRoles={[SURUserRole.SUR_ANNOTATION_SPECIALIST]}
                          permissionRequired
                          overflow={false}
                        >
                          <StartupRadarDataProvider>
                            <StartupsPage />
                          </StartupRadarDataProvider>
                        </SecureLayout>
                      </ApolloProvider>
                    </ThemeProvider>
                  }
                />

                <Route
                  path="/investors"
                  element={
                    <ThemeProvider theme={StartupRadarTheme}>
                      <ApolloProvider client={ApolliClient}>
                        <SecureLayout
                          restrictToRoles={[SURUserRole.SUR_ANNOTATION_SPECIALIST]}
                          permissionRequired
                          overflow={false}
                        >
                          <StartupRadarDataProvider>
                            <InvestorsPage />
                          </StartupRadarDataProvider>
                        </SecureLayout>
                      </ApolloProvider>
                    </ThemeProvider>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <ThemeProvider theme={StartupRadarTheme}>
                      <ApolloProvider client={ApolliClient}>
                        <SecureLayout
                          restrictToRoles={[SURUserRole.SUR_ANNOTATION_SPECIALIST]}
                          title=""
                          permissionRequired
                        >
                          <StartupRadarDataProvider>
                            <SettingsPage />
                          </StartupRadarDataProvider>
                        </SecureLayout>
                      </ApolloProvider>
                    </ThemeProvider>
                  }
                />

                {/* //////////////////////////////////////////////////////////////////////// */}
                {/* ////     ADMIN PAGE: Only allow display if user has admin role      //// */}
                {/* //////////////////////////////////////////////////////////////////////// */}

                <Route
                  path="/admin"
                  element={
                    <ThemeProvider theme={StartupRadarTheme}>
                      <SecureLayout permissionRequired accessableByRoles={['SUR_ADMIN']}>
                        <AdminPage />
                      </SecureLayout>
                    </ThemeProvider>
                  }
                />

                {/* //////////////////////////////////////////////////////////////////////// */}
                {/* ////   DEV PAGE: Only enabled when REACT_APP_DEBUG is set to true   //// */}
                {/* //////////////////////////////////////////////////////////////////////// */}

                {process.env.REACT_APP_DEBUG === 'true' && (
                  <Route
                    path="/dev"
                    element={
                      <ThemeProvider theme={StartupRadarTheme}>
                        <ApolloProvider client={ApolliClient}>
                          <SecureLayout title="" permissionRequired>
                            <StartupRadarDataProvider>
                              <DevPage />
                            </StartupRadarDataProvider>
                          </SecureLayout>
                        </ApolloProvider>
                      </ThemeProvider>
                    }
                  />
                )}
              </Route>

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                          NOT FOUND PAGE                        //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </UIProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
