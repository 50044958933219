// Material UI
import { Stack, Typography, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import { TaxonomyMap, TaxonomyValues } from '../../../components/TaxonomyPicker/components/types';

const SelectectionChip = ({
  text,
  uuid,
  onDelete,
  isDeleteDisabled,
}: {
  text: string;
  uuid: string;
  onDelete: (uuid: string) => void;
  isDeleteDisabled: boolean;
}) => (
  <Chip
    variant="outlined"
    size="small"
    id={uuid}
    sx={{
      border: '1px solid #2196F3',
      wordBreak: 'break-word',
      '& .MuiChip-label': {
        color: '#2196F3',
        fontSize: '11.5px',
        fontWeight: '700',
      },
      '& .MuiChip-deleteIcon': {
        color: '#2196F3',
      },
      mx: 1,
      my: 1,
    }}
    label={text}
    deleteIcon={<DeleteIcon />}
    onDelete={
      !isDeleteDisabled
        ? () => {
            onDelete(uuid);
          }
        : undefined
    }
  />
);

interface OrganizationLabelsProps {
  positioningLogicValues: TaxonomyValues;
  deliverablesValues: TaxonomyValues;
  positioningLogicMap: TaxonomyMap;
  deliverablesMap: TaxonomyMap;
  isDeleteDisabled?: boolean;
  onChange?: ({ type, uuid }: { type: 'positioningLogic' | 'deliverables'; uuid: string }) => void;
}
const OrganizationLabels = ({
  positioningLogicValues = [],
  deliverablesValues = [],
  positioningLogicMap,
  deliverablesMap,
  isDeleteDisabled = false,
  onChange,
}: OrganizationLabelsProps) => {
  // const sortedPositioningLogicValues = useMemo(() => {

  //   const unsortedValues: {[level: string]: TaxonomyValues} = positioningLogicValues.reduce( (acc, uuid) => {
  //     acc[positioningLogicMap[uuid]?.level] = [...acc[positioningLogicMap[uuid]?.level], uuid]
  //     return acc
  //   }, {} as {[level: string]: TaxonomyValues} )

  //   const sortedValues = Object.keys(unsortedValues).reduce( (acc, level) )

  //   const unsortedValues = positioningLogicValues.map(uuid => ({ uuid, level: positioningLogicMap[uuid]?.level }));
  //   return unsortedValues.sort((a, b) => a?.level - b?.level);
  // }, [positioningLogicMap, positioningLogicValues]);
  // const sortedDeliverablesValues = useMemo(() => {
  //   const unsortedValues = deliverablesValues.map(uuid => ({ uuid, level: deliverablesMap[uuid].level }));
  //   return unsortedValues.sort((a, b) => a.level - b.level);
  // }, [deliverablesMap, deliverablesValues]);

  const handleOnDelete = (uuid: string, type: 'positioningLogic' | 'deliverables') => {
    if (onChange) {
      onChange({ type: type, uuid });
    }
  };

  return (
    <Stack flex={1}>
      {!!positioningLogicValues.length && (
        <Stack>
          <Typography sx={{ color: '#667085', fontSize: '15px', fontWeight: '700' }}>Taxonomy | Labels</Typography>
        </Stack>
      )}
      <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
        {positioningLogicValues.map(uuid => (
          <SelectectionChip
            key={`selection-chip-${uuid}`}
            uuid={uuid}
            text={positioningLogicMap[uuid]?.name}
            onDelete={chip_uuid => handleOnDelete(chip_uuid, 'positioningLogic')}
            isDeleteDisabled={isDeleteDisabled}
          />
        ))}
      </Stack>
      {!!deliverablesValues.length && (
        <Stack>
          <Typography sx={{ color: '#667085', fontSize: '15px', fontWeight: '700' }}>Deliverables</Typography>
        </Stack>
      )}
      <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
        {deliverablesValues.map(uuid => (
          <SelectectionChip
            key={`selection-chip-${uuid}`}
            uuid={uuid}
            text={deliverablesMap[uuid]?.name}
            onDelete={chip_uuid => handleOnDelete(chip_uuid, 'deliverables')}
            isDeleteDisabled={isDeleteDisabled}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default OrganizationLabels;
