import { gql, TypedDocumentNode } from '@apollo/client';
import { Organization, OrganizationLabels } from './types';
import { gqlOrganization } from './graphql/shared';

const LabelStatus = `
  deliveryTypes
  categories
  timestamp
  user
  reviewed
  done
`;

export const GET_LABEL_STATISTICS = gql`
  query GetLabellingStatistics($fromDate: String!, $toDate: String!) {
    labellingStatistics(fromDate: $fromDate, toDate: $toDate)
  }
`;

interface GetLabelledOrganizationsData {
  labelledOrganizations: {
    items: Array<OrganizationLabels & { organization: Organization }>;
    totalCount: number;
    count: number;
  };
}

interface GetLabelledOrganizationsVars {
  fromDate?: string;
  toDate?: string;
  offset?: number;
  limit?: number;
}
export const GET_LABELLED_ORGANIZATIONS: TypedDocumentNode<
  GetLabelledOrganizationsData,
  GetLabelledOrganizationsVars
> = gql`
  query GetLabelledOrganizations($fromDate: String!, $toDate: String!, $offset: Int!, $limit: Int!) {
    labelledOrganizations(fromDate: $fromDate, toDate: $toDate, offset: $offset, limit: $limit) {
      totalCount
      count
      items {
        ${LabelStatus}
        organization {
          ${gqlOrganization}
        }
      }
    }
  }
`;

export const GET_POSITIONING_LOGIC = gql`
  query GetPositioningLogic {
    positioningLogic {
      uuid
      name
      rank
      description
      subLevel {
        uuid
        name
        rank
        description
        subLevel {
          uuid
          name
          rank
          description
          subLevel {
            uuid
            name
            rank
            description
          }
        }
      }
    }
  }
`;

export const GET_DELIVERABLES = gql`
  query GetDeliverables {
    deliverables {
      uuid
      name
      rank
      subLevel {
        uuid
        name
        rank
      }
    }
  }
`;

export const GET_INVESTMENT_COUNT = gql`
  query GetInvestmentCount {
    fundingRounds {
      investmentType
      announcedOn
      organization {
        totalFundingUsd
        numFundingRounds
      }
      raisedAmountUsd
    }
  }
`;

export const SAVE_LABELS = gql`
  mutation SaveLabels($orgUuid: String!, $categories: [String!], $deliveryTypes: [String!]) {
    saveLabels(orgUuid: $orgUuid, categories: $categories, deliveryTypes: $deliveryTypes)
  }
`;

// export interface GetAmountOfInvestmentsByIndustryData {
//   totalInvestmentByTaxonomy: {
//     uuid: string;
//     year: number;
//     size: number;
//     count: number;
//     name: string;
//   };
// }

// interface GetAmountOfInvestmentsByIndustryVars {
//   categories?: readonly string[];
//   countries?: readonly string[];
// }

// export const GET_TOTAL_AMOUNT_OF_INVESTMENTS_BY_INDUSTRY: TypedDocumentNode<
//   GetAmountOfInvestmentsByIndustryData,
//   GetAmountOfInvestmentsByIndustryVars
// > = gql`
//   query GetAmountOfInvestmentsByIndustry($categories: [String!], $countries: [String!]) {
//     totalInvestmentByTaxonomy(categories: $categories, countries: $countries) {
//       name
//       uuid
//       year
//       size
//       count
//     }
//   }
// `;
