import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import DoneSharpIcon from '@mui/icons-material/CheckCircleRounded';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Organization } from '../types';
import OrganizationListPaginator from './OrganizationsListPaginator';
import { StartupRadarDataCtx } from '../StartupRadarDataProvider';
import countries from '../assets/countries.json';
import { getCBCategories, getYearsBetween } from '../Utils';

// Hooks
import useOrganizations from '../graphql/useOrganizations';
import useOrganization from '../graphql/useOrganization';
import { AuthCtx } from '../../../AuthProvider';
import { SURUserRole } from '../../AdminPage/types';

const OrganizationsList: FC = () => {
  const { org_uuid = '' } = useParams();
  const navigate = useNavigate();

  const {
    selectedOrganization,
    setSelectedOrganization,
    filterOptionsCountries,
    displayPinnedCompanies,
    displayReviewedCompanies,
    setDisplayPinnedCompanies,
    setDisplayReviewedCompanies,
  } = useContext(StartupRadarDataCtx);

  const { roles } = useContext(AuthCtx);
  // QUERIES

  const { organizations, loading, refetch } = useOrganizations({
    page: 1,
    limit: 50,
    orderBy: `name`,
    pinned: undefined,
  });
  const { organization: getOrganization, refetch: refetchOrganization } = useOrganization({ uuid: org_uuid });

  useEffect(() => {
    if (org_uuid) {
      refetchOrganization({ uuid: org_uuid });
    }
  }, [org_uuid, refetchOrganization]);

  useEffect(() => {
    if (!getOrganization) return;
    setSelectedOrganization(getOrganization);
  }, [getOrganization, setSelectedOrganization]);

  const [filterLabelled, setFilterLabelled] = useState<string>('');
  const [filterCategories, setFilterCategories] = useState<Array<string>>([]);
  const [filterCountries, setFilterCountries] = useState<('uuid' | 'code' | 'name' | string)[]>([]);
  const [filterYear, setFilterYear] = useState<string>('');

  const organizationListRef = useRef<HTMLDivElement>(null);

  // Use State values
  const [organizationSearchText, setOrganizationSearchText] = useState('');
  const [organizationsSearchFilters, setOrganizationsSearchFilters] = useState({
    searchText: organizationSearchText,
    page: 1,
    limit: 50,
  });

  const countriesMap: { [key: string]: { name: string; eu: boolean } } = countries;
  const itemsPerPage = 50;

  const handleYearSelection = (value: SelectChangeEvent<typeof filterYear>) => {
    setFilterYear(value.target.value);
  };

  const handleLabelledSelection = (event: SelectChangeEvent<typeof filterYear>) => {
    setFilterLabelled(event.target.value);
  };

  const handleCategorySelection = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setFilterCategories([...(value as string[])]);
  };

  const handleCountryChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setFilterCountries([...(value as 'uuid'[])]);
  };

  const refetchOrganizations = useCallback(
    (page_number: number, _limit: number, _searchText: string, _pinned: boolean, _reviewed: boolean) => {
      refetch({
        page: page_number,
        limit: _limit,
        searchText: _searchText,
        pinned: _pinned ? _pinned : undefined,
        reviewed: _reviewed ? _reviewed : undefined,
        labelStatus: filterLabelled,
        countries: filterCountries,
        categories: filterCategories,
        foundingYear: Number(filterYear),
      });
    },
    [refetch, filterLabelled, filterCountries, filterCategories, filterYear],
  );

  useEffect(() => {
    // Set last 5 years
    let previousFifthYear = new Date().getFullYear() - 5;
    const yearList = [];
    for (let i = 1; i <= 6; i++) {
      yearList.push(previousFifthYear);
      previousFifthYear++;
    }
    refetchOrganizations(
      organizationsSearchFilters.page,
      organizationsSearchFilters.limit,
      organizationsSearchFilters.searchText,
      displayPinnedCompanies,
      displayReviewedCompanies,
    );
  }, [
    organizationsSearchFilters.page,
    organizationsSearchFilters.limit,
    organizationsSearchFilters.searchText,
    displayPinnedCompanies,
    displayReviewedCompanies,
    refetchOrganizations,
  ]);

  //  NAVIGATE TO SELECTED ORG ID
  useEffect(() => {
    if (!selectedOrganization && !org_uuid && organizations && organizations.items.length) {
      navigate(`/classification/${organizations.items[0].uuid}`);
    }
  }, [navigate, org_uuid, organizations, refetchOrganization, selectedOrganization, setSelectedOrganization]);

  const getOrganizationImage = useCallback((organization: Organization) => {
    if (
      !organization?.logoUrl ||
      organization.logoUrl.toLowerCase() === 'nan' ||
      organization?.logoUrl.endsWith('.ai')
    ) {
      return `https://via.placeholder.com/70x40/000000/fff?Text=${organization?.name}`;
    }
    return organization?.logoUrl;
  }, []);

  const handleSearchClick = useCallback(() => {
    setOrganizationsSearchFilters({
      ...organizationsSearchFilters,
      searchText: organizationSearchText,
    });
  }, [organizationsSearchFilters, organizationSearchText]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearchClick();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [organizationSearchText, handleSearchClick]);

  const handleOrganizationClick = (organization: Organization) => {
    setSelectedOrganization(organization);
    navigate(`/classification/${organization.uuid}`);
  };
  const handlePinnedSwitchChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayPinnedCompanies(event.target.checked);
  };
  const handleReviewedSwitchChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayReviewedCompanies(event.target.checked);
  };

  return (
    <Stack sx={{ flex: 1 }}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="start"
          control={
            <Switch
              checked={displayPinnedCompanies}
              onChange={handlePinnedSwitchChangeEvent}
              inputProps={{ 'aria-label': 'controlled' }}
              size="small"
            />
          }
          // Check if SURUserRole.SUR_ANNOTATION_SPECIALIST is contained in roles
          disabled={roles.includes(SURUserRole.SUR_ANNOTATION_SPECIALIST) && !roles.includes(SURUserRole.SUR_ADMIN)}
          label="Pinned"
          labelPlacement="start"
        />
      </FormGroup>

      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="start"
          control={
            <Switch
              checked={displayReviewedCompanies}
              onChange={handleReviewedSwitchChangeEvent}
              inputProps={{ 'aria-label': 'controlled' }}
              size="small"
            />
          }
          label="Reviewed"
          labelPlacement="start"
        />
      </FormGroup>

      <OutlinedInput
        id="outlined-basic"
        fullWidth
        placeholder="Search"
        onChange={searchString => {
          setOrganizationSearchText(searchString.target.value);
        }}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            handleSearchClick();
          }
        }}
        value={organizationSearchText}
        sx={{ height: '50px' }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end" onClick={handleSearchClick}>
              <SearchOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
      />

      <FormControl
        fullWidth
        variant="standard"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#E4E8EA',
          color: '#667085',
          fontSize: '15px',
          fontWeight: '700',
          height: '50px',
        }}
      >
        <InputLabel
          shrink={false}
          focused={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
            textTransform: 'capitalize',
          }}
          id="companies-filter"
        >
          Companies ({filterLabelled ? `${filterLabelled.replaceAll('_', ' ').toLowerCase()}` : 'All'})
        </InputLabel>
        <Select
          disableUnderline
          fullWidth
          labelId="companies-filter"
          id="demo-simple-select-standard"
          renderValue={selected => ''}
          onChange={handleLabelledSelection}
          value={filterLabelled}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
          }}
        >
          <MenuItem value={''}>All</MenuItem>
          <MenuItem value={'LABELLED'}>Labelled</MenuItem>
          <MenuItem value={'NOT_LABELLED'}>Not Labelled</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        variant="standard"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#E4E8EA',
          color: '#667085',
          fontSize: '15px',
          fontWeight: '700',
          height: '50px',
        }}
      >
        <InputLabel
          shrink={false}
          focused={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
          }}
          id="companies-filter"
        >
          Categories ({`${filterCategories.length} selected`})
        </InputLabel>
        <Select
          disableUnderline
          fullWidth
          value={filterCategories}
          renderValue={selected => ''}
          multiple
          labelId="region-select"
          onChange={handleCategorySelection}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
          }}
        >
          {getCBCategories().map(category => (
            <MenuItem key={`select-menu-country-${category}`} value={category}>
              <Checkbox checked={filterCategories.includes(category)} />
              <ListItemText primary={category} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        variant="standard"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#E4E8EA',
          color: '#667085',
          fontSize: '15px',
          fontWeight: '700',
          height: '50px',
        }}
      >
        <InputLabel
          shrink={false}
          focused={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
          }}
          id="companies-filter"
        >
          Countries ({`${filterCountries.length} selected`})
        </InputLabel>
        <Select
          disableUnderline
          fullWidth
          value={filterCountries}
          renderValue={selected => ''}
          multiple
          labelId="region-select"
          onChange={handleCountryChange}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
          }}
        >
          {((filterOptionsCountries ?? []) as Array<{ uuid: string; name: string; code: string }>).map(
            availableValue => (
              <MenuItem key={`select-menu-country-${availableValue['code']}`} value={availableValue['code'] as 'code'}>
                <Checkbox checked={filterCountries.includes(availableValue['code'] as 'code')} />
                <ListItemText primary={countriesMap[availableValue?.code]?.name ?? availableValue?.code} />
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        variant="standard"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#E4E8EA',
          color: '#667085',
          fontSize: '15px',
          fontWeight: '700',
          height: '50px',
        }}
      >
        <InputLabel
          shrink={false}
          focused={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
          }}
          id="years-filter"
        >
          Year ({filterYear ? `${filterYear}` : 'All'})
        </InputLabel>
        <Select
          disableUnderline
          fullWidth
          labelId="years-filter"
          onChange={handleYearSelection}
          value={filterYear}
          renderValue={selected => ''}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#667085',
            fontSize: '15px',
            fontWeight: '700',
            pl: 3,
          }}
        >
          <MenuItem value="">All</MenuItem>

          {getYearsBetween().map(year => (
            <MenuItem key={`year-menu-${year}`} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////               ORGANIZATIONS SCROLLABLE CONTAINER               //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <Stack sx={{ flex: 1, position: 'relative' }}>
        {loading ? (
          <Stack>
            <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 1 }} />
          </Stack>
        ) : (
          <Stack
            sx={{
              flex: 1,
              overflowY: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              // Only show the scrollbar when the user is scrolling
              '&::-webkit-scrollbar': {
                width: '0.4em',
              },
              '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,100,.1)',
                borderRadius: '10px',
              },
            }}
            ref={organizationListRef}
          >
            {!organizations?.items.length && (
              <Paper
                square
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  px: '36px',
                  py: 3,
                  width: '100%',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                No Organizations Found
              </Paper>
            )}
            {organizations?.items.map((organization, index) => {
              return (
                <Paper
                  key={index}
                  onClick={() => {
                    handleOrganizationClick(organization);
                  }}
                  square
                  // variant="outlined"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 1,
                    px: 2,
                    width: '100%',
                    cursor: 'pointer',
                    background: organization.uuid === selectedOrganization?.uuid ? '#e8f4ff' : undefined,

                    // Add border to the bottom of the card
                    '&:not(:last-child)': {
                      borderBottom: '1px solid #F8F8F8',
                    },

                    // On hover change the background color
                    '&:hover': {
                      background: '#FAFAFA',
                    },
                  }}
                >
                  <Stack ml={-1} mr={1} sx={{ width: '18px' }}>
                    {organization?.labelStatusRel?.done && (
                      <DoneSharpIcon color="success" fontSize="small" sx={{ height: 15 }} />
                    )}
                  </Stack>

                  <img
                    width={50}
                    height={50}
                    src={getOrganizationImage(organization)}
                    alt={`${organization.name}`}
                    loading="lazy"
                    // Make sure the image is not stretched and has rounded corners
                    style={{ objectFit: 'contain', background: 'white', borderRadius: '10%' }}
                  />
                  <Stack sx={{ ml: 2 }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.8rem',
                      }}
                    >
                      {organization.name}
                    </Typography>
                    <Stack direction="row">
                      <Link
                        href={organization.cbUrl}
                        target="_"
                        color="inherit"
                        sx={{
                          fontSize: 12,
                          mr: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          // Only display underline on hover
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        <LaunchSharpIcon sx={{ fontSize: 12, mr: '5px' }} />
                        <Typography sx={{ fontSize: '0.7rem' }}>Visit Company Link</Typography>
                      </Link>
                    </Stack>
                  </Stack>
                  <Divider />
                </Paper>
              );
            })}
          </Stack>
        )}
      </Stack>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                            PAGINATOR                           //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <OrganizationListPaginator
        onPageNumberSelection={(page: number) => {
          setOrganizationsSearchFilters({ ...organizationsSearchFilters, page: page });
          organizationListRef.current?.scrollTo(0, 0);
        }}
        currentPage={organizationsSearchFilters.page}
        totalCount={organizations?.totalCount || 0}
        totalNumberOfPages={Math.ceil((organizations?.totalCount || 0) / itemsPerPage)}
      />
    </Stack>
  );
};

export default OrganizationsList;
