import { useQuery, gql, TypedDocumentNode } from '@apollo/client';

type TotalRaisedAmountByInvestmentTypeRawData = readonly {
  year: number;
  orgUuid: string;
  orgName: string;
  orgCountryCode: string;
  orgCity: string;
  orgCbUrl: string;
  count: number;
  investmentType: string;
  amount: number;
}[];

type TotalRaisedAmountByInvestmentTypeVars = {
  countries?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  investmentTypes?: readonly string[];
  years?: readonly number[];
};

export const TOTAL_RAISED_AMOUNT_BY_INVESTMENT_TYPE: TypedDocumentNode<
  TotalRaisedAmountByInvestmentTypeRawData,
  TotalRaisedAmountByInvestmentTypeVars
> = gql`
  query TotalRaisedAmountByInvestmentType(
    $countries: [String!]
    $categories: [String!]
    $deliveryTypes: [String!]
    $investmentTypes: [String!]
    $years: [Int!]
  ) {
    totalRaisedAmountByInvestmentType(
      years: $years
      investmentTypes: $investmentTypes
      deliveryTypes: $deliveryTypes
      countries: $countries
      categories: $categories
    ) {
      year
      orgUuid
      orgName
      orgCountryCode
      orgCity
      orgCbUrl
      count
      investmentType
      amount
    }
  }
`;

const useTotalRaisedAmountByInvestmentType = (filters: TotalRaisedAmountByInvestmentTypeVars) => {
  const {
    data: { totalRaisedAmountByInvestmentType } = { totalRaisedAmountByInvestmentType: [] },
    loading,
    refetch,
  } = useQuery<{
    totalRaisedAmountByInvestmentType: TotalRaisedAmountByInvestmentTypeRawData;
  }>(TOTAL_RAISED_AMOUNT_BY_INVESTMENT_TYPE, { variables: filters, notifyOnNetworkStatusChange: true });
  return { totalRaisedAmountByInvestmentType, loading, refetch };
};

export type { TotalRaisedAmountByInvestmentTypeRawData, TotalRaisedAmountByInvestmentTypeVars };
export default useTotalRaisedAmountByInvestmentType;
