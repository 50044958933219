import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ChevronRight';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import { DataLayer } from './types';
import { buildTaxonomyRankStr } from './utils';
import { StartupRadarDataCtx } from '../../../pages/StartupRadar/StartupRadarDataProvider';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem', p: 10 }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
  minHeight: 'auto',
  paddingTop: '3px',
  paddingBottom: '3px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid #E5E5E5',
  fontSize: 12,
}));

interface ITaxonomyLevelProps {
  uuid: string;
  rank: number;
  title: string;
  children: React.ReactNode;
  level: number;
  description: string;
  totalChildren: number;
  hideTotalChildren?: boolean;
  isChecked: (uuid: string) => boolean;
  onChange: (uuid: string, isChecked: boolean, level: number) => void;
  defaultExpanded: boolean;
}
const TaxonomyLevel = ({
  uuid,
  title,
  children,
  rank,
  level,
  description,
  totalChildren,
  hideTotalChildren,
  onChange,
  isChecked,
  defaultExpanded,
}: ITaxonomyLevelProps) => {
  const { positioningLogicMap } = useContext(StartupRadarDataCtx);
  const [isExpanded, setExpanded] = useState(false);

  const isCheckable = useMemo(() => {
    return level > 0 || totalChildren === 0 || true;
  }, [level, totalChildren]);

  const extensiveTitle = useMemo(() => {
    if (level === 0) {
      const rankTitle = `L${buildTaxonomyRankStr(uuid, positioningLogicMap)}: ${title}`;
      if (hideTotalChildren) {
        return rankTitle;
      } else {
        return `${rankTitle} (${totalChildren})`;
      }
    } else {
      return `${buildTaxonomyRankStr(uuid, positioningLogicMap)}: ${title}`;
    }
  }, [level, uuid, title, totalChildren, hideTotalChildren, positioningLogicMap]);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Accordion
      disableGutters
      square
      elevation={0}
      expanded={isExpanded}
      sx={{
        background: '#FEFEFE',
        border: '1px solid #E0E0E0',
        borderLeft: level > 0 ? 'none' : undefined,
        borderRight: level > 0 ? 'none' : undefined,
        // Edit Accordion Root Style
        '.MuiButtonBase-root': {
          paddingRight: '3px',
        },
      }}
    >
      <AccordionSummary
        sx={{ pl: level }}
        expandIcon={
          totalChildren > 0 && <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={() => setExpanded(!isExpanded)} />
        }
      >
        {isCheckable && (
          <Checkbox
            color="default"
            size="small"
            sx={{ p: 0 }}
            icon={<CheckBoxOutlineBlankSharpIcon />}
            checkedIcon={<CheckBoxSharpIcon />}
            checked={isChecked(uuid) || false}
            onChange={(_, checked) => onChange(uuid, checked, level)}
            disableRipple
          />
        )}
        <Button
          onClick={() => onChange(uuid, !isChecked(uuid), level)}
          variant="text"
          color="inherit"
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'none',
            '&:hover': {
              background: 'none',
            },
          }}
          disableElevation
        >
          {extensiveTitle}
        </Button>
        {description && (
          <Tooltip enterDelay={1000} title={description}>
            <IconButton
              sx={{
                opacity: 0.3,
                padding: 0,
              }}
            >
              <InfoIcon
                sx={{
                  fontSize: '1rem',
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </AccordionSummary>

      {totalChildren > 0 && <AccordionDetails sx={{ px: 0 }}>{children}</AccordionDetails>}
    </Accordion>
  );
};

const RecursiveLayers = ({
  node,
  level,
  isChecked,
  onChange,
  isHierarchySelected,
  hideTotalChildren,
}: {
  node: DataLayer;
  level: number;
  isChecked: (uuid: string) => boolean;
  isHierarchySelected: (uuid: string) => boolean;
  onChange: (uuid: string, isChecked: boolean, level: number) => void;
  hideTotalChildren?: boolean;
}) => {
  const { positioningLogicMap } = useContext(StartupRadarDataCtx);
  const compare = (a: DataLayer, b: DataLayer) => {
    if (a.rank < b.rank) {
      return -1;
    }
    if (a.rank > b.rank) {
      return 1;
    }
    return 0;
  };

  const sortedChildren = [...(node?.subLevel ?? [])].sort(compare);
  return (
    <TaxonomyLevel
      uuid={node.uuid}
      isChecked={isChecked}
      onChange={onChange}
      title={node.name}
      rank={node.rank}
      description={node.description}
      level={level}
      totalChildren={node?.subLevel?.length ?? 0}
      defaultExpanded={isHierarchySelected(node.uuid)}
      hideTotalChildren={hideTotalChildren}
    >
      <FormControl component="fieldset" sx={{ width: '100%' }}>
        {sortedChildren.map(child =>
          !(child?.subLevel ?? []).length ? (
            <FormGroup
              key={`form-group-${child.uuid}`}
              aria-label="position"
              row
              sx={{
                width: '100%',
                flex: 1,
                ml: '-1px',
              }}
            >
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    color="default"
                    size="small"
                    icon={<CheckBoxOutlineBlankSharpIcon />}
                    checkedIcon={<CheckBoxSharpIcon />}
                    checked={isChecked(child.uuid) || false}
                    disableRipple
                    onChange={(_, checked) => onChange(child.uuid, checked, level)}
                  />
                }
                label={
                  <Stack direction={'row'} spacing={'4px'}>
                    {/* Create enclosing Box to avoid automatic flex behaviour of Typography Component */}
                    <Box>
                      <Typography>{buildTaxonomyRankStr(child.uuid, positioningLogicMap)}:</Typography>
                    </Box>
                    {/* Create enclosing Box to avoid automatic flex behaviour of Typography Component */}
                    <Box>
                      <Typography>{child.name}</Typography>
                    </Box>
                    {child.description && (
                      <Tooltip enterDelay={500} title={child.description}>
                        <IconButton
                          sx={{
                            opacity: 0.3,
                            padding: 0,
                          }}
                        >
                          <InfoIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                }
                labelPlacement="end"
                sx={{
                  flex: 1,
                  ml: level + 1,
                  '& .MuiTypography-root': { fontSize: '12px', flex: 2 },
                  '& .MuiButtonBase-root': { mr: 1 },
                }}
              />
            </FormGroup>
          ) : (
            <RecursiveLayers
              key={`form-group-${child.uuid}`}
              level={level + 1}
              node={child}
              isChecked={isChecked}
              onChange={onChange}
              isHierarchySelected={isHierarchySelected}
            />
          ),
        )}
      </FormControl>
    </TaxonomyLevel>
  );
};

export default RecursiveLayers;
