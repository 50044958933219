import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
// Material UI
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Container, FormControl, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
// Third Party
import { useMutation } from '@apollo/client';

// Custom
import { MEDIA_URL } from '../../config';
import StartupRadarHeader from '../../components/NavigationHeader/StartupRadarHeader';
import { UIContext } from '../../UIProvider';
import FooterToolbar from '../StartupRadar/components/FooterToolbar';
import OrganizationDetails from '../StartupRadar/components/OrganizationDetails';
import OrganizationsList from '../StartupRadar/components/OrganizationsList';
import { SAVE_LABELS } from '../StartupRadar/Models';
import { StartupRadarDataCtx } from '../StartupRadar/StartupRadarDataProvider';
import CategoryPicker from '../../components/TaxonomyPicker/CategoryPicker';
import { DataLayer, TaxonomyValues } from '../../components/TaxonomyPicker/components/types';
import { headerConfig } from '../../Firebase';

const ClassificationPage: FC = () => {
  const {
    selectedOrganization,
    positioningLogic,
    positioningLogicMap,
    deliverables,
    deliverablesMap,
    permissions,
    setEnableSaveButton,
  } = useContext(StartupRadarDataCtx);
  const { addNotification, removeNotification } = useContext(UIContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExcelDownload = async () => {
    const header_config = await headerConfig();
    addNotification({ id: 'downloading_file', message: 'Downloading Excel file...', type: 'info' });
    fetch(MEDIA_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/vnd.ms-excel',
        Authorization: header_config.headers.Authorization,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `organization_categories.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .finally(() => {
        removeNotification('downloading_file');
        handleClose();
      });
  };

  const [saveLabels, { loading: isSaving }] = useMutation(SAVE_LABELS);

  const [organizationPositioningLogic, setOrganizationPositioningLogic] = useState<{
    [orgUuid: string]: TaxonomyValues;
  }>();
  const [organizationDeliverables, setOrganizationDeliverables] = useState<{
    [orgUuid: string]: TaxonomyValues;
  }>();

  const [oldOrganizationPositioningLogic, setOldOrganizationPositioningLogic] = useState<{
    [orgUuid: string]: TaxonomyValues;
  }>();

  const [oldOrganizationDeliverables, setOldOrganizationDeliverables] = useState<{
    [orgUuid: string]: TaxonomyValues;
  }>();

  const [sortedSelectedPositioningLogic, setSortedSelectedPositioningLogic] = useState<Array<string>>(['']);
  const [sortedSelectedDeliverables, setSortedSelectedDeliverables] = useState<Array<string>>(['']);

  const subLevelWithRanks = useCallback(
    (subLevelArray: Array<DataLayer>, selectedCategory: string): Array<{ uuid: string; rank: number }> => {
      let result: { uuid: string; rank: number }[] = [];
      for (let i = 0; i < subLevelArray?.length; i++) {
        const { uuid, rank, subLevel } = subLevelArray[i];
        if (uuid === selectedCategory) {
          result.push({ uuid, rank });
        }
        if (subLevel?.length) {
          result = [...result, ...subLevelWithRanks(subLevel, selectedCategory)];
        }
      }
      return result;
    },
    [],
  );

  useEffect(() => {
    // sort the selected categories based on the rank
    const selectedPositioningLogic = organizationPositioningLogic?.[selectedOrganization?.uuid ?? ''];
    if (selectedPositioningLogic) {
      const tempObjectSelectedPositioningLogic = [];
      for (let i = 0; i < selectedPositioningLogic?.length; i++) {
        for (let j = 0; j < positioningLogic?.length; j++) {
          if (selectedPositioningLogic[i] === positioningLogic[j].uuid) {
            tempObjectSelectedPositioningLogic.push({
              uuid: positioningLogic[j].uuid,
              rank: positioningLogic[j].rank,
            });
          }
          const subLevelArray = positioningLogic[j]?.subLevel;
          if (subLevelArray?.length) {
            const allSublevels = subLevelWithRanks(subLevelArray, selectedPositioningLogic[i]);
            tempObjectSelectedPositioningLogic.push(...allSublevels);
          }
        }
      }
      setSortedSelectedPositioningLogic(
        [...new Set(tempObjectSelectedPositioningLogic)].sort((a, b) => a.rank - b.rank).map(item => item.uuid),
      );
    }

    const selectedDeliverables = organizationDeliverables?.[selectedOrganization?.uuid ?? ''];
    if (selectedDeliverables) {
      const tempObjselectedDeliverables = [];
      for (let i = 0; i < selectedDeliverables?.length; i++) {
        for (let j = 0; j < deliverables?.length; j++) {
          if (selectedDeliverables[i] === deliverables[j].uuid) {
            tempObjselectedDeliverables.push({
              uuid: deliverables[j].uuid,
              rank: deliverables[j].rank,
            });
          }
          const subLevelArray = deliverables[j]?.subLevel;
          if (subLevelArray?.length) {
            const allSublevels = subLevelWithRanks(subLevelArray, selectedDeliverables[i]);
            tempObjselectedDeliverables.push(...allSublevels);
          }
        }
      }

      setSortedSelectedDeliverables(
        [...new Set(tempObjselectedDeliverables)].sort((a, b) => a.rank - b.rank).map(item => item.uuid),
      );
    }
    if (selectedOrganization && !organizationPositioningLogic?.[selectedOrganization.uuid ?? '']) {
      const orgPositioningLogic = selectedOrganization.taxonomy
        .filter(taxonomy => taxonomy.type === 'CATEGORY')
        .map(taxonomy => taxonomy.uuid);
      setOrganizationPositioningLogic({
        ...organizationPositioningLogic,
        [selectedOrganization.uuid]: orgPositioningLogic,
      });
      setOldOrganizationPositioningLogic({
        ...organizationPositioningLogic,
        [selectedOrganization.uuid]: orgPositioningLogic,
      });
    }
    if (!organizationPositioningLogic?.[selectedOrganization?.uuid ?? ''] && selectedOrganization) {
      const orgDeliverables = selectedOrganization.taxonomy
        .filter(taxonomy => taxonomy.type === 'DELIVERY_TYPE')
        .map(taxonomy => taxonomy.uuid);
      setOrganizationDeliverables({ ...organizationDeliverables, [selectedOrganization.uuid]: orgDeliverables });
      setOldOrganizationDeliverables({ ...organizationDeliverables, [selectedOrganization.uuid]: orgDeliverables });
    }
  }, [
    organizationDeliverables,
    organizationPositioningLogic,
    selectedOrganization,
    deliverables,
    positioningLogic,
    subLevelWithRanks,
  ]);

  function areEqual(array1: string[] | undefined, array2: string | string[]) {
    if (!array1) return false;
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }

        return false;
      });
    }

    return false;
  }

  const handleTaxonomy = (type: 'positioningLogic' | 'deliverables', values: TaxonomyValues) => {
    if (!selectedOrganization) {
      alert('Please select a company first');

      return;
    }

    if (type === 'positioningLogic') {
      setOrganizationPositioningLogic({
        ...(organizationPositioningLogic ?? {}),
        [selectedOrganization.uuid]: values,
      });

      areEqual(oldOrganizationPositioningLogic?.[selectedOrganization.uuid], values)
        ? setEnableSaveButton(false)
        : setEnableSaveButton(true);
    } else {
      setOrganizationDeliverables({
        ...(organizationDeliverables ?? {}),
        [selectedOrganization.uuid]: values,
      });
      areEqual(oldOrganizationDeliverables?.[selectedOrganization.uuid], values)
        ? setEnableSaveButton(false)
        : setEnableSaveButton(true);
    }
  };

  const canSave = useMemo(() => {
    if (!selectedOrganization) return false;
    return (organizationPositioningLogic?.[selectedOrganization.uuid] ?? []).length;
  }, [organizationPositioningLogic, selectedOrganization]);

  const handleSave = () => {
    const newCategories = organizationPositioningLogic?.[selectedOrganization?.uuid ?? ''] ?? [];
    const newDeliveryTypes = organizationDeliverables?.[selectedOrganization?.uuid ?? ''] ?? [];
    saveLabels({
      variables: {
        orgUuid: selectedOrganization?.uuid,
        categories: newCategories,
        deliveryTypes: newDeliveryTypes,
      },
    });
  };

  return (
    <Container maxWidth={false} sx={{ flex: 1, position: 'relative' }}>
      <StartupRadarHeader />
      <Stack
        sx={{
          position: 'absolute',
          top: '50px',
          right: '0',
          left: '0px',
          bottom: '0',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              minHeight: 'min-content',
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 3,
                overflow: 'hidden',
                maxWidth: '300px',
                background: '#E4E8EA',
                // Add a right light border
                borderRight: '1px solid #E4E8EA',
                borderRadius: '4px',
              }}
            >
              <Stack my={1} alignItems="flex-end">
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                  <Tooltip title="More Options">
                    <Button
                      size="small"
                      variant="text"
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      sx={{ color: '#667085', borderColor: 'transparent', p: 0, width: '20px', minWidth: 0, mr: 0 }}
                      onClick={handleClick}
                    >
                      <MoreVertIcon color="inherit" fontSize="small" />
                    </Button>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 8,
                        width: 8,
                        height: 8,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleExcelDownload}>
                    <FileDownloadOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">Download Excel</Typography>
                  </MenuItem>
                </Menu>
              </Stack>
              {/* TODO: Remove the false when we want to display the year filter */}
              {false && permissions?.['isAdmin'] && (
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#E4E8EA',
                    color: '#667085',
                    fontSize: '15px',
                    fontWeight: '700',
                    height: '50px',
                    marginBottom: '10px',
                  }}
                >
                  {/* {filterYear.length === 0 && (
                    <InputLabel
                      shrink={false}
                      focused={false}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#667085',
                        fontSize: '15px',
                        fontWeight: '700',
                        pl: 3,
                      }}
                      id="year-filter"
                    >
                      Year
                    </InputLabel>
                  )}

                  <Select
                    disableUnderline
                    fullWidth
                    labelId="companies-filter"
                    id="demo-simple-select-standard"
                    onChange={handleYearSelection}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#667085',
                      fontSize: '15px',
                      fontWeight: '700',
                      pl: 3,
                    }}
                  >
                    {yearList.map((value, index) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select> */}
                </FormControl>
              )}

              <OrganizationsList />
            </div>
            <div
              style={{
                paddingLeft: '30px',
                paddingRight: '30px',
                flex: 4,
                // overflow: 'scroll',
                paddingBottom: '150px',
                overflow: 'auto',
              }}
            >
              {selectedOrganization && (
                <OrganizationDetails
                  org_uuid={selectedOrganization.uuid}
                  positioningLogicValues={sortedSelectedPositioningLogic}
                  onPositioningLogicValuesChange={values => handleTaxonomy('positioningLogic', values)}
                  deliverablesValues={sortedSelectedDeliverables}
                  onDeliverablesValuesChange={values => handleTaxonomy('deliverables', values)}
                  positioningLogicMap={positioningLogicMap}
                  deliverablesMap={deliverablesMap}
                />
              )}
            </div>
            <Stack
              sx={{
                flex: 3,
                position: 'relative',
              }}
            >
              <Stack sx={{ flex: 1, position: 'relative' }}>
                <Stack
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflowY: 'auto',
                  }}
                >
                  <CategoryPicker
                    // enableClassification={true}
                    positionLogicValues={organizationPositioningLogic?.[selectedOrganization?.uuid ?? '']}
                    deliverablesValues={organizationDeliverables?.[selectedOrganization?.uuid ?? '']}
                    onPositionLogicValuesChange={values => handleTaxonomy('positioningLogic', values)}
                    onDeliverablesValuesChange={values => handleTaxonomy('deliverables', values)}
                    disableCascadeUnchecking
                    enableDeliverables={true}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ justifyContent: 'center' }}>
                <FooterToolbar saveDisabled={!canSave || isSaving} onSave={handleSave} />
              </Stack>
            </Stack>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

export default ClassificationPage;
