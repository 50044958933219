import { useContext, useMemo } from 'react';
import { Button, ButtonProps, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthCtx } from '../../AuthProvider';
import { SURUserRole } from '../../pages/AdminPage/types';
// import BeryllsLogo from '../../assets/berylls.png';

const ButtonStyling = styled(Button)<ButtonProps>(() => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '0.9rem',
  // lineHeight: '15px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

interface IStartupRadarProps {
  hideMenuItems?: boolean;
}

const StartupRadarHeader = ({ hideMenuItems = false }: IStartupRadarProps) => {
  const { roles } = useContext(AuthCtx);
  const navigate = useNavigate();
  const location = useLocation();

  const currentPage = useMemo(() => {
    const locationParts = location.pathname.split('/');
    let rootLocation = '';
    for (let i = 0; i < locationParts.length; i++) {
      if (locationParts[i]) {
        rootLocation = locationParts[i];
        break;
      }
    }
    return locationParts.length ? rootLocation : '';
  }, [location]);

  return (
    <Stack direction="row" height={'60px'} py={2} sx={{ position: 'relative' }}>
      {/* <Box
        sx={{
          position: 'absolute',
          left: '20px',
          top: '15px',
        }}
      >
        <img height={25} src={BeryllsLogo} alt="Berylls Logo" />
      </Box> */}
      {!hideMenuItems && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            // background: 'red',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <ButtonStyling
            variant="text"
            sx={{
              color: '#999999',
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            Home
          </ButtonStyling>

          {
            // Check either SUR_ADMIN or SUR_ANNOTATION_SPECIALIST is present in the user roles
            (roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 || roles.indexOf(SURUserRole.SUR_USER) !== -1) && (
              <>
                <ButtonStyling
                  variant="text"
                  sx={{
                    color: currentPage === 'market' ? '#000000' : '#999999',
                  }}
                  onClick={() => {
                    navigate('/market');
                  }}
                >
                  Market Activities
                </ButtonStyling>
                <ButtonStyling
                  variant="text"
                  sx={{
                    color: '#999999',
                  }}
                  onClick={() => {
                    navigate('/startups');
                  }}
                >
                  Startups
                </ButtonStyling>
                <ButtonStyling
                  variant="text"
                  sx={{
                    color: '#999999',
                  }}
                  onClick={() => {
                    navigate('/investors');
                  }}
                >
                  Investors
                </ButtonStyling>
              </>
            )
          }

          {(roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 ||
            roles.indexOf(SURUserRole.SUR_ANNOTATION_SPECIALIST) !== -1) && (
            <ButtonStyling
              variant="text"
              sx={{
                color: currentPage === 'classification' ? '#000000' : '#999999',
              }}
              onClick={() => {
                navigate('/classification');
              }}
            >
              Classification
            </ButtonStyling>
          )}

          {
            // Check either SUR_ADMIN or SUR_ANNOTATION_SPECIALIST is present in the user roles
            (roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 || roles.indexOf(SURUserRole.SUR_USER) !== -1) && (
              <ButtonStyling
                variant="text"
                sx={{
                  color: currentPage === 'settings' ? '#000000' : '#999999',
                }}
                onClick={() => {
                  navigate('/settings');
                }}
              >
                Settings
              </ButtonStyling>
            )
          }
        </Stack>
      )}
    </Stack>
  );
};

export default StartupRadarHeader;
