import { FC } from 'react';
import { SURUser } from '../types';
import UserRow from './UserRow';

interface IUsersList {
  users?: SURUser[];
}

const UsersList: FC<IUsersList> = ({ users }) => {
  return (
    <div>
      {users?.map((user, index) => (
        <UserRow key={`user-${index}`} user={user} />
      ))}
    </div>
  );
};
export default UsersList;
