import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { FC, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import useTotalRaisedAmountByInvestmentTypes from '../graphql/useTotalRaisedAmountByInvestmentTypes';
import { formatCash, formatString } from '../Utils';
import { colorMap } from './UIProvider';
import MarketAnalysisGridCardHeader from '../MarketAnalysis/MarketAnalysisGridCardHeader';
import SURGraphModal from './SURGraphModal';

ChartJS.register(ArcElement, Tooltip, Legend);
type ParsedInvestmentsData = {
  [year: string]: {
    [investment_type: string]: {
      amount: number;
      count: number;
      organization: {
        orgUuid: string;
        orgName: string;
        orgCbUrl: string;
        orgCity: string;
        orgCountryCode: string;
      }[];
    };
  };
};

interface TotalRaisedAmountByInvestmentTypeVars {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
}

interface RenderMarketTotalCountByInvTypeGraphProps {
  loading: boolean;
  investmentTypeLabels: string[];
  yearLabels: number[];
  displayLog: boolean;
  parsedInvestmentsData: ParsedInvestmentsData;
  displayLegend?: boolean;
}

const RenderMarketTotalCountByInvTypeGraph: FC<RenderMarketTotalCountByInvTypeGraphProps> = ({
  loading,
  investmentTypeLabels,
  yearLabels,
  displayLog = false,
  parsedInvestmentsData,
  displayLegend,
}) => {
  if (loading) {
    return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}> Loading...</Stack>;
  }

  return (
    <Chart
      type={'scatter'}
      options={{
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'left',
            formatter: function (context) {
              return context / 1000 + 'k';
            },
          },
          subtitle: {
            display: true,
          },
          legend: {
            display: displayLegend ? true : false,
            position: 'right',
          },
        },
        scales: {
          y: {
            ticks: {
              callback: (tickValue: string | number) => {
                return formatCash(tickValue as number);
              },
            },
            type: displayLog ? 'logarithmic' : 'linear',
          },
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 0,
            to: 0.3,
            loop: true,
          },
        },
      }}
      data={
        {
          datasets: [
            ...investmentTypeLabels.map(label => ({
              type: 'bar',
              label: formatString(label),
              data: yearLabels.map(year => parsedInvestmentsData?.[year]?.[label]?.['count']),
              borderColor: colorMap[label],
              backgroundColor: colorMap[label],
              animation: { duration: 1000, easing: 'linear' },
              hoverBackgroundColor: '#616E7A',
            })),
          ],
          labels: yearLabels,
        } as unknown as ChartData<'bar'>
      }
    />
  );
};

const MarketTotalCountByInvType: FC<TotalRaisedAmountByInvestmentTypeVars> = ({
  countries,
  investmentTypes,
  categories,
  years,
}) => {
  const vars = { countries, investmentTypes, categories, years };
  const { totalRaisedAmountByInvestmentType, loading } = useTotalRaisedAmountByInvestmentTypes(vars);

  const [displayLog, setDisplayLog] = useState<boolean>(false);
  // use state variable to track expanded state
  const [expanded, setExpanded] = useState<boolean>(false);

  const parsedInvestmentsData = useMemo(() => {
    const result = totalRaisedAmountByInvestmentType.reduce((_acc, curr) => {
      const result_array = { ..._acc };
      const { year, investmentType, amount, count, ...organization } = curr;
      if (!result_array[year]) {
        return {
          ...result_array,
          [year]: {
            [investmentType]: {
              amount,
              count,
              organization: [organization],
            },
          },
        };
      }
      if (!result_array[year][investmentType]) {
        result_array[year][investmentType] = {
          amount,
          count,
          organization: [organization],
        };
        return result_array;
      }
      result_array[year][investmentType]['amount'] += amount;
      result_array[year][investmentType]['count'] += count;
      result_array[year][investmentType]['organization'].push(organization);

      return result_array;
    }, {} as ParsedInvestmentsData);
    return result;
  }, [totalRaisedAmountByInvestmentType]);

  const yearLabels = useMemo(() => {
    const tempYears = totalRaisedAmountByInvestmentType.map(investment_type => investment_type.year);
    return [...new Set(tempYears)].sort((a, b) => a - b);
  }, [totalRaisedAmountByInvestmentType]);

  const investmentTypeLabels = useMemo(() => {
    const tempInvestmentTypes = totalRaisedAmountByInvestmentType.map(
      investment_type => investment_type.investmentType,
    );
    return [...new Set(tempInvestmentTypes)];
  }, [totalRaisedAmountByInvestmentType]);

  const handleExpandClose = () => {
    setExpanded(false);
  };

  return (
    <Stack>
      <MarketAnalysisGridCardHeader
        id="total-count-of-funding-rounds-by-investment-types"
        title={'Total count of funding rounds by investment type'}
        exportOptions={['csv', 'image']}
        enableLogarithmicScale={true}
        onLogarithmicScaleClick={id => {
          if (id === 'total-count-of-funding-rounds-by-investment-types') setDisplayLog(!displayLog);
        }}
        onClickExpandIcon={() => {
          setExpanded(true);
        }}
        displayLog={displayLog}
      >
        <RenderMarketTotalCountByInvTypeGraph
          investmentTypeLabels={investmentTypeLabels}
          yearLabels={yearLabels}
          displayLog={displayLog}
          parsedInvestmentsData={parsedInvestmentsData}
          loading={loading}
        />
      </MarketAnalysisGridCardHeader>
      {expanded && (
        <SURGraphModal
          id={'total-count-of-funding-rounds-by-investment-types'}
          open={expanded}
          onClose={handleExpandClose}
          loading={loading}
          title="Total count of funding rounds by investment type"
          enableLogButton={true}
          onLogarithmicScaleModelViewClick={id => {
            if (id === 'total-count-of-funding-rounds-by-investment-types') setDisplayLog(!displayLog);
          }}
          displayLog={displayLog}
        >
          <Stack sx={{ minHeight: '300px', height: '80vh', maxHeight: '600px' }}>
            <RenderMarketTotalCountByInvTypeGraph
              investmentTypeLabels={investmentTypeLabels}
              yearLabels={yearLabels}
              displayLog={displayLog}
              parsedInvestmentsData={parsedInvestmentsData}
              loading={loading}
              displayLegend={true}
            />
          </Stack>
        </SURGraphModal>
      )}
    </Stack>
  );
};

export default MarketTotalCountByInvType;
