import { colorMap } from '../../UIProvider';
import type { RawData } from '../../../graphql/useAmountRaisedByCompany';
import { investmentOrders } from '../../../Utils';

type TransformedData = {
  labels: string[];
  datasets: {
    label: string;
    data: (number | undefined)[];
    stack: 'breakdown';
    backgroundColor: string;
  }[];
  totals: number[];
};

const transformDataForDisplay = (data: RawData): TransformedData => {
  // Create a list containing all the unique investment types
  const listOfAllInvestmentTypes = data.reduce((accumulator, funding) => {
    funding.fundingRaised.forEach(f => {
      if (!accumulator.includes(f.investmentType)) {
        accumulator.push(f.investmentType);
      }
    });
    return accumulator;
  }, [] as string[]);

  const labels = data.map(funding => funding.organization.name);

  const datasets = (
    listOfAllInvestmentTypes.map((investmentType, index) => ({
      stack: 'breakdown' as const,
      label: investmentType,
      data: data.map(funding => {
        const fundingAmount = funding.fundingRaised.reduce(
          (accumulator, f) => accumulator + (f.investmentType === investmentType ? f.raisedAmountUsd : 0),
          0,
        );
        return fundingAmount;
      }),
      backgroundColor: colorMap[investmentType] ?? colorMap.default,
    })) || []
  ).sort((a, b) => {
    return investmentOrders.indexOf(a.label) - investmentOrders.indexOf(b.label);
  });

  const totals = data.map(funding => funding.amount);

  return { labels, datasets, totals };
};

export default transformDataForDisplay;
