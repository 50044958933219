import { useQuery, TypedDocumentNode, gql } from '@apollo/client';
import { Organization } from '../types';
import { gqlOrganization } from './shared';

interface OrganizationsData {
  organizations: {
    items: Organization[];
    totalCount: number;
    count: number;
  };
}

interface OrganizationsVars {
  searchText?: string;
  page?: number;
  offset?: number;
  limit?: number;
  labelStatus?: string;
  categories?: string[];
  countries?: string[];
  foundingYear?: number;
  pinned?: boolean;
  reviewed?: boolean;
  orderBy?: string;
}

export const ORGANIZATIONS: TypedDocumentNode<OrganizationsData, OrganizationsVars> = gql`
  query GetOrganizations(
    $searchText: String, 
    $offset: Int, 
    $page: Int, 
    $limit: Int, 
    $labelStatus: String, 
    $knowledgeManagement: Boolean, 
    $categories: [String!], 
    $countries: [String!], 
    $foundingYear: Int,
    $pinned: Boolean
    $reviewed: Boolean
    $orderBy: String,
    ) {
    organizations(searchText: $searchText, offset: $offset, page: $page, limit: $limit, labelStatus: $labelStatus, knowledgeManagement: $knowledgeManagement, categories: $categories, countries: $countries, foundingYear: $foundingYear, pinned: $pinned, reviewed: $reviewed, orderBy: $orderBy) {
      totalCount
      count
      items {
        ${gqlOrganization}
        labelStatusRel {
          deliveryTypes
          categories
          timestamp
          user
          reviewed
          done
        }
      }
    }
  }
`;

const useOrganizations = (vars: OrganizationsVars) => {
  const {
    data: { organizations } = { totalCount: 0, items: [] },
    loading,
    refetch,
  } = useQuery(ORGANIZATIONS, { variables: vars });

  return { organizations, loading, refetch };
};

export type { OrganizationsData, OrganizationsVars };
export default useOrganizations;
