import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import useTotalRaisedAmountByInvestmentTypes from '../graphql/useTotalRaisedAmountByInvestmentTypes';
import { colorMap } from './UIProvider';
import { formatCash, formatString } from '../Utils';
import MarketAnalysisGridCardHeader from '../MarketAnalysis/MarketAnalysisGridCardHeader';
import SURGraphModal from './SURGraphModal';

ChartJS.register(ArcElement, Tooltip, Legend);
type ParsedInvestmentsData = {
  [year: string]: {
    [investment_type: string]: {
      amount: number;
      count: number;
      organization: {
        orgUuid: string;
        orgName: string;
        orgCbUrl: string;
        orgCity: string;
        orgCountryCode: string;
      }[];
    };
  };
};

interface RenderMarketTotalAmountByInvTypeGraphProps {
  loading: boolean;
  investmentTypeLabels: string[];
  yearLabels: number[];
  displayLog: boolean;
  parsedInvestmentsData: ParsedInvestmentsData;
  displayLegend?: boolean;
}

const RenderMarketTotalAmountByInvTypeGraph: FC<RenderMarketTotalAmountByInvTypeGraphProps> = ({
  loading,
  investmentTypeLabels,
  yearLabels,
  displayLog,
  parsedInvestmentsData,
  displayLegend,
}) => {
  if (loading) {
    return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}> Loading...</Stack>;
  }

  return (
    <Chart
      type={'scatter'}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: displayLegend ? true : false,
            position: 'right',
          },
          subtitle: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context): string {
                const value = context.raw;
                const amount = formatCash(value as unknown as number);
                const label = context.dataset.label;
                // return the label with the formatted value
                return `${label}: ( ${'$'}${amount})`;
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback: (tickValue: string | number) => {
                return formatCash(tickValue as number);
              },
            },
            type: displayLog ? 'logarithmic' : 'linear',
          },
        },
        animations: {
          tension: {
            duration: 8000,
            easing: 'linear',
            from: 0,
            to: 0.3,
            loop: true,
          },
        },
      }}
      data={
        {
          datasets: [
            ...investmentTypeLabels.map(label => ({
              type: 'bar',
              data: yearLabels.map(year => parsedInvestmentsData?.[year]?.[label]?.['amount']),
              label: formatString(label),
              borderColor: colorMap[label],
              backgroundColor: colorMap[label],
              animation: { duration: 1000, easing: 'linear' },
              hoverBackgroundColor: '#616E7A',
            })),
          ],
          labels: yearLabels,
        } as unknown as ChartData<'bar'>
      }
    />
  );
};

interface MarketTotalAmountByInvTypeVars {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
  onLegendUpdate: (investmentType: string[], categories: string[]) => void;
}

const MarketTotalAmountByInvType: FC<MarketTotalAmountByInvTypeVars> = ({
  countries,
  investmentTypes,
  categories,
  years,
  onLegendUpdate,
}) => {
  const memoizedVars = useMemo(
    () => ({ countries, investmentTypes, categories, years }),
    [countries, investmentTypes, categories, years],
  );
  const [displayLog, setDisplayLog] = useState<boolean>(false);
  const { totalRaisedAmountByInvestmentType, loading } = useTotalRaisedAmountByInvestmentTypes(memoizedVars);

  // use state variable to track expanded state
  const [expanded, setExpanded] = useState<boolean>(false);

  const parsedInvestmentsData = useMemo(() => {
    const result = totalRaisedAmountByInvestmentType.reduce((_acc, curr) => {
      const result_array = { ..._acc };
      const { year, investmentType, amount, count, ...organization } = curr;
      if (!result_array[year]) {
        return {
          ...result_array,
          [year]: {
            [investmentType]: {
              amount,
              count,
              organization: [organization],
            },
          },
        };
      }
      if (!result_array[year][investmentType]) {
        result_array[year][investmentType] = {
          amount,
          count,
          organization: [organization],
        };
        return result_array;
      }
      result_array[year][investmentType]['amount'] += amount;
      result_array[year][investmentType]['count'] += count;
      result_array[year][investmentType]['organization'].push(organization);

      return result_array;
    }, {} as ParsedInvestmentsData);
    return result;
  }, [totalRaisedAmountByInvestmentType]);

  const yearLabels = useMemo(() => {
    const tempYears = totalRaisedAmountByInvestmentType.map(investment_type => investment_type.year);
    return [...new Set(tempYears)].sort((a, b) => a - b);
  }, [totalRaisedAmountByInvestmentType]);

  const investmentTypeLabels = useMemo(() => {
    const tempInvestmentTypes = totalRaisedAmountByInvestmentType.map(
      investment_type => investment_type.investmentType,
    );
    return [...new Set(tempInvestmentTypes)];
  }, [totalRaisedAmountByInvestmentType]);

  const onLegendUpdateCallback = useCallback(
    (investmentTypeLabel: string[], categoryLabels: string[]) => {
      onLegendUpdate(investmentTypeLabel, categoryLabels);
    },
    [onLegendUpdate],
  );

  useEffect(() => {
    // on legend update
    if (memoizedVars.categories?.length && memoizedVars.categories?.length >= 1) {
      // convert READONLY ARRAY to ARRAY
      const tempCategories = memoizedVars.categories as string[];
      onLegendUpdateCallback(investmentTypeLabels, tempCategories);
    } else {
      onLegendUpdateCallback(investmentTypeLabels, []);
    }
  }, [memoizedVars.categories, onLegendUpdateCallback, investmentTypeLabels]);

  const handleExpandClose = () => {
    setExpanded(false);
  };

  return (
    <Stack>
      <MarketAnalysisGridCardHeader
        id="total-amount-of-funding-rounds-by-investment-types"
        title={'Total raised amount of funding rounds by investment type'}
        exportOptions={['csv', 'image']}
        enableLogarithmicScale={true}
        onLogarithmicScaleClick={id => {
          if (id === 'total-amount-of-funding-rounds-by-investment-types') setDisplayLog(!displayLog);
        }}
        onClickExpandIcon={() => {
          setExpanded(true);
        }}
        displayLog={displayLog}
      >
        <RenderMarketTotalAmountByInvTypeGraph
          investmentTypeLabels={investmentTypeLabels}
          yearLabels={yearLabels}
          displayLog={displayLog}
          parsedInvestmentsData={parsedInvestmentsData}
          loading={loading}
        />
      </MarketAnalysisGridCardHeader>
      {expanded && (
        <SURGraphModal
          id={'Total raised amount of funding rounds by investment type'}
          open={expanded}
          onClose={handleExpandClose}
          loading={loading}
          title="Total raised amount of funding rounds by investment type"
          enableLogButton={true}
          onLogarithmicScaleModelViewClick={id => {
            if (id === 'Total raised amount of funding rounds by investment type') setDisplayLog(!displayLog);
          }}
          displayLog={displayLog}
        >
          <Stack sx={{ minHeight: '300px', height: '80vh', maxHeight: '600px' }}>
            <RenderMarketTotalAmountByInvTypeGraph
              investmentTypeLabels={investmentTypeLabels}
              yearLabels={yearLabels}
              displayLog={displayLog}
              parsedInvestmentsData={parsedInvestmentsData}
              loading={loading}
              displayLegend={true}
            />
          </Stack>
        </SURGraphModal>
      )}
    </Stack>
  );
};

export default MarketTotalAmountByInvType;
