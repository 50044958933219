import { useCallback, useEffect, useRef, useState } from 'react';
// Material UI
import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Stack,
  Link,
  Pagination,
  styled,
  TableCellProps,
} from '@mui/material';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
import useInvestors from '../StartupRadar/graphql/useInvestors';
import MainLayout from '../MainLayout';
import LoadingPage from '../LoadingPage';

const InvestorsPage = () => {
  const itemsPerPage = 20;
  const investorsPageListRef = useRef<HTMLDivElement>(null);
  const { investors, loading, refetch } = useInvestors({ limit: itemsPerPage, page: 1 });
  const [investorsPageFetchNextPage, setInvestorsPageFetchNextPage] = useState({
    page: 1,
    limit: itemsPerPage,
  });

  const refetchInvestorsPage = useCallback(
    (page_number: number, _limit: number) => {
      refetch({
        page: page_number,
        limit: _limit,
      });
    },
    [refetch],
  );

  const TableHeaderCellStyle = styled(TableCell)<TableCellProps>(() => ({
    backgroundColor: '#c3ced7',
  }));

  useEffect(() => {
    refetchInvestorsPage(investorsPageFetchNextPage.page, investorsPageFetchNextPage.limit);
  }, [investorsPageFetchNextPage.page, investorsPageFetchNextPage.limit, refetchInvestorsPage]);

  return loading ? (
    <LoadingPage />
  ) : (
    <MainLayout>
      <Stack sx={{ position: 'absolute', top: '60px', right: '0', left: '0', bottom: '15px' }}>
        <TableContainer
          component={Stack}
          ref={investorsPageListRef}
          sx={{
            overflowY: 'auto',
            marginBottom: '20px',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,100,.1)',
              borderRadius: '10px',
            },
          }}
        >
          <Table aria-label="Investors" stickyHeader>
            <TableHead sx={{ backgroundColor: '#c3ced7' }}>
              <TableRow>
                <TableHeaderCellStyle></TableHeaderCellStyle>
                <TableHeaderCellStyle>Name</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Description</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Status</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right"># Of investments</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right"># Of Exits</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Location</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">Avg. ticket Size.</TableHeaderCellStyle>
                <TableHeaderCellStyle align="right">URL</TableHeaderCellStyle>
              </TableRow>
            </TableHead>
            <TableBody>
              {investors?.items.map((row, index) => (
                <TableRow key={`investor-row-${index}`}>
                  <TableCell></TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">{row.noOfInvestments}</TableCell>
                  <TableCell align="right">{row.noOfExits}</TableCell>
                  <TableCell align="right">{row.city}</TableCell>
                  <TableCell align="right">{row.avgTicketSize}</TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
                      <Link
                        href={row.cbUrl}
                        target="_"
                        color="inherit"
                        sx={{ fontSize: 12, mr: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <LaunchSharpIcon sx={{ fontSize: 12, mr: 1 }} />
                        Link
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'center',
          }}
        >
          <Pagination
            count={Math.ceil((investors?.totalCount || 0) / itemsPerPage)}
            defaultPage={investorsPageFetchNextPage.page}
            boundaryCount={2}
            // Max number of pages to show
            siblingCount={0}
            size="small"
            // On page number selection
            onChange={(event, value) => {
              setInvestorsPageFetchNextPage({ ...investorsPageFetchNextPage, page: value });
              investorsPageListRef.current?.scrollTo(0, 0);
            }}
            sx={{
              // Adjust font size of the pagination buttons
              '& .MuiPaginationItem-root': {
                fontSize: '0.6rem',
                minWidth: '20px',
                height: '20px',
              },
            }}
          />
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default InvestorsPage;
