import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import RajdhaniMedium from './assets/fonts/Rajdhani-Medium.ttf';
import RajdhaniBold from './assets/fonts/Rajdhani-Bold.ttf';
import OpenSansRegular from './assets/fonts/OpenSans-Regular.ttf';

let theme = createTheme({
  typography: {
    fontFamily: ['Rajdhani', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Rajdhani';
          font-style: normal;
          font-weight: 500;
          src: local('Rajdhani'), local('Rajdhani-Medium'), url(${RajdhaniMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Rajdhani';
          font-style: normal;
          font-weight: 700;
          src: local('Rajdhani'), local('Rajdhani-Bold'), url(${RajdhaniBold}) format('truetype');
        }
        @font-face {
          font-family: 'OpenSans-Regular';
          font-style: normal;
          src: local('OpenSans-Regular'), local('OpenSans-Regular'), url(${OpenSansRegular}) format('truetype');
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
