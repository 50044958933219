import { Stack } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import transformDataForDisplay from './transformForDisplay';
import { industryColorMap } from '../UIProvider';
import useTotalInvestmentsByCategory from '../../graphql/useInvestmentsByTaxonomy';
import { StartupRadarDataCtx } from '../../StartupRadarDataProvider';
import MarketAnalysisGridCardHeader from '../../MarketAnalysis/MarketAnalysisGridCardHeader';
import SURGraphModal from '../SURGraphModal';
import { RenderMarketTotalAmountByIndustryGraph } from './RenderMarketTotalAmountByIndustryGraph';

interface chartDatapoints {
  x: number;
  y: number;
}

interface ChartData {
  backgroundColor: string;
  label: string;
  data: chartDatapoints[];
  labels: string[];
  showLine?: boolean;
  borderColor: string;
}

interface MarketTotalAmountByIndustryVars {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
  onLegendUpdate: (investmentType: string[], categories: string[]) => void;
}

const MarketTotalAmountByIndustry: FC<MarketTotalAmountByIndustryVars> = ({
  countries,
  investmentTypes,
  categories,
  years,
  onLegendUpdate,
}) => {
  const memoizedVars = useMemo(
    () => ({ countries, investmentTypes, categories, years }),
    [countries, investmentTypes, categories, years],
  );

  const { positioningLogicMap, deliverablesMap } = useContext(StartupRadarDataCtx);

  // QUERIES
  const { totalInvestmentByTaxonomy, loading } = useTotalInvestmentsByCategory(memoizedVars);
  const { labels, datasets } = transformDataForDisplay(totalInvestmentByTaxonomy);

  // use state variable to track expanded state
  const [expanded, setExpanded] = useState<boolean>(false);

  const [displayLog, setDisplayLog] = useState<boolean>(false);

  const newChartData: ChartData[] = [];

  const groupByCategory = totalInvestmentByTaxonomy.reduce(
    (group: { [key: string]: { year: number; size: number; count: number; color: string }[] }, obj) => {
      const { year, size, count, name, uuid } = obj;
      if (!group[name]) {
        group[name] = [];
      }
      group[name] = [...group[name], { year, size, count, color: industryColorMap[uuid] }];
      return group;
    },
    {},
  );

  for (const [categoryKey, categoryValue] of Object.entries(groupByCategory)) {
    newChartData.push({
      label: categoryKey,
      data: categoryValue
        .sort((a, b) => a.year - b.year)
        .map(element => {
          return { x: element.year, y: element.size };
        }),
      labels: categoryValue.map(element => element.year.toString()),
      showLine: true,
      backgroundColor: categoryValue[0].color,
      borderColor: categoryValue[0].color,
    });
  }

  const onLegendUpdateCallback = useCallback(
    (investmentTypeLabels: string[], categoryLabels: string[]) => {
      onLegendUpdate(investmentTypeLabels, categoryLabels);
    },
    [onLegendUpdate],
  );

  useEffect(() => {
    // on legend update
    if (memoizedVars.investmentTypes?.length && memoizedVars.investmentTypes?.length >= 1) {
      //  convert READONLY ARRAY to ARRAY
      const tempInvestmentTypes = memoizedVars.investmentTypes as string[];
      onLegendUpdateCallback(tempInvestmentTypes, Object.keys(groupByCategory));
    } else {
      onLegendUpdateCallback([], Object.keys(groupByCategory));
    }
  }, [groupByCategory, onLegendUpdateCallback, memoizedVars.investmentTypes]);

  const handleExpandClose = () => {
    setExpanded(false);
  };

  return (
    <Stack>
      <MarketAnalysisGridCardHeader
        id={'total-amount-of-investments-by-industry'}
        title={'Total Amount of Investments by Industry'}
        exportOptions={['csv', 'image']}
        enableLogarithmicScale={true}
        onLogarithmicScaleClick={id => {
          if (id === 'total-amount-of-investments-by-industry') setDisplayLog(!displayLog);
        }}
        onClickExpandIcon={() => {
          setExpanded(true);
        }}
        displayLog={displayLog}
      >
        <RenderMarketTotalAmountByIndustryGraph
          loading={loading}
          vars={memoizedVars}
          displayLog={displayLog}
          newChartData={newChartData}
          categories={categories}
          datasets={datasets}
          labels={labels}
          positioningLogicMap={positioningLogicMap}
          deliverablesMap={deliverablesMap}
          years={years}
        />
      </MarketAnalysisGridCardHeader>
      {expanded && (
        <SURGraphModal
          id={'total-amount-of-investments-by-industry'}
          open={expanded}
          onClose={handleExpandClose}
          loading={loading}
          title="Total Amount of Investments by Industry"
          enableLogButton={true}
          onLogarithmicScaleModelViewClick={id => {
            if (id === 'total-amount-of-investments-by-industry') setDisplayLog(!displayLog);
          }}
          displayLog={displayLog}
        >
          <Stack sx={{ minHeight: '300px', height: '80vh', maxHeight: '600px' }}>
            <RenderMarketTotalAmountByIndustryGraph
              loading={loading}
              vars={memoizedVars}
              displayLog={displayLog}
              newChartData={newChartData}
              categories={categories}
              datasets={datasets}
              labels={labels}
              positioningLogicMap={positioningLogicMap}
              deliverablesMap={deliverablesMap}
              years={years}
            />
          </Stack>
        </SURGraphModal>
      )}
    </Stack>
  );
};

export default MarketTotalAmountByIndustry;
