import { FC, useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { Bar } from 'react-chartjs-2';
import { Color } from 'chart.js';
import transformDataForDisplay from './transformDataForDisplay.ts/transformDataForDisplay';
import useAmountRaisedByCompany from '../../graphql/useAmountRaisedByCompany';
import { formatCash, formatString } from '../../Utils';
import MarketAnalysisGridCard from '../../MarketAnalysis/MarketAnalysisGridCardHeader';
import SURGraphModal from '../SURGraphModal';

interface AmountRaisedByCompanyVars {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
  onLegendUpdate: (investmentType: string[], categories: string[]) => void;
}

interface RenderAmountRaisedByCompanyGraphProps {
  loading: boolean;
  chartLabels: string[];
  chartDatasets: {
    label: string;
    data: (number | undefined)[];
    stack: 'breakdown';
    backgroundColor: string;
  }[];
  formattedLabelChartLabels: string[];
  displayLegend?: boolean;
}

const RenderAmountRaisedByCompanyGraph: FC<RenderAmountRaisedByCompanyGraphProps> = ({
  loading,
  chartLabels,
  chartDatasets,
  formattedLabelChartLabels,
  displayLegend = false,
}) => {
  if (loading) {
    return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}> Loading...</Stack>;
  }

  return (
    <Stack sx={{ flex: 1, position: 'relative' }}>
      <Stack sx={{ position: 'absolute', top: '10px', left: '10px', right: '10px', bottom: '10px' }}>
        <Bar
          data={{
            labels: chartLabels,
            datasets: chartDatasets.map(dataset => ({
              ...dataset,
              borderRadius: 4,
            })),
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: displayLegend ? true : false,
                position: 'right',
                labels: {
                  generateLabels(chart) {
                    if (chart.data.datasets !== undefined) {
                      console.log(chart.data.datasets);
                      return chart.data?.datasets.map((chart_label, index: number) => {
                        const text = chart?.data?.datasets?.[index].label as string;
                        const fillStyle = chart?.data?.datasets?.[index]?.backgroundColor as Color | undefined;
                        console.log(fillStyle);
                        return {
                          text: formatString(text),
                          strokeStyle: fillStyle,
                          fillStyle: fillStyle,
                          hidden: false,
                        };
                      });
                    } else {
                      return [];
                    }
                  },
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context): string {
                    const amount = formatCash(context.parsed.x);
                    const label = formatString(context.dataset.label);
                    // return the label with the formatted value
                    return `${label}: ${'$'}${amount}`;
                  },
                },
              },
            },
            indexAxis: 'y',
            scales: {
              y: {
                stacked: true,
                ticks: {
                  callback: (tickValue: string | number) => {
                    return formattedLabelChartLabels[tickValue as number];
                  },
                },
              },
              x: {
                stacked: true,
                ticks: {
                  callback: (tickValue: string | number) => {
                    return formatCash(tickValue as number);
                  },
                },
              },
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

const AmountRaisedByCompany: FC<AmountRaisedByCompanyVars> = ({
  countries,
  investmentTypes,
  categories,
  years,
  onLegendUpdate,
}) => {
  const vars = { countries, investmentTypes, categories, years };
  const { fundingRoundsByCompany, loading } = useAmountRaisedByCompany(vars);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [formattedLabelChartLabels, setFormattedLabelChartLabels] = useState<string[]>([]);
  const [chartDatasets, setChartDatasets] = useState<
    {
      label: string;
      data: (number | undefined)[];
      stack: 'breakdown';
      backgroundColor: string;
    }[]
  >([]);

  // use state variable to track expanded state
  const [expanded, setExpanded] = useState<boolean>(false);

  const formattedLabel = (label: string) => {
    const n_length = 10;
    if (label.length > n_length) {
      return label.slice(0, n_length) + '...';
    }
    return label;
  };

  const onLegendUpdateCallback = useCallback(
    (investmentTypeLabels: string[], categoryLabels: string[]) => {
      onLegendUpdate(investmentTypeLabels, categoryLabels);
    },
    [onLegendUpdate],
  );

  useEffect(() => {
    if (fundingRoundsByCompany) {
      const { labels, datasets } = transformDataForDisplay(fundingRoundsByCompany);
      setChartLabels(labels);
      setFormattedLabelChartLabels(labels.map(label => formattedLabel(label)));
      // replace the datasets label with the formatted label
      // datasets.forEach(dataset => {
      //   dataset.label = formatString(dataset.label);
      // });
      setChartDatasets(datasets);

      const investmentTypeLabels = datasets.map(dataset => dataset.label);
      if (vars.categories?.length && vars.categories?.length >= 1) {
        //  convert READONLY ARRAY to ARRAY
        const tempCategories = vars.categories as string[];
        onLegendUpdateCallback(investmentTypeLabels, tempCategories);
      } else {
        onLegendUpdateCallback(investmentTypeLabels, []);
      }
    }
  }, [fundingRoundsByCompany, onLegendUpdate, onLegendUpdateCallback, vars.categories]);

  const handleExpandClose = () => {
    setExpanded(false);
  };

  const graphData = {
    loading,
    chartLabels,
    chartDatasets,
    formattedLabelChartLabels,
  };

  return (
    <Stack>
      <MarketAnalysisGridCard
        id={'total-amount-raised-by-company'}
        title={'Total Amount Raised by Company'}
        exportOptions={['csv', 'image']}
        onClickExpandIcon={() => {
          setExpanded(true);
        }}
      >
        <RenderAmountRaisedByCompanyGraph {...graphData} />
      </MarketAnalysisGridCard>
      {expanded && (
        <SURGraphModal
          id={'total-amount-raised-by-company'}
          open={expanded}
          onClose={handleExpandClose}
          loading={loading}
          title="Total Amount Raised by Company"
        >
          <Stack sx={{ minHeight: '300px', height: '80vh', maxHeight: '600px' }}>
            <RenderAmountRaisedByCompanyGraph {...graphData} displayLegend={true} />
          </Stack>
        </SURGraphModal>
      )}
    </Stack>
  );
};

export default AmountRaisedByCompany;
