import React, { createContext } from 'react';
import { getAuth, ParsedToken, User } from 'firebase/auth';
import { headerConfig } from './Firebase';
import { Props } from './ifaces';

// --------------------------------------------------------
// Context to hold Authenticated user.

interface IAuthStateProps {
  user: User | null;
  customClaims: ParsedToken | null;
  loading: boolean;
  permissions: string[];
  roles: string[];
}

export const AuthCtx = createContext({} as IAuthStateProps);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(null);
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [roles, setRoles] = React.useState<string[]>([]);
  const [customClaims, setCustomClaims] = React.useState<ParsedToken | null>(null);
  const [loadingAuth, setLoadingAuth] = React.useState<boolean>(true);

  React.useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser && auth.currentUser) {
        const idTokenResult = await auth.currentUser.getIdTokenResult(true);
        const claims = idTokenResult.claims;
        setCustomClaims(claims);
        if (claims['permissions']) {
          const customClaimPermissions = claims['permissions'] as string | undefined;
          if (customClaimPermissions) {
            setPermissions(customClaimPermissions.split(','));
          }
        }
        if (claims['roles']) {
          const customClaimPermissions = claims['roles'] as string[] | undefined;
          if (customClaimPermissions) {
            setRoles(customClaimPermissions);
          }
        }

        const token = await headerConfig();
        console.log(token);
      }
      setUser(firebaseUser);
      setLoadingAuth(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthCtx.Provider
      value={{
        user: user,
        loading: loadingAuth,
        customClaims: customClaims,
        permissions: permissions,
        roles: roles,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};
