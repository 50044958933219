import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import RobotoLight from '../../assets/fonts/Roboto-Light.ttf';
import RobotoBold from '../../assets/fonts/Roboto-Bold.ttf';

let theme = createTheme({
  typography: {
    fontFamily: ['RobotoLight', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'RobotoLight';
          font-style: normal;
          font-weight: 500;
          src: local('Roboto'), local('Rajdhani-Medium'), url(${RobotoLight}) format('truetype');
        }
        @font-face {
          font-family: 'RobotoLight';
          font-style: normal;
          font-weight: 700;
          src: local('Roboto'), local('Rajdhani-Bold'), url(${RobotoBold}) format('truetype');
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
