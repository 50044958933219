import { useQuery, TypedDocumentNode, gql } from '@apollo/client';

type FilterOptionsCountry = {
  code: string;
  name: string;
};

type FilterOptionsInvestmentType = {
  name: string;
  uuid: string;
  rank: number;
};

interface FilterOptionsData {
  filterOptions: {
    countries: readonly FilterOptionsCountry[];
    industries: readonly {
      name: string;
      uuid: string;
    }[];
    investmentTypes: readonly FilterOptionsInvestmentType[];
  };
}

export const GET_FILTER_OPTIONS: TypedDocumentNode<FilterOptionsData> = gql`
  query GetFilterOptions {
    filterOptions {
      countries {
        code
        name
      }
      industries {
        name
        uuid
      }
      investmentTypes {
        name
        uuid
        rank
      }
    }
  }
`;

const useFilterOptions = () => {
  const {
    data: { filterOptions } = { filterOptions: { countries: [], industries: [], investmentTypes: [] } },
    loading,
    refetch,
  } = useQuery(GET_FILTER_OPTIONS);
  return { filterOptions, loading, refetch };
};

export type { FilterOptionsCountry, FilterOptionsInvestmentType, FilterOptionsData };
export default useFilterOptions;
