//  TAXONOMY

/**
 *
 * @param fromYear
 * @param toYear
 * @returns
 */
export const getYearsBetween = (fromYear = 1970, toYear = new Date().getFullYear()): number[] => {
  const years = [];
  for (let i = fromYear; i <= toYear; i++) {
    years.push(i);
  }
  return years.sort((a, b) => b - a);
};

export const getCBCategories = () => [
  'Taxi Service',
  'Electric Vehicle',
  'Fleet Management',
  'Auto Insurance',
  'Limousine Service',
  'Recreational Vehicles',
  'Car Sharing',
  'Parking',
  'Ride Sharing',
  'Autonomous Vehicles',
  'Last Mile Transportation',
  'Automotive',
  'Mapping Services',
  'Public Transportation',
  'Battery',
];
/**
 *
 * @param n
 * @returns
 */
export const formatCash = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

/**
 *
 * @returns
 */
export const dynamicColors = function () {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

/**
 *
 * @param str
 * @returns
 */
export const formatString = (str?: string) => {
  if (str) {
    const words = str.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const formattedString = capitalizedWords.join(' ');
    return formattedString;
  }
  return '';
};

/**
 * @returns []
 */
export const investmentOrders = [
  'angel',
  'pre_seed',
  'seed',
  'series_a',
  'series_b',
  'series_c',
  'series_d',
  'series_e',
  'series_f',
  'series_g',
  'series_h',
  'series_i',
  'series_j',
  'corporate_round',
  'post_ipo_debt',
  'post_ipo_equity',
  'private_equity',
  'debt_financing',
  'secondary_market',
  'convertible_note',
  'equity_crowdfunding',
  'grant',
  'initial_coin_offering',
  'non_equity_assistance',
  'product_crowdfunding',
  'series_unknown',
  'undisclosed',
];
