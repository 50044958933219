import countries from '../../../pages/StartupRadar/assets/countries.json';

export const dachCountryCodes = (() => {
  const dachCountries = Object.values(countries).filter(
    country => country.code === 'DEU' || country.code === 'AUT' || country.code === 'CHE',
  );
  return dachCountries.map(country => country.code);
})();

export const naftaCountryCodes = (() => {
  const naftaCountries = Object.values(countries).filter(
    country => country.code === 'USA' || country.code === 'CAN' || country.code === 'MEX',
  );
  return naftaCountries.map(country => country.code);
})();

export const africaCountryCodes = (() => {
  const africaCountries = Object.values(countries).filter(
    country =>
      country.code === 'DZA' || // Algeria
      country.code === 'AGO' || // Angola
      country.code === 'BEN' || // Benin
      country.code === 'BWA' || // Botswana
      country.code === 'BFA' || // Burkina Faso
      country.code === 'BDI' || // Burundi
      country.code === 'CPV' || // Cabo Verde
      country.code === 'CMR' || // Cameroon
      country.code === 'CAF' || // Central African Republic
      country.code === 'TCD' || // Chad
      country.code === 'COM' || // Comoros
      country.code === 'COG' || // Congo
      country.code === 'COD' || // Democratic Republic of the Congo
      country.code === 'DJI' || // Djibouti
      country.code === 'EGY' || // Egypt
      country.code === 'GNQ' || // Equatorial Guinea
      country.code === 'ERI' || // Eritrea
      country.code === 'SWZ' || // Eswatini (formerly Swaziland)
      country.code === 'ETH' || // Ethiopia
      country.code === 'GAB' || // Gabon
      country.code === 'GMB' || // Gambia
      country.code === 'GHA' || // Ghana
      country.code === 'GIN' || // Guinea
      country.code === 'GNB' || // Guinea-Bissau
      country.code === 'CIV' || // Ivory Coast (Côte d'Ivoire)
      country.code === 'KEN' || // Kenya
      country.code === 'LSO' || // Lesotho
      country.code === 'LBR' || // Liberia
      country.code === 'LBY' || // Libya
      country.code === 'MDG' || // Madagascar
      country.code === 'MWI' || // Malawi
      country.code === 'MLI' || // Mali
      country.code === 'MRT' || // Mauritania
      country.code === 'MUS' || // Mauritius
      country.code === 'MAR' || // Morocco
      country.code === 'MOZ' || // Mozambique
      country.code === 'NAM' || // Namibia
      country.code === 'NER' || // Niger
      country.code === 'NGA' || // Nigeria
      country.code === 'RWA' || // Rwanda
      country.code === 'STP' || // Sao Tome and Principe
      country.code === 'SEN' || // Senegal
      country.code === 'SYC' || // Seychelles
      country.code === 'SLE' || // Sierra Leone
      country.code === 'SOM' || // Somalia
      country.code === 'ZAF' || // South Africa
      country.code === 'SSD' || // South Sudan
      country.code === 'SDN' || // Sudan
      country.code === 'TZA' || // Tanzania
      country.code === 'TGO' || // Togo
      country.code === 'TUN' || // Tunisia
      country.code === 'UGA' || // Uganda
      country.code === 'ZMB' || // Zambia
      country.code === 'ZWE', // Zimbabwe
  );

  return africaCountries.map(country => country.code);
})();

export const asiaCountryCodes = (() => {
  const asiaCountries = Object.values(countries).filter(
    country =>
      country.code === 'AFG' || // Afghanistan
      country.code === 'ARM' || // Armenia
      country.code === 'AZE' || // Azerbaijan
      country.code === 'BHR' || // Bahrain
      country.code === 'BGD' || // Bangladesh
      country.code === 'BTN' || // Bhutan
      country.code === 'BRN' || // Brunei
      country.code === 'KHM' || // Cambodia
      country.code === 'CHN' || // China
      country.code === 'CYP' || // Cyprus
      country.code === 'GEO' || // Georgia
      country.code === 'HKG' || // Hong Kong
      country.code === 'IND' || // India
      country.code === 'IDN' || // Indonesia
      country.code === 'IRN' || // Iran
      country.code === 'IRQ' || // Iraq
      country.code === 'ISR' || // Israel
      country.code === 'JPN' || // Japan
      country.code === 'JOR' || // Jordan
      country.code === 'KAZ' || // Kazakhstan
      country.code === 'KWT' || // Kuwait
      country.code === 'KGZ' || // Kyrgyzstan
      country.code === 'LAO' || // Laos
      country.code === 'LBN' || // Lebanon
      country.code === 'MAC' || // Macao
      country.code === 'MYS' || // Malaysia
      country.code === 'MDV' || // Maldives
      country.code === 'MNG' || // Mongolia
      country.code === 'MMR' || // Myanmar (Burma)
      country.code === 'NPL' || // Nepal
      country.code === 'PRK' || // North Korea
      country.code === 'OMN' || // Oman
      country.code === 'PAK' || // Pakistan
      country.code === 'PSE' || // Palestine
      country.code === 'PHL' || // Philippines
      country.code === 'QAT' || // Qatar
      country.code === 'SAU' || // Saudi Arabia
      country.code === 'SGP' || // Singapore
      country.code === 'KOR' || // South Korea
      country.code === 'LKA' || // Sri Lanka
      country.code === 'SYR' || // Syria
      country.code === 'TWN' || // Taiwan
      country.code === 'TJK' || // Tajikistan
      country.code === 'THA' || // Thailand
      country.code === 'TLS' || // Timor-Leste
      country.code === 'TUR' || // Turkey
      country.code === 'TKM' || // Turkmenistan
      country.code === 'ARE' || // United Arab Emirates
      country.code === 'UZB' || // Uzbekistan
      country.code === 'VNM' || // Vietnam
      country.code === 'YEM', // Yemen
  );
  return asiaCountries.map(country => country.code);
})();

export const europeCountryCodes = (() => {
  // filter if eu is true, if true store it in array of strings and return it
  const europeCountries = Object.values(countries).filter(country => country.eu === true);
  return europeCountries.map(country => country.code);
})();

export const restofWorldCountryCodes = (() => {
  // store the remaining country codes which are not in the above arrays
  const dachNaftaAfricaAsiaEuropeCodes = [
    ...dachCountryCodes,
    ...naftaCountryCodes,
    ...africaCountryCodes,
    ...asiaCountryCodes,
    ...europeCountryCodes,
  ];
  const allCountryCodes = Object.values(countries).map(country => country.code);
  const restofWorldCountries = allCountryCodes.filter(code => !dachNaftaAfricaAsiaEuropeCodes.includes(code));
  return restofWorldCountries;
})();
