import { FC } from 'react';
// Material UI
import { Box, Stack, Typography } from '@mui/material';

interface LegendDisplayVars {
  sortedLegend: string[];
  legendColor: string[];
}

const LegendDisplay: FC<LegendDisplayVars> = ({ sortedLegend, legendColor }) => {
  return (
    <Stack
      direction="row"
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={'5px'}
    >
      {sortedLegend.map((item: string, index: number) => (
        <Stack direction="row" alignItems="center" key={index} spacing={'3px'}>
          <Box
            sx={{
              width: '20px',
              height: '10px',
              borderRadius: '2px',
              backgroundColor: legendColor[index],
            }}
          />
          <Typography
            variant="body2"
            sx={{
              textTransform: 'capitalize',
              fontSize: '0.7rem',
              letterSpacing: '-0.4px',
              marginRight: '10px',
            }}
          >
            {item.replace(/_/g, ' ')}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default LegendDisplay;
