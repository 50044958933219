// MUI
import { Stack, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';

interface OrganizationListPaginatorProps {
  onPageNumberSelection: (value: number) => void;
  currentPage?: number;
  totalNumberOfPages: number;
  totalCount?: number;
}

const OrganizationListPaginator = ({
  onPageNumberSelection,
  currentPage = 1,
  totalNumberOfPages,
  totalCount,
}: OrganizationListPaginatorProps) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: '0.8rem',
          minWidth: '20px',
        }}
      >
        (Total: {totalCount})
      </Typography>
      <Pagination
        count={totalNumberOfPages}
        defaultPage={currentPage}
        boundaryCount={2}
        // Max number of pages to show
        siblingCount={0}
        size="small"
        // On page number selection
        onChange={(event, value) => onPageNumberSelection(value)}
        sx={{
          // Adjust font size of the pagination buttons
          '& .MuiPaginationItem-root': {
            fontSize: '0.6rem',
            minWidth: '20px',
            height: '20px',
          },
        }}
      />
    </Stack>
  );
};

export default OrganizationListPaginator;
