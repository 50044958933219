import { FC } from 'react';
// Material UI
import { Box, Stack, Typography } from '@mui/material';
import { colorMap } from '../components/UIProvider';

interface LegendDisplayVars {
  sortedLegend: string[];
}

const LegendDisplay: FC<LegendDisplayVars> = ({ sortedLegend }) => {
  return (
    <Stack
      sx={{
        margin: '20px 20px 20px 20px',
        p: 1,
        boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.1)',
        borderRadius: '13px',
      }}
    >
      {sortedLegend.length > 0 && (
        <Stack
          direction="row"
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {sortedLegend.map((item: string, index: number) => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                key={index}
                sx={{
                  mr: 2,
                  mb: 1,
                }}
                spacing={'3px'}
              >
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: colorMap[item],
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ textTransform: 'capitalize', fontSize: '0.7rem', letterSpacing: '-0.3px' }}
                >
                  {item.replace(/_/g, ' ')}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};

export default LegendDisplay;
