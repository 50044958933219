import { useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, InputAdornment, OutlinedInput, Skeleton, Stack, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { StartupRadarDataCtx } from '../../pages/StartupRadar/StartupRadarDataProvider';
import { TaxonomyValues } from './components/types';
import RecursiveLayers from './components/RecursiveLayers';
import {
  getAllParentsTaxonomy,
  isAnyTaxonomyChildrenSelected,
  isParentTaxonomySelected,
  treeSearch,
} from './components/utils';

interface ITaxonomyProps {
  // Filter
  onUpdateCategoryPicker?: (values: TaxonomyValues) => void;
  setReset?: boolean;
  onReset?: () => void;
  // Classification
  onPositionLogicValuesChange?: (selectedValues: TaxonomyValues) => void;
  onDeliverablesValuesChange?: (selectedValues: TaxonomyValues) => void;
  // enableClassification?: boolean;
  positionLogicValues?: TaxonomyValues;
  deliverablesValues?: TaxonomyValues;
  enableDeliverables?: boolean;

  // Disabling cascade unchecking will prevent children elements from
  // being unchecked when parent is unchecked. This props is useful when
  // you want to allow users to select multiple categories from the same
  // taxonomy level, while allowing the parent to be unchecked.
  disableCascadeUnchecking?: boolean;
}

const CategoryPicker = ({
  // Filter
  onUpdateCategoryPicker,
  setReset,
  onReset,
  // Classification
  onPositionLogicValuesChange,
  onDeliverablesValuesChange,
  // enableClassification = false,
  positionLogicValues,
  deliverablesValues,
  enableDeliverables = false,
  disableCascadeUnchecking = false,
}: ITaxonomyProps) => {
  const { positioningLogic, positioningLogicMap, poisitioningLogicLoading, deliverablesLoading } =
    useContext(StartupRadarDataCtx);

  const [searchQuery, setSearchQuery] = useState('');
  // const [organizationPositioningLogic, setOrganizationPositioningLogic] = useState<{
  //   [orgUuid: string]: TaxonomyValues;
  // }>({});
  // const [organizationDeliverables, setOrganizationDeliverables] = useState<{
  //   [orgUuid: string]: TaxonomyValues;
  // }>({});

  // State variables for that local taxonomy values currently displayed
  const [localTaxonomyValues, setLocalTaxonomyValues] = useState<TaxonomyValues>(positionLogicValues ?? []);

  // Whenever the taxonomy values change, update the local taxonomy values
  useEffect(() => {
    if (positionLogicValues) {
      console.log(positionLogicValues);
      setLocalTaxonomyValues(positionLogicValues);
    }
  }, [positionLogicValues]);

  // useEffect(() => {
  //   if (setReset) {
  //     setOrganizationPositioningLogic({});
  //     setOrganizationDeliverables({});
  //     if (onReset) onReset();
  //   }
  // }, [setReset, onReset]);

  const positioningLogicLayers = useMemo(() => {
    if (searchQuery) {
      return treeSearch([...positioningLogic], searchQuery);
    }
    return positioningLogic;
  }, [positioningLogic, searchQuery]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value);
  };

  // const isTaxonomyChecked = (type: 'positioningLogic' | 'deliverables', uuid: string) => {
  //   if (type === 'positioningLogic') {
  //     if (enableClassification) {
  //       return (positionLogicValues ?? []).includes(uuid);
  //     } else {
  //       return (organizationPositioningLogic?.[selectedCategory?.uuid ?? ''] ?? []).includes(uuid);
  //     }
  //   } else {
  //     if (enableClassification) {
  //       return (deliverablesValues ?? []).includes(uuid);
  //     } else return (organizationDeliverables?.[selectedCategory?.uuid ?? ''] ?? []).includes(uuid);
  //   }
  // };

  // const handleTaxonomy = (type: 'positioningLogic' | 'deliverables', values: string[]) => {
  //   const filteredArrayOfCategories: (string | TaxonomyValues)[] = [];

  //   if (type === 'positioningLogic') {
  //     console.log(organizationPositioningLogic, values);
  //     if (selectedOrganization) {
  //       setOrganizationPositioningLogic({
  //         ...organizationPositioningLogic,
  //         [selectedOrganization.uuid]: values,
  //       });
  //     }
  //     for (let i = 0; i < values.length; i++) {
  //       filteredArrayOfCategories.push(values[i]);
  //     }
  //     if (onPositionLogicValuesChange) onPositionLogicValuesChange(values);

  //     const tempOrganizationDeliverables = Object.values(organizationDeliverables);

  //     filteredArrayOfCategories.push(...tempOrganizationDeliverables);
  //   } else {
  //     setOrganizationDeliverables({
  //       ...(organizationDeliverables ?? {}),
  //       [selectedCategory.uuid]: values,
  //     });
  //     for (let i = 0; i < values.length; i++) {
  //       filteredArrayOfCategories.push(values[i]);
  //     }
  //     if (onDeliverablesValuesChange) onDeliverablesValuesChange(values);
  //     const tempOrganizationPositioningLogic = Object.values(organizationPositioningLogic);

  //     filteredArrayOfCategories.push(...tempOrganizationPositioningLogic);
  //   }
  //   if (onUpdateCategoryPicker) onUpdateCategoryPicker(filteredArrayOfCategories.flat());
  // };

  const handlePositioningLogicChange = (uuid: string, isChecked: boolean) => {
    let updatedTaxonomyValues: TaxonomyValues = [...localTaxonomyValues];
    // Case: When it is a allowed to deselect parent taxonomy without deselecting children
    if (disableCascadeUnchecking) {
      if (isChecked) {
        // Append the uuid to the taxonomy values
        updatedTaxonomyValues.push(uuid);

        // Remove duplicates
        updatedTaxonomyValues = [...new Set(updatedTaxonomyValues)];
      } else {
        // Remove the uuid from the taxonomy values
        updatedTaxonomyValues = updatedTaxonomyValues.filter(item => item !== uuid);
      }
    }
    // Case: When it is not allowed to deselect parent taxonomy without deselecting children
    else {
      const isChildNodeSelected = isAnyTaxonomyChildrenSelected(uuid, positioningLogicMap, localTaxonomyValues ?? []);

      // If a child node is selected, then the parent node cannot be deselected
      // The current selection stays the same
      if (isChildNodeSelected) {
        return;
      }
      // If no child node is selected, then the parent node can be deselected
      else {
        if (isChecked) {
          // Check if the node has a parent, otherwise the parent has to be checked as well
          const parentNode = positioningLogicMap[uuid]?.parentId;

          // If the node has a parent, check if the parent is already selected
          if (parentNode) {
            const isParentNodeSelected = isParentTaxonomySelected(parentNode, positioningLogicMap, localTaxonomyValues);
            if (!isParentNodeSelected) {
              // Get parent nodes recursively
              const parentNodes = getAllParentsTaxonomy(uuid, positioningLogicMap);
              console.log(
                'parent nodes',
                parentNodes.map(node => positioningLogicMap[node]?.name),
              );

              // Append the parent node to the taxonomy values
              updatedTaxonomyValues.push(...parentNodes);
            }
          }
          // Append the uuid to the taxonomy values
          updatedTaxonomyValues.push(uuid);

          // Remove duplicates
          updatedTaxonomyValues = [...new Set(updatedTaxonomyValues)];
        } else {
          // Remove node from taxonomy values
          updatedTaxonomyValues = updatedTaxonomyValues.filter(item => item !== uuid);
        }
      }
    }

    setLocalTaxonomyValues(updatedTaxonomyValues);

    // Call the callback function
    if (onPositionLogicValuesChange) {
      onPositionLogicValuesChange(updatedTaxonomyValues);
    }
  };

  // const handleValuesChange = ({
  //   type,
  //   uuid,
  //   isChecked,
  // }: {
  //   type: 'positioningLogic' | 'deliverables';
  //   uuid: string;
  //   isChecked: boolean;
  // }) => {
  //   let values =
  //     type === 'positioningLogic'
  //       ? [...(organizationPositioningLogic?.[selectedOrganization?.uuid ?? ''] ?? [])]
  //       : [...(organizationDeliverables?.[selectedOrganization?.uuid ?? ''] ?? [])];

  //   const taxonomyMap = type === 'positioningLogic' ? { ...positioningLogicMap } : { ...deliverablesMap };

  //   values = calculateTaxonomyValuesChange({
  //     values: values,
  //     isChecked,
  //     uuid,
  //     type,
  //     taxonomyMap,
  //     enableClassification,
  //   });

  //   if (type === 'positioningLogic') {
  //     handleTaxonomy('positioningLogic', values);
  //   } else {
  //     handleTaxonomy('deliverables', values);
  //   }
  // };

  return (
    <Stack sx={{ px: 1, py: 1 }}>
      <Typography sx={{ color: '#667085', fontSize: '0.7rem' }} gutterBottom>
        Industry Segment
      </Typography>
      {poisitioningLogicLoading || deliverablesLoading ? (
        <Skeleton id="nksd" variant="rectangular" width="100%" height={100} sx={{ flex: 1 }} />
      ) : (
        <Stack>
          <OutlinedInput
            id="outlined-basic"
            fullWidth
            size="small"
            placeholder="Search"
            onChange={handleSearchChange}
            sx={{ background: '#fff', borderRadius: 0, mb: 1 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            }
          />

          {positioningLogicLayers.map(layer => (
            <RecursiveLayers
              key={`recursive-layer-${layer.uuid}`}
              node={layer}
              level={0}
              isChecked={uuid => localTaxonomyValues.find(item => item === uuid) !== undefined}
              // isChecked={uuid => isTaxonomyChecked('positioningLogic', uuid)}
              isHierarchySelected={uuid => localTaxonomyValues.find(item => item === uuid) !== undefined}
              // isHierarchySelected={uuid =>
              //   enableClassification
              //     ? isHierarchySelected(uuid, positioningLogicMap, positionLogicValues ?? [])
              //     : isHierarchySelected(
              //         uuid,
              //         positioningLogicMap,
              //         organizationPositioningLogic?.[selectedCategory?.uuid ?? ''] ?? [],
              //       )
              // }
              hideTotalChildren
              onChange={(uuid: string, isChecked: boolean, level: number) =>
                handlePositioningLogicChange(uuid, isChecked)
              }
              // onChange={(uuid: string, isChecked: boolean, level: number) =>
              //   handleValuesChange({ type: 'positioningLogic', uuid, isChecked })
              // }
            />
          ))}
        </Stack>
      )}
      {/* {deliverables.length > 0 && enableDeliverables === true && (
        <>
          <Typography sx={{ color: '#667085', fontSize: '11px', mt: 2 }} gutterBottom>
            Delivery Type
          </Typography>
          {poisitioningLogicLoading || deliverablesLoading ? (
            <Skeleton id="nksd" variant="rectangular" width="100%" height={100} sx={{ flex: 1 }} />
          ) : (
            <Stack>
              {deliverables.map(layer => (
                <RecursiveLayers
                  key={`recursive-layer-${layer.uuid}`}
                  node={layer}
                  level={0}
                  isChecked={uuid => isTaxonomyChecked('deliverables', uuid)}
                  isHierarchySelected={uuid =>
                    enableClassification ? isHierarchySelected(uuid, deliverablesMap, deliverablesValues ?? []) : false
                  }
                  onChange={(uuid: string, isChecked: boolean, level: number) =>
                    handleValuesChange({ type: 'deliverables', uuid, isChecked })
                  }
                />
              ))}
            </Stack>
          )}
        </>
      )} */}
    </Stack>
  );
};

export default CategoryPicker;
