import { industryColorMap } from '../UIProvider';

type TransformedData = {
  labels: number[];
  datasets: {
    label: string;
    data: (number | undefined)[];
    backgroundColor: string;
  }[];
};
const transformDataForDisplay = (
  data: readonly {
    uuid: string;
    year: number;
    size: number;
    count: number;
    name: string;
  }[],
): TransformedData => {
  let labels = data.map(item => item.year);
  labels = [...new Set(labels)];

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const datasets = data.map(category => ({
    label: category.name,
    data: data.filter(item => item.name === category.name).map(item => item.size),
    backgroundColor: industryColorMap[category.uuid] || getRandomColor(),
  }));

  datasets.sort((a, b) => {
    const sumA = a.data.reduce((acc, val) => acc + val);
    const sumB = b.data.reduce((acc, val) => acc + val);
    return sumA - sumB;
  });
  return { labels, datasets };
};

export default transformDataForDisplay;
