import { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';

// Material UI
import { Stack, Typography, Box, Link, IconButton } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import countries from '../assets/countries.json';
import OrganizationStatus from './OrganizationStatus';
import OrganizationLabels from './OrganizationLabels';
import * as API from '../../../API';
import useOrganization from '../graphql/useOrganization';
import { TaxonomyMap, TaxonomyValues } from '../../../components/TaxonomyPicker/components/types';
import { calculateTaxonomyValuesChange } from '../../../components/TaxonomyPicker/components/utils';
import { AuthCtx } from '../../../AuthProvider';
import { SURUserRole } from '../../AdminPage/types';

interface IOrganizationDetailsProps {
  org_uuid: string;
  positioningLogicValues?: TaxonomyValues;
  onPositioningLogicValuesChange: (selectedValues: TaxonomyValues) => void;
  deliverablesValues?: TaxonomyValues;
  onDeliverablesValuesChange: (selectedValues: TaxonomyValues) => void;
  positioningLogicMap: TaxonomyMap;
  deliverablesMap: TaxonomyMap;
  onOrganizationUpdated?: () => void;
}

const OrganizationDetails = ({
  org_uuid,
  positioningLogicValues = [],
  onPositioningLogicValuesChange,
  deliverablesValues = [],
  onDeliverablesValuesChange,
  positioningLogicMap,
  deliverablesMap,
}: IOrganizationDetailsProps) => {
  const { roles } = useContext(AuthCtx);
  const { organization, loading, refetch } = useOrganization({ uuid: org_uuid });

  // Keep track of local async loding state
  const [localLoading, setLocalLoading] = useState(false);

  const getFormattedDate = useCallback((date: string) => {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate.format('DD.MM.YYYY');
    } else return 'NOT AVAILABLE';
  }, []);

  const handleValuesChange = ({
    type,
    uuid,
    isChecked,
  }: {
    type: 'positioningLogic' | 'deliverables';
    uuid: string;
    isChecked: boolean;
  }) => {
    let values = type === 'positioningLogic' ? [...(positioningLogicValues ?? [])] : [...(deliverablesValues ?? [])];
    const taxonomyMap = type === 'positioningLogic' ? { ...positioningLogicMap } : { ...deliverablesMap };

    values = calculateTaxonomyValuesChange({
      values: values,
      isChecked,
      uuid,
      type,
      taxonomyMap,
    });

    if (type === 'positioningLogic') {
      onPositioningLogicValuesChange(values);
    } else {
      onDeliverablesValuesChange(values);
    }
  };

  const getOrganizationImage = useMemo(() => {
    if (
      !organization?.logoUrl ||
      organization.logoUrl.toLowerCase() === 'nan' ||
      organization?.logoUrl.endsWith('.ai')
    ) {
      return `https://via.placeholder.com/70x40/000000/fff?Text=${organization?.name}`;
    }
    return organization?.logoUrl;
  }, [organization?.logoUrl, organization?.name]);

  const updateOrganization = async (data: API.UpdateOrganization) => {
    if (!localLoading && !loading) {
      setLocalLoading(true);
      await API.updateOrganization(org_uuid, data);
      setLocalLoading(false);
      refetch({ uuid: org_uuid });
    }
  };

  const handlePinClick = async () => {
    await updateOrganization({ pinned: !organization?.pinned });
  };

  const handleReviewButtonClick = async () => {
    await updateOrganization({ reviewed: !organization?.reviewed });
  };

  if (loading) {
    return <Stack>Loading..</Stack>;
  } else if (organization === undefined) {
    return <Stack>Organization not found</Stack>;
  } else {
    return (
      <Stack>
        <Stack>
          <Stack direction="row" sx={{ background: '#F9FAFB', height: 70 }} alignItems="center">
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#667085', fontSize: 12 }}>Last assessed</Typography>
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#667085', fontSize: 12 }}>Status</Typography>
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#667085', fontSize: 12 }}>Country</Typography>
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#667085', fontSize: 12 }}>City</Typography>
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#667085', fontSize: 12 }}>Founded</Typography>
            </Box>
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <Typography sx={{ color: '#667085', fontSize: 12 }}>Pin</Typography>
            </Box>
            {roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 && (
              <Box sx={{ flex: 1, textAlign: 'center' }}>
                <Typography sx={{ color: '#667085', fontSize: 12 }}>Reviewed</Typography>
              </Box>
            )}
          </Stack>

          <Stack
            direction="row"
            sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', height: 66 }}
            alignItems="center"
          >
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: 11 }}>
                {getFormattedDate(organization.labelStatusRel?.timestamp)}
              </Typography>
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <OrganizationStatus status={organization.status} />
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: 11 }}>
                {(countries as { [key: string]: { name: string } })[organization.countryCode]?.name ?? ''}
              </Typography>
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: 11 }}>{organization.city}</Typography>
            </Box>
            <Box sx={{ flex: 2, textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: 11 }}>{getFormattedDate(organization.foundedOn)}</Typography>
            </Box>
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <IconButton onClick={handlePinClick} disabled={true}>
                {organization.pinned ? (
                  <PushPinIcon sx={{ color: 'salmon', fontSize: 20 }} />
                ) : (
                  <PushPinIcon sx={{ color: 'lightgray', fontSize: 20 }} />
                )}
              </IconButton>
            </Box>

            {roles.indexOf(SURUserRole.SUR_ADMIN) !== -1 && (
              <Box sx={{ flex: 1, textAlign: 'center' }}>
                <IconButton onClick={handleReviewButtonClick} disabled={localLoading || loading}>
                  {organization.reviewed ? (
                    <CheckBoxIcon sx={{ color: 'salmon', fontSize: 20 }} />
                  ) : (
                    <CheckBoxOutlineBlankIcon sx={{ color: 'lightgray', fontSize: 20 }} />
                  )}
                </IconButton>
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack direction={'row'} flex={1} sx={{ mt: 10, mb: 2 }}>
          <Stack
            flex={1}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              mr: 3,
            }}
          >
            <img width={120} src={getOrganizationImage} alt={`${organization.name} Logo`} />
          </Stack>
          <Stack
            flex={3}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              mr: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#000000',
              }}
            >
              {organization.shortDescription}
            </Typography>
          </Stack>
          <Stack
            flex={1}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link
              href={organization.homepageUrl}
              target="_"
              color="inherit"
              sx={{ fontSize: 12, mr: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <LaunchSharpIcon sx={{ fontSize: 12, mr: 1 }} />

              <Typography sx={{ fontSize: 12 }}>Visit Company Link</Typography>
            </Link>
          </Stack>
        </Stack>

        <Stack mb={5}>
          <Typography mb={1} sx={{ color: '#667085', fontSize: '15px', fontWeight: '700' }}>
            Description
          </Typography>
          <Typography mx={2} sx={{ color: '#667085', fontSize: '14px' }}>
            {organization?.description}
          </Typography>
        </Stack>

        <OrganizationLabels
          positioningLogicValues={positioningLogicValues}
          positioningLogicMap={positioningLogicMap}
          deliverablesValues={deliverablesValues}
          deliverablesMap={deliverablesMap}
          onChange={({ type, uuid }) => handleValuesChange({ type, uuid, isChecked: false })}
        />
      </Stack>
    );
  }
};

export default OrganizationDetails;
