import { useQuery, gql, TypedDocumentNode } from '@apollo/client';

type RawData = readonly {
  amount: number;
  fundingRaised: readonly {
    investmentType: string;
    raisedAmountUsd: number;
  }[];
  organization: {
    name: string;
    categoryList: readonly string[];
    countryCode: string;
    region: string;
  };
}[];

type TotalAmountRaisedByCompanyVars = {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
};

const GET_TOTAL_AMOUNT_RAISED_BY_COMPANY: TypedDocumentNode<RawData, TotalAmountRaisedByCompanyVars> = gql`
  query TotalAmountRaisedByCompanyQuery(
    $investmentTypes: [String!]
    $countries: [String!]
    $categories: [String!]
    $deliveryTypes: [String!]
    $years: [Int!]
  ) {
    fundingRoundsByCompany(
      investmentTypes: $investmentTypes
      countries: $countries
      categories: $categories
      deliveryTypes: $deliveryTypes
      years: $years
    ) {
      amount
      fundingRaised {
        investmentType
        raisedAmountUsd
      }
      organization {
        name
        categoryList
        countryCode
        region
      }
    }
  }
`;

const useAmountRaisedByCompany = (filters: TotalAmountRaisedByCompanyVars) => {
  const {
    data: { fundingRoundsByCompany } = { fundingRoundsByCompany: undefined },
    loading,
    refetch,
  } = useQuery<{
    fundingRoundsByCompany: RawData;
  }>(GET_TOTAL_AMOUNT_RAISED_BY_COMPANY, { variables: filters });
  return { fundingRoundsByCompany, loading, refetch };
};

export type { RawData, TotalAmountRaisedByCompanyVars };
export default useAmountRaisedByCompany;
