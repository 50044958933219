import { Chart } from 'react-chartjs-2';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { formatCash } from '../../Utils';
import { industryColorMap } from '../UIProvider';
import LegendDisplay from './LegendDisplay';
import { TaxonomyMap } from '../../../../components/TaxonomyPicker/components/types';

// ChartJS.register(ArcElement, Tooltip, Legend);
interface chartDatapoints {
  x: number;
  y: number;
}

interface ChartData {
  backgroundColor: string;
  label: string;
  data: chartDatapoints[];
  labels: string[];
  showLine?: boolean;
  borderColor: string;
}

interface RenderMarketTotalAmountByIndustryGraphProps {
  loading: boolean;
  vars: {
    countries: readonly string[] | undefined;
    investmentTypes: readonly string[] | undefined;
    categories: readonly string[] | undefined;
    years: readonly number[] | undefined;
  };
  newChartData: ChartData[];
  categories: readonly string[] | undefined;
  datasets: {
    label: string;
    data: (number | undefined)[];
    backgroundColor: string;
  }[];
  labels: number[];
  displayLog: boolean;
  positioningLogicMap: TaxonomyMap;
  deliverablesMap: TaxonomyMap;
  years: readonly number[] | undefined;
}

export const RenderMarketTotalAmountByIndustryGraph: FC<RenderMarketTotalAmountByIndustryGraphProps> = ({
  loading,
  vars,
  newChartData,
  categories,
  datasets,
  labels,
  displayLog,
  positioningLogicMap,
  deliverablesMap,
  years,
}) => {
  const formatXAxisTickLabel = (value: string | number) => {
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, '');
  };

  const formatYAxisLabel = (value: string | number) => {
    const numberValue = Number(value);
    if (numberValue >= 1000000000) {
      return (
        (numberValue / 1000000000).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }) + 'B'
      );
    }
    if (numberValue >= 1000000) {
      return (
        (numberValue / 1000000).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }) + 'M'
      );
    }
    if (numberValue >= 1000) {
      return (
        (numberValue / 1000).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }) + 'k'
      );
    }
    return numberValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 });
  };

  if (loading) {
    return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}> Loading...</Stack>;
  }

  return (
    <Stack
      sx={{
        position: 'relative',
        display: 'flex',
        flex: 1,
      }}
    >
      <Stack sx={{ flex: 1, position: 'relative' }}>
        <Stack sx={{ position: 'absolute', top: '10px', left: '10px', right: '10px', bottom: '10px' }}>
          {vars.years?.length === 1 ? (
            <Chart
              type={'bar'}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  // Hide the legend
                  legend: {
                    display: false,
                    position: 'bottom',
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context): string {
                        const year = `${context.label}`;
                        const amount = formatCash(context.parsed.y as number);
                        const label = context.dataset.label;

                        // return the label with the formatted value
                        return `${label}: (${year}, ${'$'}${amount})`;
                      },
                      title() {
                        return '';
                      },
                    },
                  },
                },
                animations: {
                  tension: {
                    duration: 8000,
                    easing: 'linear',
                    from: 0,
                    to: 0.3,
                    loop: true,
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: formatYAxisLabel,
                    },
                    type: displayLog ? 'logarithmic' : 'linear',
                  },
                },
              }}
              data={{
                labels: labels,
                datasets: datasets.map(dataset => ({ ...dataset })),
              }}
            />
          ) : (
            <Chart
              type={'scatter'}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                    position: 'bottom',
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context): string {
                        const value = context.raw as { x: string; y: number };
                        const year = `${value.x}`;
                        const amount = formatCash(value.y);
                        const label = context.dataset.label;

                        // return the label with the formatted value
                        return `${label}: (${year}, ${'$'}${amount})`;
                      },
                    },
                  },
                },
                animations: {
                  tension: {
                    duration: 8000,
                    easing: 'linear',
                    from: 0,
                    to: 0.3,
                    loop: true,
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      maxTicksLimit: 10,
                      callback: formatXAxisTickLabel,
                    },
                  },
                  y: {
                    ticks: {
                      maxTicksLimit: 10,
                      callback: formatYAxisLabel,
                    },
                    type: displayLog ? 'logarithmic' : 'linear',
                  },
                },
              }}
              data={{
                datasets: newChartData.map(dataset => ({ ...dataset })),
              }}
            />
          )}
        </Stack>
      </Stack>
      <Stack sx={{ mt: 1 }}>
        {categories && categories.length > 0 ? (
          <LegendDisplay
            sortedLegend={
              categories
                ? categories
                    .map(category => positioningLogicMap[category] || deliverablesMap[category])
                    .filter(Boolean)
                    .map(category => category.name)
                : ([] as string[])
            }
            legendColor={
              categories
                ? categories
                    .map(category => industryColorMap[category] || industryColorMap[category])
                    .filter(Boolean)
                    .map(category => category)
                : []
            }
          />
        ) : years && years.length === 1 ? (
          <LegendDisplay
            sortedLegend={datasets.map(dataset => dataset.label)}
            legendColor={datasets ? datasets.map(dataset => dataset.backgroundColor) : []}
          />
        ) : (
          <LegendDisplay
            sortedLegend={newChartData.map(dataset => dataset.label)}
            legendColor={newChartData.map(chartData => chartData.backgroundColor)}
          />
        )}
      </Stack>
    </Stack>
  );
};
