import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { getAuth, signOut } from 'firebase/auth';
import UserProfileImage from '../components/UserProfileImage';
import AnnouncedOnYearRangePicker from '../components/filters/AnnouncedOnYearRangePicker';
import CategoryPicker from '../components/TaxonomyPicker/CategoryPicker';
import CountryPicker from '../components/filters/CountryPicker/CountryPicker';
import InvestmentTypePicker from '../components/filters/InvestmentTypePicker';
import StartupRadarHeader from '../components/NavigationHeader/StartupRadarHeader';
import useFilter from './StartupRadar/components/hooks/useFilter';

const drawerWidth = 420;

const UserIconButton = styled(IconButton)({
  position: 'absolute',
  right: '0',
  top: '0',
  zIndex: 10,
});

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    position: 'relative',
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  // Change the background color of the AppBar
  backgroundColor: 'white',

  // Change the strength of the shadow
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.05), 0px 4px 5px 0px rgba(0,0,0,0.02), 0px 1px 10px 0px rgba(0,0,0,0.02)',

  // Change the color of the text
  color: 'black',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface MainLayoutProps {
  children: React.ReactNode;
  title?: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, title }) => {
  // Drawer State
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [resetClick, setResetClick] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userProfileMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  // Filters State
  const {
    setSelectedCountriesToApply,
    setSelectedInvestmentTypesToApply,
    setSelectedIndustriesToApply,
    setSelectedAnnouncedOnYearsToApply,
    applyFilters,
    resetFilters,
  } = useFilter();

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <StartupRadarHeader />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={userProfileMenuOpen}
            onClose={handleCloseUserProfileMenu}
            onClick={handleCloseUserProfileMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          <UserIconButton size="large" edge="start" color="info" aria-label="menu" onClick={handleClick}>
            <UserProfileImage />
          </UserIconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Stack
          sx={{
            flex: 1,
            position: 'relative',
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: 'auto',
            }}
          >
            <CountryPicker
              key={'countryPicker'}
              onCountryChange={countries => {
                setSelectedCountriesToApply(countries);
              }}
              setReset={resetClick}
              onReset={() => setResetClick(false)}
            />
            <InvestmentTypePicker
              key={'investmentTypePicker'}
              onInvestmentTypeChange={investmentTypes => {
                setSelectedInvestmentTypesToApply(investmentTypes);
              }}
              setReset={resetClick}
              onReset={() => setResetClick(false)}
            />
            <CategoryPicker
              key={'categoryPicker'}
              onUpdateCategoryPicker={selectedCategories => {
                setSelectedIndustriesToApply(selectedCategories);
              }}
              setReset={resetClick}
              onReset={() => setResetClick(false)}
              disableCascadeUnchecking
            />
            <AnnouncedOnYearRangePicker
              key={'announcedOnYearRangePicker'}
              title={'Announced On'}
              onUpdateYearRangePicker={selectedYearRange => {
                setSelectedAnnouncedOnYearsToApply(selectedYearRange);
              }}
              setReset={resetClick}
              onReset={() => setResetClick(false)}
            />
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'center', pt: 1, pb: 2 }} spacing={2}>
          <Button
            sx={{
              color: '#666',
              border: '1px solid #b8b9bc',
              borderRadius: 1,
              width: '50px',
            }}
            // variant="outlined"
            onClick={() => {
              setResetClick(true);
              resetFilters();
            }}
          >
            RESET
          </Button>
          <Button
            sx={{
              color: '#666',
              border: '1px solid #b8b9bc',
              borderRadius: 1,
              width: '50px',
            }}
            // variant="outlined"
            onClick={() => applyFilters()}
          >
            APPLY
          </Button>
        </Stack>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

export default MainLayout;
