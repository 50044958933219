import { DataLayer, TaxonomyMap, TaxonomyValues } from './types';

/**
 * get all parents id of the uuid
 * @param uuid
 * @param taxonomyMap
 * @returns
 */
const getAllParentsId = (uuid: string, taxonomyMap: TaxonomyMap) => {
  let ids = [uuid];
  const parentId = taxonomyMap[uuid].parentId;
  if (parentId) {
    ids = [...ids, ...getAllParentsId(parentId, taxonomyMap)];
  }
  return ids;
};
/**
 * check if any child of the uuid is not selected
 * @param param0
 * @returns
 */
export const calculateTaxonomyValuesChange = ({
  type,
  uuid,
  isChecked,
  taxonomyMap,
  values,
  enableClassification = false,
}: {
  type: 'positioningLogic' | 'deliverables';
  uuid: string;
  isChecked: boolean;
  taxonomyMap: TaxonomyMap;
  values: TaxonomyValues;
  enableClassification?: boolean;
}): string[] => {
  let newValues = [...values];
  if (isChecked) {
    let updatedValues = [];
    if (enableClassification) {
      // check if parent's value already exists
      const parentIndex = newValues.findIndex(value => value === taxonomyMap[uuid].parentId);
      if (parentIndex === -1) {
        // add uuid along with all parents uuids
        updatedValues = [
          ...newValues,
          ...getAllParentsId(uuid, taxonomyMap).map(hierarchicalValue => hierarchicalValue),
        ];
      } else {
        updatedValues = [...newValues, uuid];
      }
    } else {
      updatedValues = [...newValues, uuid];
    }
    newValues = updatedValues;
  } else {
    if (enableClassification) {
      //  check if any child of the uuid is not selected
      if (!isHierarchySelected(uuid, taxonomyMap, newValues, true)) {
        newValues = newValues.filter(value => value !== uuid);
      }
    } else {
      // Remove uuid from values if unchecked
      newValues = newValues.filter(value => value !== uuid);
    }
  }
  //  Remove duplicate values from _values
  return [...new Set(newValues)];
};
/**
 * check if any child of the uuid is selected
 * @param uuid
 * @param taxonomyMap
 * @param values
 * @param onlyChildren
 * @returns
 */
export const isHierarchySelected = (
  uuid: string,
  taxonomyMap: TaxonomyMap,
  values: TaxonomyValues,
  onlyChildren = false,
) => {
  if (values.includes(uuid) && !onlyChildren) {
    return true;
  }
  const children = taxonomyMap[uuid].children;
  if (children.length) {
    for (let i = 0; i < children.length; i++) {
      if (isHierarchySelected(children[i], taxonomyMap, values)) {
        return true;
      }
    }
  }
  return false;
};

//  utility function
export const treeSearch = (array: Array<DataLayer>, searchQuery: string, level = 0) => {
  const resultArray = [];
  for (let i = 0; i < array.length; i++) {
    if ((array[i].subLevel ?? [])?.length) {
      resultArray[i] = { ...array[i] };
      resultArray[i].subLevel = [...treeSearch(array[i].subLevel ?? [], searchQuery, level + 1)];
    }

    //  Now check if children were matched or we need to match with current label
    if (!(resultArray[i]?.subLevel ?? []).length) {
      const arrayLabel = array[i].name.toLowerCase().trim();
      if (arrayLabel.includes(searchQuery.toLowerCase().trim())) {
        resultArray[i] = { ...array[i] };
      } else {
        resultArray.splice(i, 1);
      }
    }
  }

  return resultArray.filter(n => n);
};

// Recursive Function to get all the children of a node
export const getAllChildrenTaxonomy = (uuid: string, taxonomyMap: TaxonomyMap) => {
  let children = [uuid];
  const childrenId = taxonomyMap[uuid].children;
  if (childrenId.length) {
    childrenId.forEach(childId => {
      children = [...children, ...getAllChildrenTaxonomy(childId, taxonomyMap)];
    });
  }
  return children;
};

// Recursive Function to get all the parents of a node
export const getAllParentsTaxonomy = (uuid: string, taxonomyMap: TaxonomyMap) => {
  let parents: string[] = [];
  const parentId = taxonomyMap[uuid].parentId;
  if (parentId) {
    parents = [parentId, ...getAllParentsTaxonomy(parentId, taxonomyMap)];
  }

  // Remove duplicates
  parents = [...new Set(parents)];

  return parents;
};

// Function to check if any children taxonomy node is selected
export const isAnyTaxonomyChildrenSelected = (
  uuid: string,
  taxonomyMap: TaxonomyMap,
  values: TaxonomyValues,
): boolean => {
  const children = taxonomyMap[uuid].children;
  if (children.length) {
    for (let i = 0; i < children.length; i++) {
      if (values.includes(children[i])) {
        return true;
      }
    }
  }
  return false;
};

// Function to check if the parent taxonomy node is selected
export const isParentTaxonomySelected = (uuid: string, taxonomyMap: TaxonomyMap, values: TaxonomyValues): boolean => {
  const parentId = taxonomyMap[uuid].parentId;
  if (parentId) {
    return values.includes(parentId);
  }
  return false;
};

// Function to build the taxonomy name by traversing the parents
// and appending the rank of the parent if present as a prefix to the name
// Example: 1 or 1.1 or 1.1.1, etc.
export const buildTaxonomyRankStr = (uuid: string, taxonomyMap: TaxonomyMap): string => {
  let rankStr = '';
  const parentId = taxonomyMap[uuid].parentId;
  if (parentId) {
    rankStr = `${buildTaxonomyRankStr(parentId, taxonomyMap)}.`;
  }
  return `${rankStr}${taxonomyMap[uuid].rank}`;
};
