// Material UI
import { Grid, Stack } from '@mui/material';

import { useEffect, useState } from 'react';
import useFilter from '../components/hooks/useFilter';
import ActiveInvestors from '../components/ActiveInvestors/ActiveInvestors';
import AllocationOfInvestmentTypes from '../components/AllocationOfInvestmentTypes/AllocationOfInvestmentTypes';
import AmountRaisedByCompany from '../components/AmountRaisedByCompany';

import MainLayout from '../../MainLayout';
import MarketTotalAmountByInvType from '../components/MarketTotalAmountByInvType';
import MarketTotalCountByInvType from '../components/MarketTotalCountByInvType';
import MarketTotalAmountByIndustryLineChart from '../components/MarketTotalAmountByInudstryLineChart/MarketTotalAmountByIndustryLineChart';
import { colorMap } from '../components/UIProvider';
import LegendDisplay from './LegendDisplay';

const MarketAnalysisPage = () => {
  const { filters } = useFilter();
  // const { investmentTypeLegend, setInvestmentTypeLegend } = useContext(StartupRadarDataCtx);
  const [sortedLegend, setSortedLegend] = useState<Array<string>>(['']);

  // Legend
  const [amountRaisedByCompanyInvestmentTypeLegend, setAmountRaisedByCompanyInvestmentTypeLegend] = useState<
    Array<string>
  >(['']);
  const [allocationOfInvestmentTypesLegend, setAllocationOfInvestmentTypesLegend] = useState<Array<string>>(['']);
  const [marketTotalAmountByIndustryLineChartLegend, setMarketTotalAmountByIndustryLineChartLegend] = useState<
    Array<string>
  >(['']);
  const [marketTotalCountByInvTypeLegend, setMarketTotalCountByInvTypeLegend] = useState<Array<string>>(['']);

  useEffect(() => {
    // Create a copy of the investmentTypeLegend array
    const sortedInvestmentTypeLegendCopy = [
      ...amountRaisedByCompanyInvestmentTypeLegend,
      ...allocationOfInvestmentTypesLegend,
      ...marketTotalAmountByIndustryLineChartLegend,
      ...marketTotalCountByInvTypeLegend,
    ];

    // Remove duplicates
    const uniqueInvestmentTypeLegend = [...new Set(sortedInvestmentTypeLegendCopy)];

    // Sort the array based on the order of the keys in colorMap
    uniqueInvestmentTypeLegend.sort((a, b) => {
      const aColorIndex = Object.keys(colorMap).indexOf(a);
      const bColorIndex = Object.keys(colorMap).indexOf(b);
      return aColorIndex - bColorIndex;
    });
    setSortedLegend(uniqueInvestmentTypeLegend);
  }, [
    amountRaisedByCompanyInvestmentTypeLegend,
    allocationOfInvestmentTypesLegend,
    marketTotalAmountByIndustryLineChartLegend,
    marketTotalCountByInvTypeLegend,
  ]);

  interface MarketAnalysisGridCardProps {
    children: React.ReactNode;
  }

  const MarketAnalysisGridCard: React.FC<MarketAnalysisGridCardProps> = ({ children }) => {
    return (
      <Grid
        item
        xl={4}
        lg={6}
        md={12}
        sm={12}
        xs={12}
        sx={{
          position: 'relative',
          minHeight: '360px',
        }}
        p={1}
      >
        {children}
      </Grid>
    );
  };

  return (
    <MainLayout>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                            SUR HEADER                          //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <Stack
        sx={{
          position: 'absolute',
          top: '70px',
          right: '0',
          left: '0px',
          bottom: '0',
          overflowY: 'auto',
        }}
      >
        <LegendDisplay sortedLegend={sortedLegend} />
        <Grid container flex={1} px={1}>
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////                  Total Amount Raised by Company                //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}
          <MarketAnalysisGridCard>
            <AmountRaisedByCompany
              countries={filters.countries}
              investmentTypes={filters.investmentTypes}
              categories={filters.industries}
              years={filters.fundingYearsManual}
              onLegendUpdate={(investmentTypeLegend: string[], categoryLegend: string[]) => {
                if (
                  JSON.stringify(investmentTypeLegend) !== JSON.stringify(amountRaisedByCompanyInvestmentTypeLegend)
                ) {
                  setAmountRaisedByCompanyInvestmentTypeLegend(investmentTypeLegend);
                }
              }}
            />
          </MarketAnalysisGridCard>
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////                         ACTIVE INVESTORS                       //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}
          <MarketAnalysisGridCard>
            <ActiveInvestors
              countries={filters.countries}
              investmentTypes={filters.investmentTypes}
              categories={filters.industries}
              years={filters.fundingYearsManual}
            />
          </MarketAnalysisGridCard>
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////                  Allocation of Investment Types                //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          <MarketAnalysisGridCard>
            <AllocationOfInvestmentTypes
              countries={filters.countries}
              investmentTypes={filters.investmentTypes}
              categories={filters.industries}
              years={filters.fundingYearsManual}
              onLegendUpdate={(investmentTypeLegend: string[], categoryLegend: string[]) => {
                if (JSON.stringify(investmentTypeLegend) !== JSON.stringify(allocationOfInvestmentTypesLegend)) {
                  setAllocationOfInvestmentTypesLegend(investmentTypeLegend);
                }
              }}
            />
          </MarketAnalysisGridCard>
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////             Total Amount of Investments by Industry            //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          <MarketAnalysisGridCard>
            <MarketTotalAmountByIndustryLineChart
              countries={filters.countries}
              investmentTypes={filters.investmentTypes}
              categories={filters.industries}
              years={filters.fundingYearsManual}
              onLegendUpdate={(investmentTypeLegend: string[], categoryLegend: string[]) => {
                if (
                  JSON.stringify(investmentTypeLegend) !== JSON.stringify(marketTotalAmountByIndustryLineChartLegend)
                ) {
                  setMarketTotalAmountByIndustryLineChartLegend(investmentTypeLegend);
                }
              }}
            />
          </MarketAnalysisGridCard>
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////             Total Amount of Investments by Industry            //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          <MarketAnalysisGridCard>
            <MarketTotalCountByInvType
              countries={filters.countries}
              investmentTypes={filters.investmentTypes}
              categories={filters.industries}
              years={filters.fundingYearsManual}
            />
          </MarketAnalysisGridCard>
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////             Total Amount of Investments by Industry            //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          <MarketAnalysisGridCard>
            <MarketTotalAmountByInvType
              countries={filters.countries}
              investmentTypes={filters.investmentTypes}
              categories={filters.industries}
              years={filters.fundingYearsManual}
              onLegendUpdate={(investmentTypeLegend: string[], categoryLegend: string[]) => {
                if (JSON.stringify(investmentTypeLegend) !== JSON.stringify(marketTotalCountByInvTypeLegend)) {
                  setMarketTotalCountByInvTypeLegend(investmentTypeLegend);
                }
              }}
            />
          </MarketAnalysisGridCard>
        </Grid>
      </Stack>
    </MainLayout>
  );
};
export default MarketAnalysisPage;
