// Material UI
import { Typography } from '@mui/material';
import { useMemo } from 'react';

const OrganizationStatus = ({ status }: { status: string }) => {
  const colors = useMemo(() => {
    if (status === 'closed') {
      return {
        color: '#d54c4c',
        background: '#facece',
      };
    }
    return {
      color: '#027A48',
      background: '#ECFDF3',
    };
  }, [status]);

  return (
    <Typography
      sx={{
        textTransform: 'capitalize',
        display: 'inline-flex',
        background: colors.background,
        borderRadius: '80px',
        color: colors.color,
        fontSize: '12px',
        fontWeight: 900,
        textAlign: 'center',
        paddingTop: '3px',
        paddingBottom: '3px',
        px: '8px',
        alignItems: 'center',
      }}
    >
      <span style={{ background: colors.color, width: 8, height: 8, borderRadius: 8, marginRight: 5 }} />
      {status === 'ipo' ? status.toUpperCase() : status}
    </Typography>
  );
};

export default OrganizationStatus;
