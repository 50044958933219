import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDYt7iccys_EDvuqVYNWO0GccKk4WBR3k8',
  authDomain: 'berylls.firebaseapp.com',
  databaseURL: 'https://berylls-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'berylls',
  storageBucket: 'berylls.appspot.com',
  messagingSenderId: '698689390809',
  appId: '1:698689390809:web:d2eacc2baec47a4afbd6c8',
  measurementId: 'G-CLZQK3PY28',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerConfig = async (): Promise<{ [key: string]: any }> => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    return { headers: { Authorization: 'Bearer ' + token } };
  } else {
    return {};
  }
};

const uploadFileToStorage = async (
  fileName: string,
  storagePath: string,
  file: File,
  onLoadingHandler?: (progress: boolean) => void,
  onProgressHandler?: (progress: number) => void,
) => {
  if (onLoadingHandler) onLoadingHandler(true);

  const destPath = `${storagePath}/${fileName}`;

  const storageRef = ref(storage, destPath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (snapshot: any) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (onProgressHandler) onProgressHandler(progress);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      console.log(error);
      if (onLoadingHandler) onLoadingHandler(false);
      if (onProgressHandler) onProgressHandler(-1);
    },
    async () => {
      if (onLoadingHandler) onLoadingHandler(false);
      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
        console.log('File available at', downloadURL);
      });
    },
  );
};

export { app, auth, database, firestore, storage, headerConfig, uploadFileToStorage };
