import { useQuery, gql, TypedDocumentNode } from '@apollo/client';

type TotalInvestmentsByTaxonomyRawData = {
  totalInvestmentByTaxonomy: readonly {
    uuid: string;
    year: number;
    size: number;
    count: number;
    name: string;
  }[];
};

type TotalInvestmentsByTaxonomyVars = {
  countries?: readonly string[];
  investmentTypes?: readonly string[];
  categories?: readonly string[];
  deliveryTypes?: readonly string[];
  years?: readonly number[];
};

const TOTAL_AMOUNT_OF_INVESTMENTS_BY_TAXONOMY: TypedDocumentNode<
  TotalInvestmentsByTaxonomyRawData,
  TotalInvestmentsByTaxonomyVars
> = gql`
  query GetAmountOfInvestmentsByIndustry(
    $countries: [String!]
    $categories: [String!]
    $deliveryTypes: [String!]
    $investmentTypes: [String!]
    $years: [Int!]
  ) {
    totalInvestmentByTaxonomy(
      countries: $countries
      categories: $categories
      deliveryTypes: $deliveryTypes
      investmentTypes: $investmentTypes
      years: $years
    ) {
      name
      uuid
      year
      size
      count
    }
  }
`;

const useTotalInvestmentsByCategory = (filters: TotalInvestmentsByTaxonomyVars) => {
  const {
    data: { totalInvestmentByTaxonomy } = { totalInvestmentByTaxonomy: [] },
    loading,
    refetch,
  } = useQuery(TOTAL_AMOUNT_OF_INVESTMENTS_BY_TAXONOMY, { variables: filters, notifyOnNetworkStatusChange: true });
  return { totalInvestmentByTaxonomy, loading, refetch };
};

export type { TotalInvestmentsByTaxonomyRawData, TotalInvestmentsByTaxonomyVars };
export default useTotalInvestmentsByCategory;
